// for peer connection
export const PEER_SERVER_HOST = "backend.itransportindex.com";
//export const PEER_SERVER_HOST = "0.peerjs.com";
export const PEER_SERVER_PORT = "443";
export const PEER_SERVER_PATH = "/peerjs";
//export const PEER_SERVER_PATH = "/";
export const PEER_SERVER_ICE = {
   "iceServers": [
        {
            //"urls": "stun:62.171.190.84:3478",
            'urls': 'stun:tcmfiles.com:3478',
            //'urls': 'stun:stun.l.google.com:19302',
        },
       {
            //"urls": "turn:62.171.190.84:3478",
            //"urls": "turn:metaimpire.com:3478",
             "urls": "turn:tcmfiles.com:3478",
            "username": "nobody",
            "credential": "nogroup",

            // 'urls': 'turn:us-0.turn.peerjs.com:3478?transport=tcp',
            // username: 'peerjs',
            // credential: 'peerjsp' 
        }
      ],

   
    "iceTransportPolicy": "relay",
    "sdpSemantics": "unified-plan"
};


