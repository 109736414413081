import React from "react";
import "../../assets/scss/themes.scss";
//Import Components
import UserChat from "./Chats/UserChat";
import { configureStore } from "../../store/messenger/store";
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import {
  updateMessages,
  deleteMessage,
  updateContacts,
  updateConversations,
  deleteContacts,
} from "../../store/messengerSlice";
import { useDispatch } from "react-redux";

function VideoChat() {
  const messenger = useSelector((state) => state.messenger);
  const dispatch = useDispatch();

  const setMessagestoState = (obj) => {
    dispatch(
      updateMessages({
        key: messenger.activeUser,
        message: obj,
      })
    );
  };

  const deleteMessageFromState = (id) => {
    dispatch(
      deleteMessage({
        id,
      })
    );
  };

  const handleUpdateContacts = (cntcts) => {
    dispatch(updateContacts(cntcts));
  };

  const handleUpdateConversations = (data) => {
    dispatch(updateConversations([data]));
  };

  const handleDelConversation = (id) => {
    dispatch(deleteContacts(id));
  };
  console.log("chat board", messenger);
  return (
    <React.Fragment>
      <Provider store={configureStore()}>
        <UserChat
          isForVideoChat={true}
          contacts={messenger?.contacts}
          currentUser={messenger.currentUser}
          active_user={messenger.activeUser}
          conversations={messenger.conversations}
          setMessagestoState={setMessagestoState}
          deleteMessageFromState={deleteMessageFromState}
          updateContacts={handleUpdateContacts}
          conversationsIds={messenger.conversationsIds}
          handleUpdateConversations={handleUpdateConversations}
          handleDelConversation={handleDelConversation}
        />
      </Provider>
    </React.Fragment>
  );
}

export default VideoChat;
