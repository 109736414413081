import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useCallContext } from "../../../store/context/CallProvider";
import { useSocket } from "../../../store/context/SocketProvider";
import sound from "../../../assets/sounds/Ringing.mp3";
export default function CallModal(props) {
  const {
    isOpen,
    toggleCallModal,
    isVideo,
    user,
    inComming,
    currentUser,
    conversationId,
    updateMessages,
  } = props;
  const volume = inComming ? 1 : 0.3;
  const [isCalling, setIsCalling] = useState(false);
  const audioRef = useRef();

  const { callUser, declineCall, answerCall } = useCallContext();

  const {  socket } = useSocket();

  const onClose = () => {
    audioRef.current.currentTime = 0;
    audioRef.current.pause();
    audioRef.current.loop = false;

    setIsCalling(false);
    toggleCallModal();

    if (isCalling) {
      const messageObj = {
        sender: currentUser?._id,
        receiver: user?._id,
        message: "",
        media: [],
        conversationId: conversationId,
        updatedAt: new Date().toLocaleString(),
        createdAt: new Date().toLocaleString(),
        callInfo: {
          isCall: true,
          callType: isVideo ? "Video" : "Audio",
          duration: "00:00",
          time: new Date().toLocaleString(),
        },
      };

      console.log("end with", messageObj, updateMessages);
      updateMessages(messageObj);
      socket?.emit("sendMessage", messageObj);
    }
  };

  const onCall = (type) => {
    audioRef.current.play();
    audioRef.current.loop = true;
    setIsCalling(true);
    if (type == "video") {
      callUser(user, true, currentUser, conversationId);
    } else {
      callUser(user, false, currentUser, conversationId);
    }
  };

  useEffect(() => {
    if (inComming) {
      audioRef.current = new Audio(sound).play();
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  console.log("modal", props);
  return (
    <Modal tabIndex="-1" isOpen={isOpen} toggle={toggleCallModal} centered>
      <ModalBody>
        <div className="text-center p-4">
          <div className="avatar-md mx-auto mb-4">
            <img
              src={
                user?.isGroupChat
                  ? "https://static.vecteezy.com/system/resources/thumbnails/021/063/599/small/group-coaching-icon-vector.jpg"
                  : user?.imageSrc
              }
              alt=""
              className="img-thumbnail rounded-circle"
            />
          </div>

          <h5 className="text-truncate">
            {user?.isGroupChat
              ? user.name
              : user?.contactName?.first + " " + user?.contactName?.last}
          </h5>
          <p className="text-muted">
            {inComming
              ? "Calling you"
              : isCalling
              ? "Calling..."
              : isVideo
              ? "Start Video Call"
              : "Start Audio Call"}
          </p>

          <div className="mt-5">
            <ul className="list-inline mb-1">
              <li className="list-inline-item px-2 me-2 ms-0">
                <button
                  type="button"
                  className="btn btn-danger avatar-sm rounded-circle"
                  onClick={inComming ? declineCall : onClose}
                >
                  <span className="avatar-title bg-transparent font-size-20">
                    <i className="ri-close-fill"></i>
                  </span>
                </button>
              </li>
              {inComming ? (
                isVideo ? (
                  <li className="list-inline-item px-2">
                    <button
                      type="button"
                      className="btn btn-success avatar-sm rounded-circle"
                      onClick={answerCall}
                    >
                      <span className="avatar-title bg-transparent font-size-20">
                        <i className="ri-vidicon-fill"></i>
                      </span>
                    </button>
                  </li>
                ) : (
                  <li className="list-inline-item px-2">
                    <button
                      type="button"
                      className="btn btn-success avatar-sm rounded-circle"
                      onClick={answerCall}
                    >
                      <span className="avatar-title bg-transparent font-size-20">
                        <i className="ri-phone-fill"></i>
                      </span>
                    </button>
                  </li>
                )
              ) : (
                !isCalling &&
                (isVideo ? (
                  <li className="list-inline-item px-2">
                    <button
                      type="button"
                      className="btn btn-success avatar-sm rounded-circle"
                      onClick={() => onCall("video")}
                    >
                      <span className="avatar-title bg-transparent font-size-20">
                        <i className="ri-vidicon-fill"></i>
                      </span>
                    </button>
                  </li>
                ) : (
                  <li className="list-inline-item px-2">
                    <button
                      type="button"
                      className="btn btn-success avatar-sm rounded-circle"
                      onClick={() => onCall("audio")}
                    >
                      <span className="avatar-title bg-transparent font-size-20">
                        <i className="ri-phone-fill"></i>
                      </span>
                    </button>
                  </li>
                ))
              )}
            </ul>
          </div>
          <audio ref={audioRef}>
            <source src={sound} type="audio/mpeg" />
          </audio>
        </div>
      </ModalBody>
    </Modal>
  );
}
