import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";

import withRouter from "../../../../components/messenger/withRouter";

//Import Components
import UserProfileSidebar from "../../../../components/messenger/UserProfileSidebar";
import SelectContact from "../../../../components/messenger/SelectContact";
import UserHead from "./UserHead";
import ImageList from "./ImageList";
import ChatInput from "./ChatInput";
import FileList from "./FileList";
//Import Images
import avatar4 from "../../../../assets/images/users/avatar-4.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
//i18n
import { useTranslation } from "react-i18next";
import { useSocket } from "../../../../store/context/SocketProvider";
import { delMessage } from "../../../../services/conversations";
import { uploadAttachment } from "../../../../services/attachments";
function UserChat(props) {
  const bottomRef = useRef();
  console.log("socket provider", useSocket());
  const { socket } = useSocket();
  const [modal, setModal] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const { t } = useTranslation();
  const [chatMessages, setchatMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [isDrag, setDrag] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [farMsg, setFarMsg] = useState(null);

  const handleForwardCLick = (msg) => {
    console.log("farMsg is",msg)
    setFarMsg(msg);
    toggle();
  };

  const getReceivers = (contact) => {
    let r = [];
    for (let usr of contact?.members) {
      if (usr._id != props.currentUser._id) {
        r.push(usr);
      }
    }
    return r;
  };

  const handleSentMessage = (msg)=>{
    console.log("message sent 200",msg);
    if(msg.isForwarded){
      props.handleForwardMessage(msg)
    }
  }

  useEffect(()=>{
    socket?.on("sentMessage", handleSentMessage);
    socket?.on("sentGroupMessage", handleSentMessage);

    return ()=>{
      socket?.off("sentMessage");
      socket?.off("sentGroupMessage");
    }
  },[socket])

  const forwardMessage = (contact) => {
    if(!socket?.connected){
        window.alert("Socket is not connected please refresh.!");
        return;
    }
    console.log(farMsg, contact);
    const messageObj = {
      ...farMsg,
      sender: props.currentUser?._id,
      receiver: contact?.isGroupChat ? getReceivers(contact) : contact.user?._id,
      conversationId: contact?.isGroupChat ? contact?._id : props.conversationsIds[contact?.index],
      forwardedBy: farMsg?.sender,
      isForwarded:true
    };
    delete messageObj._id;

    if (contact?.isGroupChat) {
      console.log("forward group message sent",messageObj);
      socket.emit("sendMessageGroup", messageObj);
    } else {
      console.log("forward  message sent",messageObj);
      socket.emit("sendMessage", messageObj);
    }
    // const d = new Date();
    // props.handleForwardMessage({ ...messageObj, updatedAt: d.toLocaleString() });
    setModal(false);
  };

  const startRecording = () => {
    setIsRecording(true);
    let constraints = {
      audio: true,
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        console.log("initializing Recorder.js ...");

        setStream(stream);

        setRecordingStatus("recording");
        //create new Media recorder instance using the stream
        const media = new MediaRecorder(stream, { type: "audio/webm" });
        //set the MediaRecorder instance to the mediaRecorder ref
        mediaRecorder.current = media;
        //invokes the start method to start the recording process
        mediaRecorder.current.start();
        let localAudioChunks = [];
        mediaRecorder.current.ondataavailable = (event) => {
          if (typeof event.data === "undefined") return;
          if (event.data.size === 0) return;
          localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
      })
      .catch(function (err) {
        //enable the record button if getUserMedia() fails
      });
  };

  const onStop = async (blob) => {
    console.log("uploading...", blob);
    //creates a blob file from the audiochunks data
    const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
    //creates a playable URL from the blob file.
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudio(audioUrl);
    setAudioChunks([]);
    // let data = new FormData();
    console.log("audio is", audioUrl, audioBlob);
    const d = new Date();
    const messageObj = {
      sender: props.currentUser?._id,
      receiver: props.contacts[props.active_user]?.isGroupChat
        ? filterReceivers()
        : props.contacts[props.active_user]?.user._id,
      message: "",
      media: [
        {
          fileName: "memo.wav",
          fileType: "audio/webm",
          filePath: audioUrl,
        },
      ],
      conversationId: conversationId,
      createdAt: d.toDateString(),
      callInfo: {
        isCall: false,
      },
    };

    let data = new FormData();
    data.append("attachments", audioBlob);

    const res = await uploadAttachment(data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Length": blob.size,
      },
    });
    if (res.status == 200) {
      console.log("res is", res);
      if (props.contacts[props.active_user]?.isGroupChat) {
        console.log("group message sent");
        socket.emit("sendMessageGroup", { ...messageObj, media: res.data });
      } else {
        socket.emit("sendMessage", { ...messageObj, media: res.data });
      }
      props.setMessagestoState({
        ...messageObj,
        media: res.data,
        updatedAt: d.toLocaleString(),
      });
    }
  };
  const stopRecording = useCallback(() => {
    setIsRecording(false);
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = onStop;
  }, [mediaRecorder, onStop]);

  const filterReceivers = () => {
    const arr = props.contacts[props.active_user]?.members;
    let data = [];
    for (const key in arr) {
      const user = arr[key];
      if (user._id != props?.currentUser?._id) {
        console.log("filter user", user);
        data.push(user._id);
      }
    }
    return data;
  };

  const toggle = () => setModal(!modal);

  const addMessage = (message, type) => {
    console.log(message, type);
    var messageObj = null;

    let d = new Date();
    var n = d.getSeconds();

    //matches the message type is text, file or image, and create object according to it
    switch (type) {
      case "textMessage":
        messageObj = {
          sender: props.currentUser?._id,
          receiver: props.contacts[props.active_user]?.isGroupChat
            ? filterReceivers()
            : props.contacts[props.active_user]?.user._id,
          message: message,
          media: [],
          conversationId: conversationId,
          createdAt: d.toDateString(),
          callInfo: {
            isCall: false,
          },
        };
        break;

      case "fileMessage":
        messageObj = {
          id: chatMessages.length + 1,
          message: "file",
          fileMessage: message.name,
          size: message.size,
          time: "00:" + n,
          userType: "sender",
          image: avatar4,
          isFileMessage: true,
          isImageMessage: false,
          callInfo: {
            isCall: false,
          },
        };
        break;

      case "imageMessage":
        messageObj = {
          sender: props.currentUser?._id,
          receiver: props.contacts[props.active_user]?.isGroupChat
            ? filterReceivers()
            : props.contacts[props.active_user]?.user._id,
          message: "",
          media: message,
          conversationId: conversationId,
          createdAt: d.toDateString(),
          callInfo: {
            isCall: false,
          },
        };
        break;

      default:
        break;
    }

    console.log("sending message", socket, messageObj);
    socket.on("sentGroupMessage", () => {
      console.log("message sent", messageObj);
    });

    if (props.contacts[props.active_user]?.isGroupChat) {
      console.log("group message sent");
      socket.emit("sendMessageGroup", messageObj);
    } else {
      socket.emit("sendMessage", messageObj);
    }

    props.setMessagestoState({ ...messageObj, updatedAt: d.toLocaleString() });
  };

  const deleteMessage = async (chat) => {
    console.log("del", chat);
    try {
      const res = await delMessage(chat._id);
      if (res.status == 200) {
        console.log(res);
        props.deleteMessageFromState(chat._id);
        toast.success(`Message deleted.!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formatDate = (date) => {
    const renderNumber = (value, size) => {
      const text = String(value);
      return text.padStart(size, "0");
    };
    let PM = false;
    let newHours;
    const year = renderNumber(date.getFullYear(), 4);
    const month = renderNumber(date.getMonth() + 1, 2);
    const day = renderNumber(date.getDate(), 2);
    const hours = renderNumber(date.getHours(), 2);
    const minutes = renderNumber(date.getMinutes(), 2);
    const seconds = renderNumber(date.getSeconds(), 2);
    if (hours >= 12) {
      newHours = hours - 12;
      PM = true;
    }
    if (PM) {
      return `${newHours}:${minutes} PM  ${day}/${month}/${year}  `;
    } else {
      return `${hours}:${minutes} AM  ${day}/${month}/${year}  `;
    }
  };

  const getUserofGroupChat = (arr, id) => {
    let u;
    for (const user of arr) {
      if (user._id == id) {
        u = user;
      }
    }
    return u ? u : false;
  };

  const fetchNextData = () => {
    console.log("fetching");
    props.handleFetchMore(
      conversationId,
      props.contacts[props.conversationsIds.indexOf(conversationId)]
        ?.isGroupChat,
      page++
    );
    setPage(page++);
  };

  useEffect(() => {
    if (props?.conversations[props?.active_user]) {
      setchatMessages(props?.conversations[props?.active_user][0]);
      console.log(
        "updating conv id",
        props.conversationsIds,
        props?.conversationsIds[props?.active_user]
      );
      setConversationId(props?.conversationsIds[props?.active_user]);
    }
  }, [props.active_user, props.conversations, props.conversationsIds]);

  useEffect(() => {
    bottomRef.current.recalculate();
    console.log(bottomRef.current);
    if (bottomRef.current.el) {
      bottomRef.current.getScrollElement().scrollTop =
        bottomRef.current.getScrollElement().scrollHeight;
    }
    // <- the root element you applied SimpleBar on
  });

  const renderContacts = () => {
    return props?.contacts.map((contact, i) => (
      <ListGroupItem key={i} onClick={() => forwardMessage({...contact,index:i})}>
        <div style={{ display: "flex" }} className="cursor-pointer">
          <div className="chat-user-img align-self-center me-3">
            <div className="avatar-xs">
              <img
                src={
                  contact?.isGroupChat
                    ? "https://static.vecteezy.com/system/resources/thumbnails/021/063/599/small/group-coaching-icon-vector.jpg"
                    : contact?.user?.imageSrc
                }
                className="rounded-circle avatar-xs"
                alt="chatvia"
              ></img>
            </div>
          </div>
          <span className="m-auto">{contact?.name}</span>
        </div>
      </ListGroupItem>
    ));
  };

  console.log("user chat", props);
  return (
    <React.Fragment>
      <div
        className="user-chat w-100"
        onDragOver={(e) => {
          e.preventDefault();
          setDrag(true);
        }}
        style={{ position: "relative" }}
        onDragLeave={() => setDrag(false)}
      >
        <div className="d-lg-flex">
          <div className={props.userSidebar ? "w-70" : "w-100"}>
            {/* render user head */}
            {!props.isForVideoChat && (
              <UserHead
                handleDelConversation={props.handleDelConversation}
                updateMessages={props.setMessagestoState}
                conversationId={conversationId}
                contacts={props.contacts[props.active_user]}
                currentUser={props.currentUser}
                allContacts={props.contacts}
                updateContacts={props.updateContacts}
                updateConversations={props.handleUpdateConversations}
              />
            )}

            <SimpleBar
              style={{ maxHeight: "100%" }}
              ref={bottomRef}
              className={
                props.isForVideoChat
                  ? "videochat-conversation"
                  : "chat-conversation p-3 p-lg-4"
              }
              id="messages"
            >
              <ul
                className="list-unstyled mb-0"
                id="scrollableDiv"
                style={{ height: "100%", overflow: "auto" }}
              >
                <InfiniteScroll
                  dataLength={chatMessages.length}
                  loader={<h4>Loading...</h4>}
                  // style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  next={fetchNextData}
                  inverse={true}
                  hasMore={true}
                  scrollableTarget="scrollableDiv"
                >
                  {chatMessages?.map((chat, key) =>
                    chat.isToday && chat.isToday === true ? (
                      <li key={"dayTitle" + key}>
                        <div className="chat-day-title">
                          <span className="title">Today</span>
                        </div>
                      </li>
                    ) : props.contacts[props.active_user]?.isGroupChat ===
                      true ? (
                      <li
                        key={key}
                        className={
                          chat.sender === props.currentUser?._id ? "right" : ""
                        }
                      >
                        <div className="conversation-list">
                          <div className="chat-avatar">
                            {chat.sender === props.currentUser?._id ? (
                              <img
                                src={
                                  props?.currentUser?.imageSrc &&
                                  props?.currentUser?.imageSrc
                                }
                                alt="chatvia"
                              />
                            ) : !getUserofGroupChat(
                                props?.contacts[props?.active_user]?.members,
                                chat.sender
                              ).imageSrc ? (
                              <div className="chat-user-img align-self-center me-3">
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {getUserofGroupChat(
                                      props?.contacts[props?.active_user]
                                        ?.members,
                                      chat.sender
                                    )?.contactName?.fisrt &&
                                      getUserofGroupChat(
                                        props?.contacts[props?.active_user]
                                          ?.members,
                                        chat.sender
                                      )?.contactName?.fisrt?.charAt(0)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={
                                  getUserofGroupChat(
                                    props?.contacts[props?.active_user]
                                      ?.members,
                                    chat?.sender
                                  ).imageSrc &&
                                  getUserofGroupChat(
                                    props?.contacts[props?.active_user]
                                      ?.members,
                                    chat?.sender
                                  )?.imageSrc
                                }
                                alt="chatvia"
                              />
                            )}
                          </div>
                          {chat.callInfo.isCall ? (
                            //call log start
                            <div className="user-chat-content">
                              {chatMessages[key]?.forwardedBy && chatMessages[key]?.forwardedBy._id != props.currentUser?._id && (
                                <div className="flex gap-2 ml-2">
                                  <i class="ri-share-forward-fill"></i>
                                  <span className="text-xs">
                                    {
                                      chatMessages[key]?.forwardedBy
                                        ?.contactName?.first
                                    }
                                  </span>
                                </div>
                              )}
                              <div className="ctext-wrap">
                                <div
                                  className={
                                    chatMessages[key]?.forwardedBy
                                      ? "ctext-wrap-content border-l-2 border-solid border-blue-300"
                                      : "ctext-wrap-content"
                                  }
                                >
                                  {chat.sender == props.currentUser._id ? (
                                    <i className="ri-arrow-left-up-line text-md text-red-200 float-left">
                                      {chat.callInfo.duration}
                                    </i>
                                  ) : (
                                    <i className="ri-arrow-left-down-line text-md text-red-200 float-left">
                                      {chat.callInfo.duration}
                                    </i>
                                  )}
                                  <br />
                                  <p className="w-full text-right pt-1">
                                    {chat.callInfo.duration == "00:00"
                                      ? "Missed"
                                      : ""}{" "}
                                    {chat.callInfo.callType} Call{" "}
                                    {chat.callInfo.duration == "00:00"
                                      ? ""
                                      : "Ended"}
                                  </p>
                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>{" "}
                                    <span className="align-middle">
                                      {formatDate(new Date(chat?.updatedAt))}
                                    </span>
                                  </p>
                                </div>
                                <UncontrolledDropdown className="align-self-start">
                                  <DropdownToggle tag="a">
                                    <i className="ri-more-2-fill"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => deleteMessage()}
                                    >
                                      Delete{" "}
                                      <i className="ri-delete-bin-line float-end text-muted"></i>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              {
                                <div className="conversation-name">
                                  {chat?.sender === props?.currentUser?._id
                                    ? props?.currentUser?.contactName?.first +
                                      " " +
                                      props?.currentUser?.contactName?.last
                                    : getUserofGroupChat(
                                        props?.contacts[props?.active_user]
                                          ?.members,
                                        chat.sender
                                      )?.contactName?.first +
                                      " " +
                                      getUserofGroupChat(
                                        props?.contacts[props.active_user]
                                          ?.members,
                                        chat.sender
                                      )?.contactName?.last}
                                </div>
                              }
                            </div>
                          ) : (
                            <div className="user-chat-content">
                              {chatMessages[key]?.forwardedBy && chatMessages[key]?.forwardedBy._id != props.currentUser?._id && (
                                <div className="flex gap-2 ml-2">
                                  <i class="ri-share-forward-fill"></i>
                                  <span className="text-xs">
                                    {
                                      chatMessages[key]?.forwardedBy
                                        ?.contactName?.first
                                    }
                                  </span>
                                </div>
                              )}
                              <div className="ctext-wrap">
                                <div
                                  className={
                                    chatMessages[key]?.forwardedBy
                                      ? "ctext-wrap-content border-l-2 border-solid border-blue-300"
                                      : "ctext-wrap-content"
                                  }
                                >
                                  {chat.media.length == 0 &&
                                    chat.message !== "" && (
                                      <p className="mb-0">{chat.message}</p>
                                    )}
                                  {chat.media.length > 0 &&
                                    // image list component
                                    (["image/jpeg","image/png"].includes(chat?.media[0]?.fileType) ? (
                                      <ImageList images={chat.media} />
                                    ) : chat?.media[0]?.fileType ==
                                      "audio/webm" ? (
                                      <div>
                                        <audio controls>
                                          <source
                                            src={`https://backend.itransportindex.com/api/${chat?.media[0]?.filePath}`}
                                            type="audio/webm"
                                          />
                                          Your browser does not support the
                                          audio element.
                                        </audio>
                                      </div>
                                    ) : (
                                      <div className="flex justify-between items-center">
                                        <span className="text-md text-grey-800">
                                          {chat?.media[0]?.fileName}
                                        </span>
                                        <a
                                          className="px-2"
                                          href={
                                            process.env.REACT_APP_API_URL +
                                            "/" +
                                            chat?.media[0]?.filePath
                                          }
                                          download
                                        >
                                          <i
                                            className="ri-download-cloud-2-line text-xl"
                                            style={{ marginTop: 4 }}
                                          ></i>
                                        </a>
                                      </div>
                                    ))}
                                  {chat.fileMessage && (
                                    //file input component
                                    <FileList
                                      fileName={chat.fileMessage}
                                      fileSize={chat.size}
                                    />
                                  )}
                                  {chat.isTyping && (
                                    <p className="mb-0">
                                      typing
                                      <span className="animate-typing">
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                      </span>
                                    </p>
                                  )}
                                  {!chat.isTyping && (
                                    <p className="chat-time mb-0">
                                      <i className="ri-time-line align-middle"></i>{" "}
                                      <span className="align-middle">
                                        {formatDate(new Date(chat.updatedAt))}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                {!chat.isTyping && (
                                  <UncontrolledDropdown className="align-self-start">
                                    <DropdownToggle tag="a">
                                      <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>
                                        {t("Reply")}{" "}
                                        <i className="ri-file-copy-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem>
                                        {t("Quote")}{" "}
                                        <i className="ri-file-copy-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem>
                                        {t("Copy")}{" "}
                                        <i className="ri-file-copy-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem>
                                        {t("Save")}{" "}
                                        <i className="ri-save-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem onClick={() =>
                                          handleForwardCLick(
                                            chatMessages[key],
                                            chat
                                          )
                                        }>
                                        Forward{" "}
                                        <i className="ri-chat-forward-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => deleteMessage(chat)}
                                      >
                                        Delete{" "}
                                        <i className="ri-delete-bin-line float-end text-muted"></i>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </div>
                              {
                                <div className="conversation-name">
                                  {chat?.sender === props?.currentUser?._id
                                    ? props?.currentUser?.contactName?.first +
                                      " " +
                                      props?.currentUser?.contactName?.last
                                    : getUserofGroupChat(
                                        props?.contacts[props?.active_user]
                                          ?.members,
                                        chat.sender
                                      )?.contactName?.first +
                                      " " +
                                      getUserofGroupChat(
                                        props?.contacts[props.active_user]
                                          ?.members,
                                        chat.sender
                                      )?.contactName?.last}
                                </div>
                              }
                            </div>
                          )}
                        </div>
                      </li>
                    ) : (
                      <li
                        key={key}
                        className={
                          chat.sender === props.currentUser._id ? "right" : ""
                        }
                      >
                        <div className="conversation-list">
                          <div className="chat-avatar">
                            {chat.sender === props.currentUser._id ? (
                              <img
                                src={props.currentUser.imageSrc}
                                alt="chatvia"
                              />
                            ) : props.contacts[props.active_user].user
                                .imageSrc === "Null" ? (
                              <div className="chat-user-img align-self-center me-3">
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {props.contacts[
                                      props.active_user
                                    ].name.charAt(0)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={
                                  props.contacts[props.active_user].user
                                    .imageSrc
                                }
                                alt="chatvia"
                              />
                            )}
                          </div>
                          {chatMessages[key].callInfo.isCall ? (
                            //call log start
                            <div className="user-chat-content">
                              <div className="ctext-wrap">
                                <div className="ctext-wrap-content">
                                  {chat.sender == props.currentUser._id ? (
                                    <i className="ri-arrow-left-up-line text-md text-red-200 float-left">
                                      {chatMessages[key].callInfo.duration}
                                    </i>
                                  ) : (
                                    <i className="ri-arrow-left-down-line text-md text-red-200 float-left">
                                      {chatMessages[key].callInfo.duration}
                                    </i>
                                  )}
                                  <br />
                                  <p className="w-full text-left pt-1">
                                    {chatMessages[key].callInfo.duration ==
                                    "00:00"
                                      ? "Missed"
                                      : ""}{" "}
                                    {chatMessages[key].callInfo.callType} Call{" "}
                                    {chatMessages[key].callInfo.duration ==
                                    "00:00"
                                      ? ""
                                      : "Ended"}
                                  </p>
                                  <p className="chat-time mb-0">
                                    <i className="ri-time-line align-middle"></i>{" "}
                                    <span className="align-middle">
                                      {formatDate(new Date(chat?.updatedAt))}
                                    </span>
                                  </p>
                                </div>
                                <UncontrolledDropdown className="align-self-start">
                                  <DropdownToggle tag="a">
                                    <i className="ri-more-2-fill"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => deleteMessage(chat)}
                                    >
                                      Delete{" "}
                                      <i className="ri-delete-bin-line float-end text-muted"></i>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>

                              <div className="conversation-name">
                                {chat.sender === props?.currentUser?._id
                                  ? props?.currentUser?.contactName?.first +
                                    " " +
                                    props.currentUser?.contactName?.last
                                  : props?.contacts[props.active_user]?.name}
                              </div>
                            </div>
                          ) : (
                            // call log end
                            <div className="user-chat-content">
                              {chatMessages[key]?.forwardedBy && chatMessages[key]?.forwardedBy._id != props.currentUser?._id && (
                                <div className="flex gap-2 ml-2">
                                  <i class="ri-share-forward-fill"></i>
                                  <span className="text-xs">
                                    {
                                      chatMessages[key]?.forwardedBy
                                        ?.contactName?.first
                                    }
                                  </span>
                                </div>
                              )}
                              <div className="ctext-wrap">
                                <div
                                  className={
                                    chatMessages[key]?.forwardedBy
                                      ? "ctext-wrap-content border-l-2 border-solid border-blue-300"
                                      : "ctext-wrap-content"
                                  }
                                >
                                  {chat.message && (
                                    <p className="mb-0">{chat.message}</p>
                                  )}
                                  {chat.media &&
                                    chat.media.length > 0 &&
                                    // image list component
                                    (["image/jpeg","image/png"].includes(chat?.media[0]?.fileType) ? (
                                      <ImageList images={chat.media} />
                                    ) : chat?.media[0]?.fileType ==
                                      "audio/webm" ? (
                                      <div>
                                        <audio controls>
                                          <source
                                            src={`https://backend.itransportindex.com/api/${chat?.media[0]?.filePath}`}
                                            type="audio/webm"
                                          />
                                          Your browser does not support the
                                          audio element.
                                        </audio>
                                      </div>
                                    ) : (
                                      <div className="flex justify-between items-center">
                                        <span className="text-md text-grey-800">
                                          {chat?.media[0]?.fileName}
                                        </span>
                                        <a
                                          className="px-2"
                                          href={
                                            process.env.REACT_APP_API_URL +
                                            "/" +
                                            chat?.media[0]?.filePath
                                          }
                                          download
                                        >
                                          <i
                                            className="ri-download-cloud-2-line text-xl"
                                            style={{ marginTop: 4 }}
                                          ></i>
                                        </a>
                                      </div>
                                    ))}
                                  {chat.fileMessage && (
                                    //file input component
                                    <FileList
                                      fileName={chat.fileMessage}
                                      fileSize={chat.size}
                                    />
                                  )}
                                  {chat.isTyping && (
                                    <p className="mb-0">
                                      typing
                                      <span className="animate-typing">
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                      </span>
                                    </p>
                                  )}
                                  {!chat.isTyping && (
                                    <p className="chat-time mb-0">
                                      <i className="ri-time-line align-middle"></i>{" "}
                                      <span className="align-middle">
                                        {formatDate(new Date(chat?.updatedAt))}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                {!chat.isTyping && (
                                  <UncontrolledDropdown className="align-self-start">
                                    <DropdownToggle tag="a">
                                      <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem>
                                        {t("Copy")}{" "}
                                        <i className="ri-file-copy-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem>
                                        {t("Save")}{" "}
                                        <i className="ri-save-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() =>
                                          handleForwardCLick(
                                            chatMessages[key],
                                            chat
                                          )
                                        }
                                      >
                                        Forward{" "}
                                        <i className="ri-chat-forward-line float-end text-muted"></i>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => deleteMessage(chat)}
                                      >
                                        Delete{" "}
                                        <i className="ri-delete-bin-line float-end text-muted"></i>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </div>
                              <div className="conversation-name">
                                {chat.sender === props?.currentUser?._id
                                  ? props?.currentUser?.contactName?.first +
                                    " " +
                                    props.currentUser?.contactName?.last
                                  : props?.contacts[props.active_user]?.name}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    )
                  )}
                </InfiniteScroll>
              </ul>
            </SimpleBar>

            <Modal backdrop="static" isOpen={modal} centered toggle={toggle}>
              <ModalHeader toggle={toggle}>Forward to...</ModalHeader>
              <ModalBody>
                <CardBody className="p-2">
                  <SimpleBar style={{ maxHeight: "200px" }}>
                    <ListGroup
                      style={{ maxHeight: "236px", overflowY: "scroll" }}
                    >
                      {/* <SelectContact handleCheck={() => {}} /> */}
                      {renderContacts()}
                    </ListGroup>
                  </SimpleBar>
                </CardBody>
              </ModalBody>
            </Modal>

            <ChatInput
              onaddMessage={addMessage}
              className={props.isForVideoChat ? "video-chat-input" : ""}
              isForVideoChat={props.isForVideoChat}
              isDrag={isDrag}
              setDrag={setDrag}
              isRecording={isRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
            />
          </div>

          {!props.isForVideoChat && (
            <UserProfileSidebar
              activeUser={props.contacts[props.active_user]}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { userSidebar } = state.Layout;
  return { userSidebar };
};

export default withRouter(connect(mapStateToProps, {})(UserChat));
