import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import App from "./../app.jsx";

import auth from "./../services/authservice";

import DashboardV3 from "./../pages/dashboard/dashboard-v3.js";


import ExtraTimeline from "./../pages/extra/extra-timeline.js";
import ExtraComingSoon from "./../pages/extra/extra-coming-soon.js";
import ExtraSearch from "./../pages/extra/extra-search.js";
import ExtraInvoice from "./../pages/extra/extra-invoice.js";
import ExtraError from "./../pages/extra/extra-error.js";
import ExtraProfile from "./../pages/extra/extra-profile.js";
import ExtraScrumBoard from "./../pages/extra/extra-scrum-board.js";
import ExtraCookieAcceptanceBanner from "./../pages/extra/extra-cookie-acceptance-banner.js";
import ExtraOrders from "./../pages/extra/extra-orders.js";
import ExtraOrderDetails from "./../pages/extra/extra-order-details.js";
import ExtraProducts from "./../pages/extra/extra-products.js";
import ExtraProductDetails from "./../pages/extra/extra-product-details.js";

import LoginV2 from "./../pages/user/login.js";

import RegisterV3 from "./../pages/user/register-v3.js";

import Logout from "./../common/logout";

//////////////////////////////////////////////////////////////////////////////////////////////
import User from "./../pages/clinic/user";
import UserTable from "./../pages/clinic/users.js";
import ClinicSolo from "./../pages/clinic/clinicsolo.js";
import ClinicSoloTable from "./../pages/clinic/clinicsolos.js";
import Patient from "./../pages/clinic/patient.js";
import PatientTable from "./../pages/clinic/patients.js";
import Doctor from "./../pages/clinic/doctor.js";
import DoctorTable from "./../pages/clinic/doctors.js";
import Accountant from "./../pages/clinic/accountant.js";
import AccountantTable from "./../pages/clinic/accountants.js";
import Reception from "./../pages/clinic/reception.js";
import ReceptionTable from "./../pages/clinic/receptions.js";
import Contacts from "./../pages/contacts/Contacts";
import CompanyTableData from "../pages/user/companies.js";
import CompanySolo from "../pages/user/company.js";
import ChatBoard from "../pages/messenger/index";
import MessengerContacts from "../pages/messenger/Contacts";
import VideoMeetingTableData from "../pages/messenger/videomeetings.js";
import VideoMeeting from "../pages/messenger/videomeeting.js";
import Conversations from "../pages/chatGPT/Conversations.jsx";
import VideocallIndex from "../pages/messenger/Videocall/index.jsx";
import AudiocallIndex from "../pages/messenger/Audiocall/index.jsx";
import JoinMeeting from "../pages/messenger/joinmeeting.js";
import VideoMeetingSession from "../pages/messenger/VideoMeetingSession.js";


import ConsultsTableData from "../pages/chatGPT/Consults.jsx";

function RequireAuth({ children }) {
  const isAuthenticated = auth.getProfile() !== null;

  return isAuthenticated ? children : <Navigate to="/login" replace />;
}

const Home = () => {
  const isAuthenticated = auth.getProfile() != null;
  return isAuthenticated ? (
      <Navigate to="/dashboard" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

const AppRoute = [
	{
		path: '',
		element: <Home />
	},
	{
		path: '*',
		element: <App />,
		children: [
			{
				//path: 'login/*', 
				path: 'login/',
				element: <LoginV2 />,

			},
			{
				path: '',
				element: <RequireAuth><DashboardV3 /></RequireAuth>
			},
			{
				path: '*',
				element: <ExtraError />
			},
			{
				path: 'dashboard/*',
				element: <RequireAuth><DashboardV3 /></RequireAuth>
			},
			{
				path: "logout/",
				element: <Logout />
			},

			{
				path: 'clinic/*',
				element: <Outlet />,
				children: [
					{ path: 'users/:id', element: <User /> },
					{ path: 'users/', element: <UserTable /> },
					{ path: 'patients/:id', element: <Patient /> },
					{ path: 'patients/', element: <PatientTable /> },
					{ path: 'doctors/:id', element: <Doctor /> },
					{ path: 'doctors/', element: <DoctorTable /> },
					{ path: 'accountants/:id', element: <Accountant /> },
					{ path: 'accountants/', element: <AccountantTable /> },
					{ path: 'receptions/:id', element: <Reception /> },
					{ path: 'receptions/', element: <ReceptionTable /> },
					{ path: 'clinicsolos/:id', element: <ClinicSolo /> },
					{ path: 'clinicsolos/', element: <ClinicSoloTable /> },
				]
			},
			{
				path: 'user/*',
				element: <Outlet />, 
				children: [
					{ path: 'companies/', element: <CompanyTableData /> },
					{path: 'companies/:id',element:<CompanySolo/>}
					
				]
			},
			{
				path: 'contacts/*',
				element: <Outlet />,
				children: [
					{ path: '', element: <Contacts /> }

				]
			},
			{
				path: 'messenger/*',
				element: <Outlet />,
				children: [
					{ path: 'chats', element: <ChatBoard/> },
					{ path: 'contacts', element: <MessengerContacts/> },
					{ path: 'videomeetings', element: <VideoMeetingTableData/> },
					{ path: 'videomeeting', element: <VideoMeeting/> },
					{ path: 'videocall', element: <VideocallIndex/> },
					{ path: 'audiocall', element: <AudiocallIndex/> },
					{ path: 'joinmeeting', element: <JoinMeeting/> },
					{ path: 'videomeeting-session', element: <VideoMeetingSession/> },

				]
			},
			{
				path: 'ChatGPT/*',
				element: <Outlet />,
				children: [
					{ path: 'consult', element: <Conversations/> },
					{ path: 'consults', element: <ConsultsTableData/> },
					
				]
			},
			{
				path: 'extra/*',
				element: <Outlet />,
				children: [
					{ path: 'timeline', element: <ExtraTimeline /> },
					{ path: 'coming-soon', element: <ExtraComingSoon /> },
					{ path: 'search', element: <ExtraSearch /> },
					{ path: 'invoice', element: <ExtraInvoice /> },
					{ path: 'error', element: <ExtraError /> },
					{ path: 'profile', element: <ExtraProfile /> },
					{ path: 'scrum-board', element: <ExtraScrumBoard /> },
					{ path: 'cookie-acceptance-banner', element: <ExtraCookieAcceptanceBanner /> },
					{ path: 'orders', element: <ExtraOrders /> },
					{ path: 'order-details', element: <ExtraOrderDetails /> },
					{ path: 'products', element: <ExtraProducts /> },
					{ path: 'product-details', element: <ExtraProductDetails /> }
				]
			},


		]
	}
];

export default AppRoute;
