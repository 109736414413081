import React, { useMemo } from "react";
import Mic from "../../../assets/Icons/mic.svg";
import MicMute from "../../../assets/Icons/mic-mute.svg";
import Camera from "../../../assets/Icons/camera.svg";
import CameraOff from "../../../assets/Icons/camera-off.svg";
import Phone from "../../../assets/Icons/phone.svg";

import Video from "../Video";

const Videocall = (props) => {
  const {
    currentUser,
    remoteUser,
    localStream,
    remoteStream,
    closeCall,
    startVideo,
    stopVideo,
    toggleMute,
    isVideoTrack,
    isMuted,
    isVideo,
  } = props;

  const endCall = () => {
    closeCall();
  };
  console.log("video call props", props);
  const myVideoClass = isVideo ? "my-video " : "my-video audio ";
  const remoteVideoClass = isVideo ? "remote-video " : "remote-audio ";
  return (
    <>
      <div className="pt-4 flex flex-row gap-2 text-white w-full md:w-[60%]">
        <div
          className={remoteVideoClass + "rounded-lg h-[70vh] md:h-[500px]"}
          style={{ width: "100%" }}
        >
          {isVideo && (
            <div
              className={
                myVideoClass +
                "rounded-lg flex justify-center items-center text-base font-semibold"
              }
              style={{ position: "absolute" }}
            >
              <div
                className="flex items-center"
                style={
                  isVideo
                    ? { position: "absolute", zIndex: 2, top: 10, left: 10 }
                    : {
                        position: "absolute",
                        zIndex: 2,
                        top: "35%",
                        left: "40%",
                        display: "flex",
                        flexDirection: "column",
                      }
                }
              >
                {currentUser && currentUser?.imageSrc ? (
                  <div className="me-1 ms-0">
                    <img
                      src={currentUser?.imageSrc}
                      className="rounded-circle avatar-xs"
                      alt="chatvia"
                    />
                  </div>
                ) : (
                  <div className="chat-user-img align-self-center me-3">
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                        {currentUser?.username}
                      </span>
                    </div>
                  </div>
                )}
                <span>Me</span>
              </div>

              {isVideo &&
                (isVideoTrack ? (
                  <Video
                    stream={localStream}
                    id="my-video"
                    muted={true}
                    style={{
                      transform: `rotateY(180deg)`,
                      zIndex: 1,
                      marginTop: 0,
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  "Camera is off"
                ))}
            </div>
          )}

          <div
            className="flex items-center"
            style={
              isVideo
                ? { position: "absolute", zIndex: 2, top: 30, left: 30 }
                : {
                    position: "absolute",
                    zIndex: 2,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            {remoteUser && remoteUser?.imageSrc ? (
              <div className={!isVideo ? "" : "me-3 ms-0"}>
                <img
                  src={remoteUser?.imageSrc}
                  className="rounded-circle avatar-xs"
                  alt="chatvia"
                />
              </div>
            ) : (
              <div className="chat-user-img align-self-center me-3">
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                    {remoteUser?.username}
                  </span>
                </div>
              </div>
            )}
            <span>{remoteUser?.contactName.first}</span>
            {isVideo &&
              (() => {
                switch ("online") {
                  case "online":
                    return (
                      <>
                        <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
                      </>
                    );

                  case "away":
                    return (
                      <>
                        <i className="ri-record-circle-fill font-size-10 text-warning d-inline-block ms-1"></i>
                      </>
                    );

                  case "offline":
                    return (
                      <>
                        <i className="ri-record-circle-fill font-size-10 text-secondary d-inline-block ms-1"></i>
                      </>
                    );

                  default:
                    return;
                }
              })()}
          </div>

          <div
            id="cam"
            className={
              isVideo
                ? "w-full h-[65vh] bg-black rounded-lg text-white flex flex-col justify-center items-center text-base font-semibold relative"
                : "w-full h-[65vh] bg-white rounded-lg text-black flex flex-col justify-center items-center text-base font-semibold relative"
            }
            style={
              isVideo
                ? { height: "500px" }
                : { height: "500px", backgroundColor: "white", color: "black" }
            }
          >
            <Video
              stream={remoteStream}
              id="my-video"
              muted={false}
              style={{
                transform: `rotateY(180deg)`,
                zIndex: 1,
                marginTop: 0,
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 20,
                zIndex: 10,
              }}
              className="flex justify-center gap-4 "
            >
              <div
                className="w-9 h-9 rounded-full bg-blue-200 justify-center items-center flex cursor-pointer shadow-sm"
                onClick={toggleMute}
              >
                {isMuted ? (
                  <img src={MicMute} alt="mic-mute" />
                ) : (
                  <img src={Mic} alt="mic" />
                )}
              </div>

              {isVideo && (
                <div className="w-9 h-9 rounded-full bg-blue-200 justify-center items-center flex cursor-pointer shadow-sm">
                  {!isVideoTrack ? (
                    <img
                      src={CameraOff}
                      alt="camera-off"
                      onClick={startVideo}
                    />
                  ) : (
                    <img src={Camera} alt="camera" onClick={stopVideo} />
                  )}
                </div>
              )}

              <div
                className="w-9 h-9 rounded-full bg-red-200 justify-center items-center flex cursor-pointer shadow-sm"
                onClick={endCall}
              >
                <img src={Phone} alt="phone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videocall;
