import http from "./httpService";
const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + "/attachments";


export const deleteAttachments = async (filePath, id, type) => {
  await http.delete(apiEndpoint + "/" + id + "/" + type + "/" + filePath);
};


export const uploadAttachment = (files,config) => {

  if(config){
    return http.post(apiEndpoint, files,config);
  }
  return http.post(apiEndpoint, files);


};