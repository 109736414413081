import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { getProfile } from "../../services/authservice";
import { getUser, getUsers, updateUser } from "../../services/users";
import Loader from "../../common/loader";
import { toast } from "react-toastify";

import { withTranslation } from "react-i18next";
//use sortedContacts variable as global variable to sort contacts
let sortedContacts = [
  {
    group: "A",
    children: [],
  },
];

class MessengerContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      mainContacts: [],
      contacts: [],
      users: [],

      selectedUser: "",
      isLoading: false
    };
    this.toggle = this.toggle.bind(this);
    this.sortContact = this.sortContact.bind(this);
  }

  async onRefresh() {
    return new Promise((resolve) => {
      this.getContacts();
      setTimeout(resolve, 1000);
    });
  }

  // componentDidUpdate(prevProps) {
    // if (prevProps !== this.props) {
    //   this.setState({
    //     contacts: this.props.contacts,
    //     mainContacts: this.props.contacts
    //   });
    // }
    // this.onRefresh();
  // }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }



  searchContacts(e) {
    const search = e.target.value;


  }

  sortContact() {
    this.setState({
      ...this.state,
      isLoading: true
    })
    let data = this.state.mainContacts.reduce((r, e) => {
      try {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
      } catch (error) {
        return sortedContacts;
      }
      // return accumulator
      return r;
    }, {});

    // since data at this point is an object, to get array of values
    // we use Object.values method
    let result = Object.values(data);
    console.log("sorting", result);
    this.setState({ contacts: result ,isLoading:false});
    sortedContacts = result;
    return result;
  }

  handleSelectContact(e) {
    this.setState({
      ...this.state,
      selectedUser: e.target.value,
    });
  }


  async handleSaveContact() {
    this.setState({
      ...this.state,
      isLoading: true
    })
    const token = await getProfile();
    const selected = this.state.users.find(
      (user) => user._id === this.state.selectedUser
    );
    const already = this.state.mainContacts.find(user => {
      console.log("find", user.user._id, this.state.selectedUser)
      if (user.user._id === this.state.selectedUser) {
        return user.user._id
      }
    });
    console.log("already", already)
    if (already) {
      toast.warning(
        `Already in contacts`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    } else if (this.state.selectedUser === token._id) {
      toast.warning(
        `You can not add yourself..!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }
    else {
      const tempContact = {
        user: selected._id,
        star: false,
        name: selected.contactName.first + " " + selected.contactName.last,
        phone: selected.phones.phone,
        about: selected.about,
        function: "",
      };
      const cntcts = [...this.state.mainContacts];
      cntcts.push(tempContact);
      console.log("cntcts after add ", cntcts)
      const res = await updateUser(token._id, { contacts: cntcts });
      if (res.status == 200) {
        console.log("contact saved", res);
        this.setState({
          ...this.state,
          modal: false,
          isLoading: false,
          mainContacts: cntcts,
        }, () => {
          this.sortContact();
        })

        toast.success(
          `Contact Added.!`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

      }
    }

  }

  getContacts() {
    const token = getProfile();
    console.log("token is", token);
    getUser(token._id).then((res) => {
      console.log("res is", res.data);
      this.setState({
        ...this.state,
        mainContacts: res.data.contacts
      }, () => this.sortContact());

    })

  }

  async getData() {
    this.setState({
      ...this.state,
      isLoading: true
    });
    this.getContacts();
    this.setState({
      ...this.state,
      isLoading: false
    });
    getUsers().then((allUsers) => {
      console.log("all users", allUsers.data);
      this.setState({
        ...this.state,
        users: allUsers.data,
      });
    });


  }


  async handleDeleteContact(child, key) {
    this.setState({
      ...this.state,
      isLoading: true
    })
    const token = await getProfile();

    let newContacts = [];

    for (let cntct of this.state.mainContacts) {
      if (cntct._id !== child._id) {
        console.log(cntct)
        newContacts.push(cntct)
      }
    }
    console.log("after del", newContacts)
    const res = await updateUser(token._id, { contacts: newContacts });
    if (res.status == 200) {
      console.log("contact deleted", res);
      this.setState({
        ...this.state,
        contact: [...newContacts],
        mainContacts: [...newContacts],
        isLoading: false
      }, () => {
        console.log("before osrt")
        this.sortContact();
      });

      toast.success(
        `Contact Deleted..!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }


  }
  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this.sortContact();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>

        <div>
          <div className="p-4">
            <div className="user-chat-nav float-end">
              <div id="add-contact">
                {/* Button trigger modal */}
                <Button
                  type="button"
                  color="link"
                  onClick={this.toggle}
                  className="text-decoration-none text-muted font-size-18 py-0"
                >
                  <i className="ri-user-add-line"></i>
                </Button>
              </div>
              <UncontrolledTooltip target="add-contact" placement="bottom">
                Add Contact
              </UncontrolledTooltip>
            </div>
            <h4 className="mb-4">Contacts</h4>

            {/* Start Add contact Modal */}
            <Modal isOpen={this.state.modal} centered toggle={this.toggle}>
              <ModalHeader
                tag="h5"
                className="font-size-16"
                toggle={this.toggle}
              >
                {t("Add Contacts")}
              </ModalHeader>
              <ModalBody className="p-4">
                <Form>
                  <div className="mb-4">
                    <Label
                      className="form-label"
                      htmlFor="addcontactemail-input"
                    >
                      {t("Users")}
                    </Label>
                    <select
                      name="users"
                      value={this.state.selectedUser}
                      className="form-control"
                      onChange={(e) => this.handleSelectContact(e)}
                    >
                      <option value="" key="gjhb">
                        Select User
                      </option>
                      {this.state.users.map((user, key) => {
                        return (
                          <option value={user._id} key={key}>
                            {user.contactName.first +
                              " " +
                              user.contactName.last}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="link" onClick={this.toggle}>
                  Close
                </Button>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => this.handleSaveContact()}
                >
                  Invite Contact
                </Button>
              </ModalFooter>
            </Modal>
            {/* End Add contact Modal */}

            <div className="search-box chat-search-box">
              <InputGroup size="lg" className="bg-light rounded-lg">
                <Button
                  color="link"
                  className="text-decoration-none text-muted pr-1"
                  type="button"
                >
                  <i className="ri-search-line search-icon font-size-18"></i>
                </Button>
                <Input
                  type="text"
                  className="form-control bg-light "
                  placeholder={t("Search users..")}
                />
              </InputGroup>
            </div>
            {/* End search-box */}
          </div>
          {/* end p-4 */}

          {/* Start contact lists */}
          {this.state.isLoading ? <div className="w-full h-50 flex justify-center item-center"><Loader /></div> :
              <SimpleBar
                style={{ maxHeight: "100%" }}
                id="chat-room"
                className="p-4 chat-message-list chat-group-list"
              >
                {this.state.contacts.map((contact, key) => (
                  <div key={key} className={key + 1 === 1 ? "" : "mt-3"}>
                    <div className="p-3 fw-bold text-primary">{contact.group}</div>

                    <ul className="list-unstyled contact-list">
                      {contact?.children?.map((child, key) => (
                        <li key={key}>
                          <div className="d-flex align-items-center">
                            <div className="chat-user-img align-self-center me-3">
                              <div className="avatar-xs">
                                <img src={child?.user?.imageSrc} className="rounded-circle avatar-xs" alt="chatvia"></img>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="font-size-14 m-0">{child.name}</h5>
                            </div>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-muted">
                                <i className="ri-more-2-fill"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem>
                                  {t("Share")}{" "}
                                  <i className="ri-share-line float-end text-muted"></i>
                                </DropdownItem>
                                <DropdownItem>
                                  {t("Block")}{" "}
                                  <i className="ri-forbid-line float-end text-muted"></i>
                                </DropdownItem>
                                <DropdownItem onClick={() => this.handleDeleteContact(child, key)}>
                                  {t("Remove")}{" "}
                                  <i className="ri-delete-bin-line float-end text-muted"></i>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </SimpleBar>
          }
        </div>
      </React.Fragment>
    );
  }
}



export default withTranslation()(MessengerContacts);
