import React ,{useEffect} from 'react';

const Video = ({ stream,id,muted,style }) => {
    const localVideo = React.createRef();
  
    useEffect(() => {
      // Let's update the srcObject only after the ref has been set
      // and then every time the stream prop updates
      console.log("video.js",stream)
      if (localVideo.current) localVideo.current.srcObject = stream.current;
    }, [stream, localVideo]);
  
    return (
        <video style={style? style : {}} ref={localVideo} autoPlay id={id ? id: ""} muted={muted ? true:false } className='rounded-lg'/>
    );
  };

  export default Video;