import React, { useEffect, useState } from "react";
import "./videoMeeting.css";
import { verifyMeeting } from "./../../services/videomeetings";
import { ToastContainer, toast } from "react-toastify";
import { useSocket } from "../../store/context/SocketProvider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function JoinMeeting(props) {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const {startMeeting} = useSocket();
  const navigate = useNavigate();
  const msngr = useSelector(state=>state.messenger);
  console.log("messenger slice",msngr)
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(id, password, "submit");

    try {
      const res = await verifyMeeting(id, password);
      if (res.status == 200) {
        console.log("ok data", res, props);
        toast.success("Meeting joined.!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        startMeeting(id);
        navigate("/messenger/videomeeting-session")
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };


  return (
    <div id="join-meeting-wrapper">
      <h1>Join Meeting</h1>
      <br />
      <form id="join-meeting-form" onSubmit={handleSubmit}>
        <div className="form-group column">
          <label className="col-form-label" htmlFor="meetingID">
            Meeting ID
          </label>
          <div className="row-lg-10">
            <div className="row">
              <input
                type="text"
                className="form-control m-b-5"
                name="meetingID"
                id="meetingID"
                value={id}
                onChange={(e) => setId(e.target.value)}
                placeholder="Enter Meeting ID"
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group column">
          <label className="col-lg-6 col-form-label" htmlFor="meetingID">
            password
          </label>
          <div className="row-lg-10">
            <div className="row row-space-10">
              <input
                type="password"
                className="form-control m-b-5"
                name="meetingID"
                id="meetingID"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
            </div>
          </div>
        </div>
        <br />
        <div className="form-group column">
          <div className="col-lg-12">
            <button type="submit" className="btn btn-primary btn-join">
              Join
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
