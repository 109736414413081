import React from 'react';
import { Link } from "react-router-dom";

//carousel
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'


const OnlineUsers = (props) => {
    const responsive = {
        0: { items: 4 },
        1024: { items: 4 },
    }
    return (
        <React.Fragment>
            <div className="px-4 pb-4 dot_remove" dir="ltr" >
                <AliceCarousel
                    responsive={responsive}
                    disableDotsControls={false}
                    disableButtonsControls={false}
                    mouseTracking
                >
                    {
                        props?.contacts?.map((contact,index) =>
                            !contact?.isGroupChat && <div className="item" onClick={()=>{}} key={index}>
                                <Link to="#" className="user-status-box">
                                    <div className="avatar-xs mx-auto d-block chat-user-img online">
                                        <img src={contact?.user?.imageSrc} alt="user-img" className="rounded-circle avatar-xs" />
                                        <span className="user-status"></span>
                                    </div>

                                    <h5 className="font-size-13 text-truncate mt-3 mb-1 text-center">{contact?.name}</h5>
                                </Link>
                            </div>
                        )
                    }


                </AliceCarousel>
            </div>
        </React.Fragment>
    );
}

export default OnlineUsers;