import React, { memo, useRef } from "react";
import "../../../assets/scss/themes.scss";
import { configureStore } from "../../../store/messenger/store";
import { Provider } from "react-redux";
//Import Components
import "./index.css";
import Layout from "../Chats/Layout";
import Videocall from "./Videocall";
import { useCallContext } from "../../../store/context/CallProvider";
import { useSocket } from "../../../store/context/SocketProvider";
import UserChat from "../Chats/UserChat";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import {
  updateMessages,
  deleteMessage,
  updateContacts,
  updateConversations,
  deleteContacts,
} from "../../../store/messengerSlice";
import { useDispatch } from "react-redux";

const VideocallIndex = () => {
  const {
    remoteUser,
    localStream,
    remoteStream,
    closeCall,
    startVideo,
    stopVideo,
    toggleMute,
    isVideoTrack,
    isMuted,
    isVideo,
  } = useCallContext();

  const messenger = useSelector((state) => state.messenger);
  const dispatch = useDispatch();

  const setMessagestoState = (obj) => {
    dispatch(
      updateMessages({
        key: messenger.activeUser,
        message: obj,
      })
    );
  };

  const deleteMessageFromState = (id) => {
    dispatch(
      deleteMessage({
        id,
      })
    );
  };

  const handleUpdateContacts = (cntcts) => {
    dispatch(updateContacts(cntcts));
  };

  const handleUpdateConversations = (data) => {
    dispatch(updateConversations([data]));
  };

  const handleDelConversation = (id) => {
    dispatch(deleteContacts(id));
  };
  console.log("chat board", messenger);

  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  localStreamRef.current = localStream;
  remoteStreamRef.current = remoteStream;
  const { me } = useSocket();
  console.log("is Video is", isVideo);
  return (
    <Provider store={configureStore()}>
      <React.Fragment>
        <Layout>
          <Videocall
            currentUser={me}
            remoteUser={remoteUser.current}
            localStream={localStreamRef}
            remoteStream={remoteStreamRef}
            closeCall={closeCall}
            startVideo={startVideo}
            stopVideo={stopVideo}
            toggleMute={toggleMute}
            isVideoTrack={isVideoTrack}
            isMuted={isMuted}
            isVideo={isVideo}
          />
          <div className="w-[40%] bg-white rounded-md mt-6 flex flex-col gap-1 p-2 ml-2" id="call-chat">
            <div className="w-full">
              <div className="text-black text-2xl py-2 pl-2">Chat</div>
              <UserChat
                isForVideoChat={true}
                contacts={messenger.contacts}
                currentUser={messenger.currentUser}
                active_user={messenger.activeUser}
                conversations={messenger.conversations}
                setMessagestoState={setMessagestoState}
                deleteMessageFromState={deleteMessageFromState}
                updateContacts={handleUpdateContacts}
                conversationsIds={messenger.conversationsIds}
                handleUpdateConversations={handleUpdateConversations}
                handleDelConversation={handleDelConversation}
              />
            </div>
          </div>
        </Layout>
      </React.Fragment>
    </Provider>
  );
};

export default memo(VideocallIndex);
