import React, { Component } from "react";
import Table from "./../common/table";
import Status from "./user/ActiveStatus";
import { Link, withRouter } from "react-router-dom";
import Gender from "./user/Gender"


class CompaniesTable extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		values: [],
	// 	};
	// }

	columns = [
		//   {path: '_id', label: 'Id'},
		{
			key: "checkbox",
			label: (
				<input
					type="check"
					style={{
						width: "15px",
						height: "15px",
						marginTop: "0.4rem",
						borderRadius: 0,
					}}
				/>
			),
			content: (company) => (
				<span className="icon-img sm-r-5" style={{ marginTop: "15px" }}>
					<input
						type="checkbox"
						style={{
							width: "15px",
							height: "15px",
							marginTop: "0.4rem",
							borderRadius: 0,
						}}
						onChange={this.props.handleCheckboxChange}
						value={company._id}
					/>
				</span>
			),
		},
		{
			key: "Username",
			label: "username",
			content: (user) => {
				return  (
					<div className="flex justify-start space-x-2 items-center px-4">
						<img className="w-12 h-6 rounded-full" src={user.imageSrc} alt="No Image"/>
						<p className="pt-2 font-bold">{user.companies.username}</p>
        			</div>
				)
			}
		},
		// {label: 'Username',   path: 'companies.username' } ,	
		{label: 'email',   path: 'companies.email' } ,   
		{label: 'CompanyNo',   path: 'clinicSoloNo' } ,   
		{label: 'Company',   path: 'companyInfo.businessName' } ,       
		{label: 'Firstname',   path: 'companies.contactName.first' } ,   
		{label: 'initials',   path: 'companies.contactName.initials' } ,   	  
		{label: 'Lastname',   path: 'companies.contactName.last' } ,   	  		
		{
			label: "DOB",
			content: (user) => {
				return  (
					<DateComponent date={user.companies.dateBirth} />
				)
			}
		},

		{
			label: "Status",
			content: (user) => <Status value={user.companies.status} />
		},

		{
			label: "Gender",
			content: (user) =>  <Gender value={user.companies.gender} />
		},

		{label: 'Address 1',   path: 'companies.Address.address1' } ,   
		{label: 'Address 2',   path: 'companies.Address.address2' } ,   
		{label: 'Address 3',   path: 'companies.Address.address3' } ,           
		{label: 'zip',   path: 'companies.Address.zip' } ,   	  
		{label: 'city',   path: 'companies.Address.city' } ,   	      
		{label: 'state',   path: 'companies.Address.state' } , 
		// {
		// 	label: "Country",
		// 	content: (user) => {
		// 		return <p>{user.companies.country}</p>
		// 	}
		// },  	          
		{label: 'Country',   path: 'companies.Address.country' } ,   	  	  
		{label: 'website',   path: 'website' } ,   
		{label: 'Linkedin',   path: 'linkedin' } ,       
		{label: 'mobile',   path: 'companies.phones.mobile' } ,   	  
		{label: 'phone',   path: 'companies.phones.phone' } ,   	  
		{label: 'skype',   path: 'companies.phones.skype' } , 
		{label: 'IBAN',   path: 'bankInfo.IBAN' } ,   
		{label: 'Bank',   path: 'bankInfo.bank' } ,   
		{label: 'Branch Bank',   path: 'bankInfo.branchOfBank' } ,   
		//{label: 'subscription',   path: 'subscription' } ,   	  
	//	{label: 'subscriptionEndDate',   path: 'subscriptionEndDate' } ,   	  
		{label: 'ChamberCommerce No',   path: 'chamberCommerceNo' } ,   
		{label: 'TaxPayerNo',   path: 'taxPayerNo' } ,   
		{label: 'treatments',   path: 'treatments' } ,   
		{label: 'LicenseNo',   path: 'licenseNo' } ,   	  
		{label: 'License Valid Till',   path: 'licenseValidTill' } ,   	      
		{label: 'OrganizationA Name',   path: 'organizationAName' } ,   	          
		{label: 'OrganizationA Member No',   path: 'organizationAMemberNo' } ,   	  	  
		{label: 'OrganizationB Name',   path: 'organizationBName' } ,   	          
		{label: 'OrganizationB Member No',   path: 'organizationBMemberNo' } ,   	  	  
		{label: 'Branch',   path: 'branch' } ,   		
		{label: 'SubBranch',   path: 'companyInfo.subBranch' } ,   		 		
	];

	render() {
		console.log(this.props) ;
		const { users, onSort, sortColumn } = this.props;
		console.log("data ",users);
		return (
			<Table
				columns={this.columns}
				sortColumn={sortColumn}
				onSort={onSort}
				data={users}
			/>
		);
	}
}

const DateComponent = ({ date }) => {
	let newDate = new Date(date);
	return (
	  <>
		{newDate || date
		  ? `${newDate.getDate()}/${
			  newDate.getMonth() + 1
			}/${newDate.getFullYear()}`
		  : "-"}
	  </>
	);
  };



export default CompaniesTable;
