import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { useRef } from 'react'
import SimpleBar from "simplebar-react";
import { Button, Input, UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu, Label, Form } from "reactstrap";
import EmojiPicker from 'emoji-picker-react';

const Conversations = () => {
    const tags = ['Web', 'App', 'AI',]
    const [msg, setMsg] = useState('')
    const [isOpen, setOpen] = useState(null)
    const toggle = () => 0
    const [messages, setMessages] = useState([
        {
            id: 1,
            message: 'hello',
            time: "00:00",
            userType: "sender",
            image: null,
            isFileMessage: false,
            isImageMessage: false
        },
        {
            id: 1,
            message: 'hey, AI chatbot here',
            time: "00:00",
            userType: "AI",
            image: null,
            isFileMessage: false,
            isImageMessage: false
        }
    ])
    const handleImageChange = () => 0
    const handleFileChange = () => 0
    const onEmojiClick = () => 0
    const addMessage = (message, type) => {
        var messageObj = null;

        let d = new Date();
        var n = d.getSeconds();

        //matches the message type is text, file or image, and create object according to it
        switch (type) {
            case "textMessage":
                messageObj = {
                    id: messages.length + 1,
                    message: message,
                    time: "00:" + n,
                    userType: "sender",
                    image: null,
                    isFileMessage: false,
                    isImageMessage: false
                }
                break;

            case "fileMessage":
                messageObj = {
                    id: messages.length + 1,
                    message: 'file',
                    fileMessage: message.name,
                    size: message.size,
                    time: "00:" + n,
                    userType: "sender",
                    image: null,
                    isFileMessage: true,
                    isImageMessage: false
                }
                break;

            case "imageMessage":
                var imageMessage = [
                    { image: message },
                ]

                messageObj = {
                    id: messages.length + 1,
                    message: 'image',
                    imageMessage: imageMessage,
                    size: message.size,
                    time: "00:" + n,
                    userType: "sender",
                    image: null,
                    isImageMessage: true,
                    isFileMessage: false
                }
                break;

            default:
                break;
        }
        setMsg((prev) => prev = '')
        //add message object to chat        
        setMessages([...messages, messageObj]);

        // let copyallUsers = [...allUsers];
        // copyallUsers[props.active_user].messages = [...chatMessages, messageObj];
        // copyallUsers[props.active_user].isTyping = false;
        // props.setFullUser(copyallUsers);

        // scrolltoBottom();
    }

    // function scrolltoBottom() {
    //     if (ref.current.el) {
    //         ref.current.getScrollElement().scrollTop = ref.current.getScrollElement().scrollHeight;
    //     }
    // }

    return (
        <>
            <h4 className='p-2'>Consult</h4>
            <div className="user-chat w-100">

                <div className="d-lg-flex">

                    <div className="w-100">

                        <div className='flex gap-2 w-full shadow-md py-2 px-4 flex-col'>

                            <div className='flex w-full justify-between'>
                                <input type="text" value="" className="w-8/12 bg-[#f2f3f4] p-2 outline-blue-200 rounded-md" placeholder="Subject" />
                                <Dropdown />
                            </div>
                            <input type="text" value="" className="w-8/12 bg-[#f2f3f4] p-2 outline-blue-200 rounded-md" placeholder="Tags" />

                            <select name="category" id="category" className='bg-[#f2f3f4] p-2 w-8/12 focus:outline-blue-200 rounded-md'>
                                <option value="category" className='text-[#9ca3b5]' disabled selected>Category</option>
                                {tags.map((t) => <option>{t}</option>)}
                            </select>
                            <div className='flex items-end'>
                                <textarea className='w-8/12 bg-[#f2f3f4] p-2 outline-blue-200 rounded-md resize-none' rows={5} name='prompt' value={msg} onChange={(e) => setMsg(e.target.value)} placeholder='Your prompt here...' />
                                <div className="chat-input-links ms-md-2">
                                    <ul className="list-inline mb-0 ms-0">
                                        {/* <EmojiPicker height={500} width={400} /> */}
                                        {/* <EmojiPicker onEmojiClick={onEmojiClick}  /> */}
                                        <li className="list-inline-item">
                                            <button type="submit" onClick={()=>addMessage(msg,'textMessage')}  className="bg-blue-400 rounded-md shadow-sm px-2 text-white text-lg">
                                                <i className="ri-send-plane-2-fill"></i>
                                            </button>
                                        </li>
                                        <li className="list-inline-item">
                                            <ButtonDropdown className="emoji-dropdown" direction="up" isOpen={isOpen} toggle={toggle}>
                                                <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                                    <i className="ri-emotion-happy-line"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <EmojiPicker onEmojiClick={onEmojiClick} />
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <UncontrolledTooltip target="emoji" placement="top">
                                                Emoji
                                            </UncontrolledTooltip>
                                        </li>
                                        <li className="list-inline-item input-file">
                                            <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-attachment-line"></i>
                                                <Input onChange={(e) => handleFileChange(e)} type="file" name="fileInput" size="60" />
                                            </Label>
                                            <UncontrolledTooltip target="files" placement="top">
                                                Attached File
                                            </UncontrolledTooltip>
                                        </li>
                                        <li className="list-inline-item input-file">
                                            <Label id="images" className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-image-fill"></i>
                                                <Input onChange={(e) => handleImageChange(e)} accept="image/*" type="file" name="fileInput" size="60" />
                                            </Label>
                                            <UncontrolledTooltip target="images" placement="top">
                                                Images
                                            </UncontrolledTooltip>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <SimpleBar
                            style={{ maxHeight: "100%" }}

                            className="chat-conversation p-3 p-lg-4"
                            id="messages">
                            <ul className="list-unstyled mb-0">


                                {
                                    messages.map((msg, key) =>
                                        // chat.isToday && chat.isToday === true ? <li key={"dayTitle" + key}>
                                        //     <div className="chat-day-title">
                                        //         <span className="title">Today</span>
                                        //     </div>
                                        // </li> :

                                        <li key={key} className={msg.userType === "sender" ? "right" : ""}>
                                            <div className="conversation-list">

                                                <div className="chat-avatar">
                                                    {msg.userType === "sender" ? <div className="chat-user-img align-self-center me-3">
                                                        <div className="avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                {"S"}
                                                            </span>
                                                        </div>
                                                    </div> :

                                                        <div className="chat-user-img align-self-center me-3">
                                                            <div className="avatar-xs">
                                                                <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                    {"AI"}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    }
                                                </div>

                                                <div className="user-chat-content">
                                                    <div className="ctext-wrap">
                                                        <div className="ctext-wrap-content">
                                                            {

                                                                <p className="mb-0">
                                                                    {msg.message}
                                                                </p>
                                                            }



                                                            {
                                                                <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{msg.time}</span></p>
                                                            }
                                                        </div>

                                                    </div>
                                                    {/* {
                                                        <div className="conversation-name">{"Patricia Smith"}</div>
                                                    } */}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </SimpleBar>



                    </div>
                </div>
            </div>
        </>
    )
}

export default Conversations