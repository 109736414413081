const Gender = props => {
    let value = props.value
    if (value === null || value === "" || value === " " || value === undefined ) value = "no value"
	const gender = value.toLowerCase().trim()
	let bg , label
	if (gender === "male") {
        bg = "bg-blue-500"
        label = "Male"
    }
	if (gender === "female") { 
        bg = "bg-pink-500" 
        label = "Female"
    }
	if(gender === "other" ) {
        bg = "bg-gray-500"
        label = "Other"
    }
    if(gender === "no value" ) {
        bg = "bg-gray-500"
        label = "No Value"
    }

	return <p className={"px-4 py-2 text-center rounded-lg text-white font-bold " + bg } >{label}</p>
    
  }

export default Gender