import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/messenger/withRouter"
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ReactTags from "react-tag-autocomplete";
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import moment from "moment";
// import Select from 'react-select';
import Select from "../../common/select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import Joi from "joi";
import Form from "../../common/form.jsx";
import { apiUrl } from "../../config/config.json";
import http from "../../services/httpService";
import { saveVideomeeting, getVideomeeting } from "./../../services/videomeetings";
import PhoneInput from "react-phone-input-2";
import { getCode } from "country-list";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

class VideoMeeting extends Form {
  constructor(props) {
    super(props);

    var maxYesterday = "";
    var minYesterday = DateTime.moment().subtract(1, "day");

    this.minDateRange = (current) => {
      return current.isAfter(minYesterday);
    };
    this.maxDateRange = (current) => {
      return current.isAfter(maxYesterday);
    };
    this.minDateChange = (value) => {
      this.setState({
        maxDateDisabled: false,
      });
      maxYesterday = value;
    };

    this.state = {
      maxDateDisabled: true,
      profiles: [],
      data: {
        name: "",
        members: "",
        host: "",
        startTime: "",
        endTime: "",
        status: "",
      },
      selectedFile: null,
      errors: {},
    };

    this.statusOptions = [
      { value: "Canceled", label: "Canceled" },
      { value: "Active", label: "Active" },
      { value: "Archived", label: "Archived" },
      { value: "Delayed", label: "Delayed" },
    ];

    this.handleSlider = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onChangeImgHandler = this.onChangeImgHandler.bind(this);
  }

  async populateStatus() {
    this.statusoptions = this.statusOptions.map((option,i) => (
      <option key={i} value={option.value}>
        {option.value}
      </option>
    ));
  }

  async populateVideomeeting() {
    try {
      const userId = this.props.match.params.id;

      if (userId === "new") return;

      const { data: user } = await getVideomeeting(userId);
      //console.log(user[0]);
      //const videomeeting = Object.assign({},user[0]);
      const videomeeting = user[0];
      //console.log(videomeeting);
      if (!videomeeting.date) videomeeting.date = new Date();

      videomeeting.name = videomeeting.name;
      videomeeting.host = videomeeting.host;
      videomeeting.members = videomeeting.members;
      videomeeting.date = videomeeting.date;
      videomeeting.startTime = videomeeting.startTime;
      videomeeting.endTime = videomeeting.endTime;	  
      this.setState({ data: this.mapToViewModel(videomeeting) });
      console.log(videomeeting);
      //console.log(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/error");
    }
  }

  async componentDidMount() {
    //await this.populateProfiles();
    await this.populateStatus();
    await this.populateVideomeeting();
    // await this.populateUser();
  }

  schema = Joi.object({
    name: Joi.string(),
    host: Joi.string(),
    members: Joi.any().optional(),
	date: Joi.date().optional(),	
    startTime: Joi.any().optional(),
    endTime: Joi.any().optional(),
    status: Joi.any().optional(),			
  });

  handleDateChange = (e) => {
    const errors = { ...this.state.errors };
    const obj = { ["date"]: e };

    const data = { ...this.state.data };
    data["date"] = e;
    //const data = {...this.state.data};
    //data.date = e;
    this.setState({ data });
    console.log(this.state.data);
  };

  doSubmit = async (user) => {
    //console.log('working');
    try {
      console.log(this.state.data);
      await saveVideomeeting(this.state.data, this.state.imageSrc);
      //console.log(this.state.data);
      this.props.history.push("/videomeeting/videomeetings");
    } catch (ex) {
      //if(ex.response && ex.response.status === 404){
      if (ex.response) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
        //console.log(this.state.errors);
      }
    }
  };

  mapToViewModel(videomeeting) {
    return {
      _id: videomeeting._id,
      name: videomeeting.name,
      host: videomeeting.host,	  
      members: videomeeting.members,
      date: new Date(videomeeting.date),
      startTime: videomeeting.startTime,
      endTime: videomeeting.endTime,
      status: videomeeting.status,
    };
  }

  render() {
    const { data, errors } = this.state;
    console.log(data);
    return (
      <React.Fragment>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/messenger/videomeetings">Videomeetings</Link>
            </li>
            <li className="breadcrumb-item active">Add Videomeeting</li>
          </ol>
          <h1 className="page-header">
            Add Videomeeting<small>Videomeeting-registration-form</small>
          </h1>

          <div className="row">
            <div className="col-xl-10">
              <Panel>
                <PanelHeader>Add Videomeeting</PanelHeader>
                <PanelBody className="panel-form">
                  <form
                    className="form-horizontal form-bordered"
                    onSubmit={this.handleSubmit}
                  >
                    {this.renderInput("name","Name","text","Enter Name")}

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="date">
                        Date
                      </label>
                      <div className="col-lg-8">
                        <DatePicker
                          onChange={this.handlesubscriptionDateChange}
                          id={data.date}
                          value={data.date}
                          selected={data.date}
                          inputProps={{ placeholder: "Datepicker" }}
                          className="form-control"
                        />
                        {errors.date && (
                          <div className="alert alert-danger">
                            {errors.date}
                       </div>
                        )}
                      </div>
                      </div>
                   

                    {/* <FormControl component="fieldset">
      <FormLabel component="legend">Subscription Type</FormLabel>
  
      <RadioGroup row aria-label="position" name="subscription" defaultValue="top">
        <FormControlLabel
          value="Solo"
          control={<Radio color="primary" />}
          label="SoloPractice"
          labelPlacement="center" onChange={this.handleChange} value="Solo" checked={data.subscription === "Solo" }
        />
    
        <FormControlLabel value="Videomeeting" control={<Radio color="primary" />} label="Videomeeting" labelPlacement="center" onChange={this.handleChange} value="Videomeeting"  checked={data.subscription === "Videomeeting" }  />
      </RadioGroup>

	  {errors.subscription && (<div className="alert alert-danger">{errors.subscription}</div>)}
    </FormControl> */}

                    {this.renderInput("startTime","starttime","text","Enter startTime")}
                    {this.renderInput("endTime","endtime","text","Enter endTime")}

                    {this.renderInput("members","Initials","text","Enter Initials")}
						<div className="form-group row">
							<label className="col-lg-4 col-form-label" htmlFor="members" >
								Members
							</label>
							<div className="col-lg-8">
								<Select
									isDisabled={false}
									isMulti
									name="members"
											// styles={customStyles}
									options={[1,2,3]}
									placeholder={"Select guests..."}
									value={this.selectMembers?.filter((opt) =>	data.members.includes(opt.value)
									)}
									onChange={(e) => this.handleMultiChange("members", e)
									}
								/>
							</div>
						</div>

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="status"
                      >
                        Select Status
                      </label>
                      <div className="col-lg-8">
                        <select
                          name="status"
                          id="status"
                          value={data.status}
                          onChange={this.handleChange}
                          className="form-control"
                        >
                          <option value="">Select Status</option>
                          {this.statusoptions}
                        </select>
                      </div>
                      {errors.status && (
                        <div className="alert alert-danger">
                          {errors.status}
                        </div>
                      )}
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-8">
                        <button
                          type="submit"
                          disabled={this.validate()}
                          className="btn btn-primary width-65"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoMeeting;

