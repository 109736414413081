import React from 'react'
import pdfIcon from "../../assets/Icons/pdf.svg";

const Dropdown = () => {
    return (
        <><div className="dropdown">
            <a href="#/" className="dropdown-toggle d-flex align-items-center font-semibold" data-bs-toggle="dropdown">
                Actions
            </a>
            <div className="dropdown-menu dropdown-menu-end me-1">
                <a href="#" className="dropdown-item"><span className='ri-share-line mr-2'></span>Share</a>
                <a href="#" className="dropdown-item"><span className='ri-save-line mr-2'></span>Save</a>
                <a href="#" className="dropdown-item"><img className='w-3 h-3 inline-flex mr-2' src={pdfIcon}/>PDF</a>
            </div>
        </div></>
    )
}

export default Dropdown