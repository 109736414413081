const Menu = [
  {
    path: "dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
    children: [
      { path: "dashboard/v2", title: "Dashboard TCMFiles" },
      { path: "dashboard/v2", title: "Dashboard AI" },
    ],
  },

	{
		path: "/Users",
		icon: "fa fa-address-book",
		title: "Users",
		badge: "10",
		children: [
			{ path: "/user/userroles", title: "Admin User-Roles" },
			{ path: "/user/userroles/new", title: "Add User-Role" },			
			{ path: "/user/modulepermissions", title: "Admin Module-Permissions" },
			{ path: "/user/modulepermissions/new", title: "Add Module-Permission" },			
			{ path: "/clinic/users", title: "Users" },
			{ path: "/clinic/user/new", title: "Add User" },
			{ path: "/clinic/search_user", title: "Search in Users" },
			{ path: "/clinic/contacts", title: "Contacts" },			
			{ path: "/user/usersa", title: "New users" },		
      { path: "/user/companies", title: "Companies" },		
		],
	},

  {
    path: "/Human Resources",
    icon: "fa fa-graduation-cap",
    title: "Human Resources",
    badge: "10",
    children: [
      { path: "/user/skills", title: "Skills" },
      { path: "/planning/admincertificates", title: "Admin of Certifications" },	  
      { path: "/user/shifts", title: "Shifts" },
      { path: "/user/leaves", title: "Leaves" },
      { path: "/ero/incidents", title: "Incidents" },
      { path: "/user/attendances", title: "Attendances" },
      { path: "/user/userroles", title: "UserRoles" },
      { path: "/user/userroles/new", title: "Add New UserRole" },
      { path: "/user/rightspermissions", title: "Rights & Permissions" },
      { path: "/user/rightspermissions/new",title: "Add new Rights & Permissions", },
      { path: "/planning/adminskills", title: "Admin Skills" },
      { path: "/planning/admincertificates", title: "Admin Certificates" },	  
      { path: "/user/adminshifts", title: "Admin Shifts" },
      { path: "/user/leaves", title: "Leaves" },
      { path: "/planning/leavesreasons", title: "Admin Leave-Reasons" },	  
      { path: "/planning/adminskills", title: "Admin Skills" },	  	  
    ],
  },

  {
    path: "/Clinicsolos",
    icon: "fa fa-hospital",
    title: "ClinicSolos",
    badge: "10",
    children: [
      { path: "/clinic/clinicsolos", title: "clinicsolos" },
      { path: "/clinic/clinicsolos/new", title: "Add clinicsolo" },
      { path: "/clinic/workinghours", title: "Working Hours" },
      { path: "/clinic/search_clinicsolo", title: "Search in clinicsolos" },
    ],
  },

  {
    path: "/Clinicsolos", icon: "fa fa-briefcase", title: "Medical Files",
    children: [
      { path: "/medicalfiles", title: "TCM Medical Files" },
      { path: "/homeosession/AddHomeopathySession/", title: "Add HomeopathySession" },
      { path: "/ayurvedasession/AddAyurvedaSession", title: "Add AyurvedaSession" },
      { path: "/TCMsession/AddTCMSession", title: "Add TCMSession" },
      { path: "/TCMsession/", title: "TCMTreatment-Suggestion" },
      { path: "/ayurvedasession/medicalfiles/", title: "Ayurvedic Medical Files" },
      { path: "/homeosession/medicalfiles/", title: "Homeopathic Medical Files" },
    ],
  },
  
  {
    path: "/Databases", icon: "fa fa-briefcase", title: "Databases",
    children: [
      { path: "/databases/titles", title: "Titles" },
      { path: "/databases/organizations", title: "Organizations" },
      { path: "/databases/banks", title: "Banks" },
      { path: "/databases/insurances", title: "Insurances" },
      { path: "/databases/reservedusernames", title: "Reserved Usernames" },
      { path: "/databases/termofuses/", title: "Term of USe" },
      { path: "/databases/privacypolicies/", title: "Privacy Policy" },
      { path: "/databases/meta", title: "Meta information" },	  
    ],
  },

  {
    path: "/ERO",
    icon: "ion-md-help-buoy",
    title: "EROs",
    children: [
      { path: "/ero/areas", title: "Areas" },	
      { path: "/ero/eros", title: "EROs" },
      { path: "/ero/eros/new", title: "Add ERO" },
      { path: "/ero/searchEROs", title: "Search in EROs" },
      { path: "/ero/incidents", title: "Incidents" },	  
      { path: "/ero/incidents/new", title: "Add Incident" },
      { path: "/ero/eevents", title: "Emergency Events" },		  
    ],
  },
  {
    path: "/messenger",
    icon: "fa fa-comments",
    title: "Messenger",
    children: [
      { path: "/messenger/chats", title: "Chats" },	
      { path: "/messenger/contacts", title: "Contacts" },		  
      { path: "/messenger/conversations", title: "Conversations" },      	  
      { path: "/messenger/videocall", title: "Videocall" },      	  	  
      { path: "/messenger/videomeetings", title: "VideoMeetings" },   
      { path: "/messenger/joinmeeting", title: "JoinMeeting" },        	  	  	  
    ],
  },
  {
    path: "/Clinicsolos",
    icon: "fa fa-cloud",
    title: "Yourdrive",
    badge: "10",
    children: [
      { path: "/clinic/yourdrive", title: "Your Drive" },
      { path: "/clinic/search_yourdrive", title: "Search in your drive" },
      { path: "itransportindex.com:4321/dbApp", title: "Mongoose DB" },
    ],
  },

{
    path: "/metai",
    icon: "fa fa-cloud",
    title: "AIML",
    badge: "10",
    children: [
      { path: "/metai/yourdrive", title: "Admin AI-ML" },
      { path: "/metai/search_yourdrive", title: "Search in your drive" },
      { path: "/metai/LabelStudio", title: "Label Studio" },	  
    ],
  },

  {
    path: "/ChatGPT",
    icon: "fas fa-ticket-alt text-indigo",
    title: "ChatGPT",
    
    children: [
      { path: "/chatGPT/consult", title: "Start Consult" },	    
      { path: "/chatGPT/setting", title: "Setting" },
      { path: "/chatGPT/imexports", title: "Import Export Manager" },      
      { path: "/chatGPT/consults", title: "Consults" },      
      { path: "/chatGPT/companies", title: "Companies" },      
      { path: "/chatGPT/APIs", title: "Manage APIs" },      	  
    ],
  },

{
    path: "/ero",
    icon: "fa fa-cloud",
    title: "Maps",
    badge: "10",
    children: [
      { path: "/ero/upload", title: "Upload new Map" },
      { path: "/ero/areas", title: "Areas" },
    ],
  },

  { path: '/widgets', icon: 'fab fa-simplybuilt', title: 'Widgets', label: 'NEW' },
  { path: '/ui', icon: 'fa fa-gem', title: 'UI Elements', label: 'NEW',
    children: [
      { path: '/ui/general', title: 'General', highlight: true },
      { path: '/ui/typography', title: 'Typograhy' },
      { path: '/ui/tabs-accordion', title: 'Tabs & Accordion' },
      { path: '/ui/modal-notification', title: 'Modal & Notification' },
      { path: '/ui/widget-boxes', title: 'Widget Boxes' },
      { path: '/ui/media-object', title: 'Media Object' },
      { path: '/ui/buttons', title: 'Buttons', highlight: true },
      { path: '/ui/icon-fontawesome', title: 'FontAwesome' },
      { path: '/ui/icon-bootstrap', title: 'Bootstrap Icons', highlight: true },
      { path: '/ui/icon-simple-line-icons', title: 'Simple Line Icons' },
      { path: '/ui/language-bar-icon', title: 'Language Bar & Icon' },
      { path: '/ui/social-buttons', title: 'Social Buttons' }
    ]
  },
  { path: '/bootstrap-5', img: '/assets/img/logo/logo-bs5.png', title: 'Bootstrap 5', label: 'NEW' },
  { path: '/form', icon: 'fa fa-list-ol', title: 'Form Stuff', label: 'NEW',
    children: [
      { path: '/form/elements', title: 'Form Elements', highlight: true },
      { path: '/form/plugins', title: 'Form Plugins', highlight: true },
      { path: '/form/wizards', title: 'Form Wizards', highlight: true }
    ]
  },
  { path: '/table', icon: 'fa fa-table', title: 'Tables',
    children: [
      { path: '/table/elements', title: 'Table Elements' },
      { path: '/table/plugins', title: 'Table Plugins' }
    ]
  },
  { path: '/pos', icon: 'fa fa-cash-register', title: 'POS System', label: 'NEW',
    children: [
      { path: '/pos/customer-order', title: 'Customer Order' },
      { path: '/pos/kitchen-order', title: 'Kitchen Order' },
      { path: '/pos/counter-checkout', title: 'Counter Checkout' },
      { path: '/pos/table-booking', title: 'Table Booking' },
      { path: '/pos/menu-stock', title: 'Menu Stock' },
    ]
  },
  { path: '/chart', icon: 'fa fa-chart-pie', title: 'Chart', label: 'NEW',
    children: [
      { path: '/chart/js', title: 'Chart JS' },
      { path: '/chart/apex', title: 'Apex Chart', highlight: true }
    ]
  },

  { path: '/map', icon: 'fa fa-map', title: 'Map' },
  { path: '/gallery', icon: 'fa fa-image', title: 'Gallery' },
  { path: '/page-option', icon: 'fa fa-cogs', title: 'Page Options', label: 'NEW',
    children: [
      { path: '/page-option/blank', title: 'Blank Page' },
      { path: '/page-option/with-footer', title: 'Page with Footer' },
      { path: '/page-option/with-fixed-footer', title: 'Page with Fixed Footer', highlight: true },
      { path: '/page-option/without-sidebar', title: 'Page without Sidebar' },
      { path: '/page-option/with-right-sidebar', title: 'Page with Right Sidebar' },
      { path: '/page-option/with-minified-sidebar', title: 'Page with Minified Sidebar' },
      { path: '/page-option/with-two-sidebar', title: 'Page with Two Sidebar' },
      { path: '/page-option/full-height', title: 'Full Height Content' },
      { path: '/page-option/with-wide-sidebar', title: 'Page with Wide Sidebar' },
      { path: '/page-option/with-light-sidebar', title: 'Page with Light Sidebar' },
      { path: '/page-option/with-mega-menu', title: 'Page with Mega Menu' },
      { path: '/page-option/with-top-menu', title: 'Page with Top Menu' },
      { path: '/page-option/with-boxed-layout', title: 'Page with Boxed Layout' },
      { path: '/page-option/with-mixed-menu', title: 'Page with Mixed Menu' },
      { path: '/page-option/boxed-layout-with-mixed-menu', title: 'Boxed Layout with Mixed Menu' },
      { path: '/page-option/with-transparent-sidebar', title: 'Page with Transparent Sidebar' },
      { path: '/page-option/with-search-sidebar', title: 'Page with Search Sidebar', highlight: true }
    ]
  },
  { path: '/extra', icon: 'fa fa-gift', title: 'Extra', label: 'NEW',
    children: [
      { path: '/extra/timeline', title: 'Timeline' },
      { path: '/extra/coming-soon', title: 'Coming Soon Page' },
      { path: '/extra/search', title: 'Search Results' },
      { path: '/extra/invoice', title: 'Invoice' },
      { path: '/extra/error', title: '404 Error Page' },
      { path: '/extra/profile', title: 'Profile Page' },
      { path: '/extra/scrum-board', title: 'Scrum Board', highlight: true },
      { path: '/extra/cookie-acceptance-banner', title: 'Cookie Acceptance Banner', highlight: true },
      { path: '/extra/orders', title: 'Orders', highlight: true },
      { path: '/extra/order-details', title: 'Order Details', highlight: true },
      { path: '/extra/products', title: 'Products', highlight: true },
      { path: '/extra/product-details', title: 'Product Details', highlight: true }
    ]
  },
  // { path: '/user', icon: 'fa fa-key', title: 'Login & Register',
  //   children: [
  //     { path: '/user/login-v1', title: 'Login' },
  //     { path: '/user/login-v2', title: 'Login v2' },
  //     { path: '/user/login-v3', title: 'Login v3' },
  //     { path: '/user/register-v3', title: 'Register v3' }
  //   ]
  // },
  { path: '/helper', icon: 'fa fa-medkit', title: 'Helper',
    children: [
      { path: '/helper/css', title: 'Predefined CSS Classes' }
    ]
  },
  { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    children: [
      { path: '/menu/menu-1-1', title: 'Menu 1.1',
        children: [
          { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
            children: [
              { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
              { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
            ]
          },
          { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
          { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
        ]
      },
      { path: '/menu/menu-1-2', title: 'Menu 1.2' },
      { path: '/menu/menu-1-3', title: 'Menu 1.3' },
    ]
  },
]

export default Menu;
