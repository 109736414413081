import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { Link, withRouter } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getCompanies, deleteCompany } from "./../../services/companies";
import "bootstrap/dist/css/bootstrap.min.css";
//import FloatSubMenu from './../../components/float-sub-menu/float-sub-menu';
import Pagination from "../../common/pagination";
import { paginate } from "../../utils/paginate";
import CompaniesTable from '../../components/companiesTable'
import SearchBox from "./../../common/searchBox";
import _ from "lodash";
import http from "./../../services/httpService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons imports
import newIcon from "../../assets/Icons/new.svg";
import editIcon from "../../assets/Icons/edit.svg";
import trashIcon from "../../assets/Icons/trash.svg";
import csvIcon from "../../assets/Icons/csv.svg";
import xlsIcon from "../../assets/Icons/xls.svg";
import pdfIcon from "../../assets/Icons/pdf.svg";
import Icon from "./../../common/icon";

const apiUrl = process.env.REACT_APP_API_URL;

class CompanyTableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pageSize: 10,
      currentPage: 1,
      sortColumn: { path: "title", order: "asc" },
      searchQuery: "",
      checkedFields: [],
      errors: {},
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleMassDelete = this.handleMassDelete.bind(this);
	  this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  async componentDidMount() {
    console.log("kjnsdkjvbkds");
    const { data } = await getCompanies();
    console.log(data,"ALL Companies");

    this.setState({ users: data });
  }

  handleDelete = async (user) => {
    ///delete
    const originalUsers = this.state.users;
    const users = this.state.users.filter((User) => User._id !== user._id);
    this.setState({ users });
    try {

      //await http.delete(apiUrl + "/companies/" + user._id);
      await deleteCompany(user._id)

    } catch (ex) {
      //ex.request
      //ex.response

      if (ex.response && ex.response === 404) {
        alert("already deleted");
      }

      this.setState({ users: originalUsers });
    }
    ////
  };

	handleMassDelete = (CheckedFields) => {
		const originalUsers = this.state.users;
		CheckedFields.map(async (user) => {

			const updatedUsers = this.state.users.filter((User) => User._id !== user);
			 console.log("user: ", user);
			this.setState({ users:updatedUsers });
      // console.log(user);
			try {
        await deleteCompany(user)
				//await http.delete(apiUrl + "/patients/" + user);

			} catch (ex) {
				if (ex.response && ex.response === 404) {
					alert("already deleted");
				}

				this.setState({ users: originalUsers });
			}
			console.log("Users: ", this.state.users);
		});
	};


  //check box change
handleCheckboxChange = ({ target: { checked, value } }) => {

  console.log("Inside checkbox",value,checked);
	if (checked) {
	// const checkedFields = [...this.state.checkedFields,value];
  // console.log(checkedFields);
	this.setState({checkedFields:[...this.state.checkedFields,value]});
  console.log("checked users: ", this.state.checkedFields);

	} else {
	const checkedFields = [...this.state.checkedFields];
	this.setState({ checkedFields:checkedFields.filter((e) => e !== value)});
	}

};




  //sorting columns
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };
  handlePageChange = (page) => {
    console.log(page);
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    console.log(query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  getDataPgnation = () => {
    const {
      pageSize,
      currentPage,
      users: Users,
      sortColumn,
      searchQuery,
    } = this.state;
    //
    //filter maybe next time
    let filtered = Users;
    if (searchQuery) {
      console.log(searchQuery);
      filtered = Users.filter(
        (el) =>
          el.email.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          el.username.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    //
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const users = paginate(sorted, currentPage, pageSize);
    return { data: users };
  };

  render() {
    const { length: count } = this.state.users;
    const { pageSize, currentPage, sortColumn, searchQuery,checkedFields } = this.state;
    // if(count === 0)  return "<p>No data available</p>";

    console.log("checked users2: ", this.state.checkedFields);
    const { data: users } = this.getDataPgnation();
    console.log("humara",users);


    return (
      <div>
        <h1 className="page-header">Companies </h1>
        <Panel>
          <PanelHeader>Companies Management</PanelHeader>

          <React.Fragment>
            <ToastContainer />
            <div className="toolbar" style={toolbarStyles}>
       
              <Icon
                to="/user/companies/new"
                title="add company"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={newIcon}
              />

              <Icon
                to={
                  checkedFields
                    ? `/user/companies/${checkedFields[0]}`
                    : "/user/companies/"
                }
                title="edit company"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={editIcon}
              />


               <Icon
                handleClick={() => this.handleMassDelete(checkedFields)}
                title="delete companies"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={trashIcon}
              />
        <Icon
                to="/user/companies/"
                title="Excel"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={xlsIcon}
              />
              <Icon
                to="/user/companies/"
                title="CSV"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={csvIcon}
              />
              <Icon
						//onClick={toPdf}
                      to="/user/companies/"
                      title="PDF"
                      btnStyle={btnStyles}
                      iconStyle={iconStyles}
                      icon={pdfIcon}
                    />
            </div>
            <div className="table-responsive">
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <p
                className="page-header float-xl-left"
                style={
                  ({ marginBottom: 5 }, { marginLeft: 20 }, { marginTop: 5 })
                }
              >
                {count} entries
              </p>

              <CompaniesTable
                users={users}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                handleCheckboxChange={this.handleCheckboxChange}
              />
            </div>
          </React.Fragment>

          <hr className="m-0" />
          <PanelBody>
            <div className="d-flex align-items-center justify-content-center">
              <Pagination
                itemsCount={count}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  }
}
const toolbarStyles = {
  background: "#c8e9f3",
  padding: "10px",
};

const btnStyles = { background: "#348fe2", margin: "0rem" };

const iconStyles = {
  width: "25px",
  height: "25px",
  marginRight: "0rem",
};
export default CompanyTableData;
