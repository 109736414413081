import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  me:{},
  peerId:null,
  isMeetingStart:false,
  isMute:false,
  isVideoTrack:true,
  meetId:''
};

export const videoMeetingSlice = createSlice({
  name: "videoMeeting",
  initialState,
  reducers: {
    updateMe: (state, action) => {
      return { ...state, me: action.payload };
    },
    updatePeerId: (state, action) => {
      return { ...state, peerId: action.payload };
    },
    updateMeetId:(state, action) => {
      return { ...state, meetId: action.payload };
    }
  },
});
export const { updateMe, updatePeerId,updateMeetId } = videoMeetingSlice.actions;

export default videoMeetingSlice.reducer;
