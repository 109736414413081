import React from "react";
import "../../assets/scss/themes.scss";
//Import Components
import UserChat from "./Chats/UserChat";
import Chats from "./Chats/Chats";
import { configureStore } from "../../store/messenger/store";
import { Provider } from "react-redux";
import Layout from "./Chats/Layout";
import { useSelector } from "react-redux";
import {
  updateActiveUser,
  updateMessages,
  deleteMessage,
  updateContacts,
  updateConversations,
  deleteContacts,
  updateMoreMessages,
  updateForwardMessage
} from "../../store/messengerSlice";
import { useDispatch } from "react-redux";
import { useSocket } from "../../store/context/SocketProvider";
import { getMessages,getGroupMessages } from "../../services/conversations";
function ChatBoard() {
  const messenger = useSelector((state) => state.messenger);
  const { fetchUpdatedMessages } = useSocket();
  const dispatch = useDispatch();

  const setActiveUser = (key) => {
    dispatch(updateActiveUser(key));
  };

  const setMessagestoState = (obj) => {
    dispatch(
      updateMessages({
        key: messenger.activeUser,
        message: obj,
      })
    );
  };

  const fetchMessages = () => {
    fetchUpdatedMessages();
  };

  const deleteMessageFromState = (id) => {
    dispatch(
      deleteMessage({
        id,
      })
    );
  };

  const handleUpdateContacts = (cntcts)=>{
    dispatch(updateContacts(
      cntcts
    ))
  }

  const handleUpdateConversations = (data)=>{
    dispatch(updateConversations([data]))
  }

  const handleDelConversation = (id)=>{
      dispatch(deleteContacts(id))
  }

  const handleForwardMessage = (msg)=>{
    dispatch(updateForwardMessage(msg));
  }

  const handleFetchMore = async (convId,isGroup,page)=>{
      if(isGroup){
        const res = await getGroupMessages(convId,page)
        updateMoreMessages(res.data.reverse())
      }else{
        const res = await getMessages(convId,page)
        updateMoreMessages(res.data.reverse())
      }
  }



  console.log("chat board", messenger);
  return (
    <Provider store={configureStore()}>
      <React.Fragment>
        <Layout>
          {/* chat-list  */}
          <Chats
            contacts={messenger.contacts}
            conversations={messenger.conversations}
            active_user={messenger.activeUser}
            setActiveUser={setActiveUser}
            fetchUpdatedMessages={fetchMessages}
            onlineContacts={messenger.onlineUsers}
            
          />

          {/* user-chat */}
          <UserChat
            contacts={messenger.contacts}
            currentUser={messenger.currentUser}
            active_user={messenger.activeUser}
            conversations={messenger.conversations}
            setMessagestoState={setMessagestoState}
            deleteMessageFromState={deleteMessageFromState}
            updateContacts={handleUpdateContacts}
            conversationsIds={messenger.conversationsIds}
            handleUpdateConversations={handleUpdateConversations}
            handleDelConversation={handleDelConversation}
            handleFetchMore={handleFetchMore}
            handleForwardMessage={handleForwardMessage}
          />
        </Layout>
      </React.Fragment>
    </Provider>
  );
}

export default ChatBoard;
