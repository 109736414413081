import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Peer from "peerjs";
import { useSocket } from "./SocketProvider";
import { getUser } from "../../services/users";
import * as config from "../../utils/config";
import CallModal from "../../pages/messenger/Chats/CallModal";
import { getProfile } from "../../services/authservice";
import { useStopwatch } from 'react-timer-hook';
import { updateMessages } from "../messengerSlice";
import { useDispatch, useSelector } from "react-redux";
import user from "../../pages/clinic/user";
import { getConfig } from "../../utils/iceServer";
export const CallContext = createContext();

export function useCallContext() {
  return useContext(CallContext);
}

function CallProvider({ children }) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 18000)
  const {
    seconds,
    minutes,
    start,
    pause,
    reset,
  } = useStopwatch({
    autoStart: false
  });
  const { socket, me } = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messenger = useSelector(state => state.messenger);

  const [isStart, setIsStart] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [fromSocket, setFromSocket] = useState(null);
  const [fromPeer, setFromPeer] = useState(null);
  const [caller, setCaller] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [convId, setConvId] = useState(null);
  const [isVideoTrack, setIsVideotrack] = useState(true);
  const [remoteU, setRemoteU] = useState(null);
  const [isCallModal, toggleCallModal] = useState(false);

  const isVideo = useRef(false);
  // isVideo.current = false;
  const remoteUser = useRef();
  const activeCall = useRef(null);
  const peerServer = useRef(null);
  const peerId = useRef(null);
  const conIdRef = useRef();
  conIdRef.current = convId;
  const meRef = useRef();
  meRef.current = me;
  const remoteURef = useRef();
  remoteURef.current = remoteU;
  const minRef = useRef()
  minRef.current = minutes;
  const secRef = useRef();
  secRef.current = seconds;

  const handleCallUser = useCallback(
    async (data) => {
      console.log("got a call");

      const res = await new Promise(async (resolve) => {
        // const ice_server = await getConfig();
        const peer = new Peer({
          secure: true,
          host: config.PEER_SERVER_HOST,
          path: config.PEER_SERVER_PATH,
          port: config.PEER_SERVER_PORT,
          // config:ice_server
        });

        const newStream = await navigator.mediaDevices.getUserMedia({
          video: isVideo.current,
          audio: true,
        });
        console.log("locastream is", newStream);
        setLocalStream(newStream);

        peer.on("open", (pid) => {
          peerId.current = pid;
          peerServer.current = peer;
          
          peer.on("call", (call) => {
            console.log("i am in peer on call and is video call", isVideo);

            call.answer(newStream);
            setIsStart(true);

            activeCall.current = call;

            call.on(
              "stream",
              (stream) => {
                console.log("on stream is", stream);
                setRemoteStream(stream);
              },
              (err) => {
                console.error("Failed to get call stream", err);
              }
            );
            call.on("close", () => {
              call.close();
            });
          });

          resolve(true);
        });


      });

      const { fromPeer, fromSocket, isVideoCall, fromUser } = data;
      console.log("i get call", data);
      setFromPeer(fromPeer);
      setFromSocket(fromSocket);
      isVideo.current = isVideoCall;
      const u = await getUser(fromUser._id);
      console.log("call user", u.data);
      remoteUser.current = u.data;
      // navigation.navigate("IncomingScreen", u.data);
      toggleCallModal(true)
    },
    [socket]
  );

  const handleEndCall = () => {
    console.log("end call ref", conIdRef, meRef, remoteUser, isVideo, minRef, secRef)
    pause();
    setCaller((state) => {
      if (state) {
        const messageObj = {
          sender: meRef.current?._id,
          receiver: remoteURef.current?._id,
          message: "",
          media: [],
          conversationId: conIdRef.current,
          updatedAt: new Date().toLocaleString(),
          createdAt: new Date().toLocaleString(),
          callInfo: {
            isCall: true,
            callType: isVideo.current ? "Video" : "Audio",
            duration: minRef.current + ":" + secRef.current,
            time: new Date().toLocaleString(),
          },
        };
        console.log("end with", messageObj, updateMessages);
        dispatch(
          updateMessages({
            key: messenger.activeUser,
            message: messageObj,
          })
        );
        socket?.emit("sendMessage", messageObj);
      }
    });

    toast.info(
      `${remoteUser.current.contactName.first
      } closed the call`,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    setIsStart(false);
    activeCall?.current?.close();
    peerServer?.current?.destroy();
    localStream?.getTracks().forEach(function (track) {
      track.stop();
      track.release && track.release();
    });
    activeCall.current = null;
    setIsMuted(false);
    setIsVideotrack(true);
    isVideo.current = null;
    setFromPeer(null);
    setFromSocket(null);
    setLocalStream(null);
    setRemoteStream(null);
    remoteUser.current = null;
    navigate("messenger/chats", { replace: true });

  }

  const callUser = async (userToCall, video, fromUser, convId) => {
    setCaller(true);
    convId && setConvId(convId);
    const res = await new Promise(async (resolve) => {
      // const ice_server = await getConfig();
      const peer = new Peer({
        secure: true,
        host: config.PEER_SERVER_HOST,
        path: config.PEER_SERVER_PATH,
        port: config.PEER_SERVER_PORT,
        // config:ice_server
      });

      peerServer.current = peer;
      peer.on("open", (pid) => {
        console.log("connect peer", pid);
        peerId.current = pid;
        
        socket?.on("callAccepted", async ({ fromSocket, fromPeer }) => {
          console.log("Accepted", isVideo.current, remoteUser.current);
  
          const newStream = await navigator.mediaDevices.getUserMedia({
            video: isVideo.current,
            audio: true,
          });
          setLocalStream(newStream);
          setFromPeer(fromPeer);
          setFromSocket(fromSocket);
  
          console.log("in peer server body and localStream is", newStream);
  
          let call = peer.call(fromPeer, newStream);
          activeCall.current = call;
          setIsStart(true);
          call?.on(
            "stream",
            (stream) => {
              console.log("on stream is after accepted", stream);
              setRemoteStream(stream);
            },
            (err) => {
              console.error("Failed to get call stream", err);
            }
          );
          call?.on("close", () => {
            call.close();
          });
  
          const t = new Date();
          t.setSeconds(t.getSeconds() + 18000);
  
          // reset();
          // start();
  
          // if (isVideo.current) {
            navigate("/messenger/videocall", { replace: true });
          // } else {
            // navigate("/messenger/audiocall", { replace: true , state:{ min:minutes , sec:seconds}});
          // }
        });

        resolve(true);
      });

     
    });

    if (res) {

     

      if (!socket.connected) {
        alert("socket is not connected.");
        socket.connect();
        return;
      }
      if (!peerId.current) {
        alert("Peer server is not connected try again later.");
        return;
      }

      console.log("in call user", userToCall._id);
      isVideo.current = video;
      remoteUser.current = userToCall;
      setRemoteU(userToCall)
      if (userToCall.isGroupChat) {
        for (const member of userToCall.members) {
          socket.emit("callUser", {
            isVideoCall: video,
            userToCall: member,
            fromPeer: peerId.current,
            fromSocket: socket.id,
            fromUser: fromUser,
          });
        }
      } else {
        socket.emit("callUser", {
          isVideoCall: video,
          userToCall: userToCall._id,
          fromPeer: peerId.current,
          fromSocket: socket.id,
          fromUser: fromUser,
        });
      }

      console.log("ending call user");
    }
  };

  const socketFunctions = async () => {
    socket?.on("callUser", handleCallUser);
    socket?.on("end call", handleEndCall);
  };

  const declineCall = () => {
    console.log("decline call by user");
    activeCall?.current?.close();
    // socket.emit("end call", fromSocket);
    peerServer?.current?.destroy();
    peerServer.current = null;
    activeCall.current = null;
    setFromPeer(null);
    setFromSocket(null);
    isVideo.current = null;
    remoteUser.current = null;
    toggleCallModal(false);
    setIsStart(false);
  };

  const closeCall = () => {
    pause();
    if (caller) {
      const messageObj = {
        sender: me?._id,
        receiver: remoteUser.current?._id,
        message: "",
        media: [],
        conversationId: convId,
        updatedAt: new Date().toLocaleString(),
        createdAt: new Date().toLocaleString(),
        callInfo: {
          isCall: true,
          callType: isVideo.current ? "Video" : "Audio",
          duration: minutes + ":" + seconds,
          time: new Date().toLocaleString(),
        },
      };

      console.log("end with", messageObj, updateMessages);
      // updateMessages(messageObj);
      dispatch(
        updateMessages({
          key: messenger.activeUser,
          message: messageObj,
        })
      );
      socket?.emit("sendMessage", messageObj);

    }

    socket?.emit("end call", fromSocket);
    console.log("active call", activeCall.current, "closed");
    console.log("active call", activeCall, "closed");
    localStream?.getTracks().forEach(function (track) {
      track.stop();
    });
    setIsStart(false);
    activeCall?.current?.close();
    peerServer?.current?.destroy();
    activeCall.current = null;
    peerServer.current = null;
    isVideo.current = null;
    remoteUser.current = null;
    setFromPeer(null);
    setLocalStream(null);
    setRemoteStream(null);
    setIsMuted(false);
    setIsVideotrack(true);
    navigate("messenger/chats", { replace: true });
  };

  const stopVideo = () => {
    if (localStream) {
      const videoTracks = localStream.getVideoTracks();
      videoTracks.forEach((track) => {
        track.enabled = false;
        setIsVideotrack(false);
      });
    }
  };
  const startVideo = () => {
    if (localStream) {
      const videoTracks = localStream.getVideoTracks();
      videoTracks.forEach((track) => {
        track.enabled = true;
        setIsVideotrack(true);
      });
    }
  };

  const toggleMute = () => {
    if (localStream) {
      const audioTracks = localStream.getAudioTracks();
      audioTracks.forEach((track) => {
        track.enabled = !isMuted;
        setIsMuted(!isMuted);
      });
    }
  };

  const answerCall = () => {

    socket.emit("answerCall", {
      myPeer: peerId.current,
      to: fromSocket,
    });
    toggleCallModal(false);
    // reset();
    // start();
    // if (isVideo.current) {
      navigate("/messenger/videocall", { replace: true });
    // } else {
      // navigate("/messenger/audiocall", { replace: true , state:{ min:minutes , sec:seconds} });
    // }


  };

  useEffect(() => {
    const getToken = async () => {
      const res = await getProfile();
      return res;
    }
    getToken().then((token) => {
      if (token) {
        socketFunctions();
      }
    })



  }, [socket]);

  return (
    <CallContext.Provider
      value={{
        localStream,
        remoteStream,
        remoteUser,
        isMuted,
        toggleMute,
        closeCall,
        callUser,
        declineCall,
        answerCall,
        isVideo: isVideo.current,
        isVideoTrack,
        startVideo,
        stopVideo,
      }}
    >


      {
        isCallModal
        &&
        <CallModal
          isOpen={isCallModal}
          toggleCallModal={toggleCallModal}
          isVideo={isVideo.current}
          user={remoteUser?.current}
          inComming={true}
          declineCall={declineCall}
          answerCall={answerCall}

        />
      }
      {children}

    </CallContext.Provider>
  );
}

export default CallProvider;