//import axios from 'axios';
import { LogarithmicScale } from 'chart.js';
import http from './httpService'; 
// import apiUrl from './../config/config.json';
const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl+'/companies';

  function userUrl(id) {
    return `${apiEndpoint}/${id}`;
  }
  
  export function getCompanies() {
    return http.get(apiEndpoint);
  }
  
  export function getCompanyByUser(id) {
    return http.get(`${apiEndpoint}/user/${id}`);
  }

  export function getCompany(userId) {
    return http.get(userUrl(userId));
  }
  
  export async function  saveCompany(user,imageSrc) {
    
   //update
   //console.log('imageSrc', imageSrc);
   const formData = new FormData(); 
   const body = { ...user };
   if (user._id) {
     //clone user and delete _id
     
     delete body._id;
     //const formData={...body,"imageSrc":imageSrc.name}
     //console.log("formData",formData);
     //console.log("Url",userUrl(user._id));

     //const company= await http.put(userUrl(user._id), formData);
     //console.log("updated company",company);
      delete body.imageSrc;
     for ( let key in body ) {
        formData.append(key, body[key]);
        }
       formData.append('imageSrc', imageSrc);
     return http.put(userUrl(user._id), formData);
   }
  
   //console.log("Body=>",body);
      // for ( let key in body ) {
      //   //console.log("Here we go",key,"=>=>",body[key]);
      //   formData.append(key, body[key]);
      //   }
      //  formData.append('imageSrc', imageSrc);
   //add a new user
 for ( let key in body ) {
        formData.append(key, body[key]);
        }
       formData.append('imageSrc', imageSrc);
   //console.log("apiEndpoint: ",apiEndpoint);
   return http.post(apiEndpoint, formData);
 }
  
  //delete user
  export function deleteCompany(userId) {
    console.log("isko delete karna hai",userId);
    return http.delete(userUrl(userId));
  }  

  
