import React from 'react';
import {useState, useEffect} from 'react';
import http from '../../services/httpService';
import Select from 'react-select';
const apiUrl = process.env.REACT_APP_API_URL;

const CountryDropDown = ({changeHandler,selectedValue}) => {
  
    const URL = `${apiUrl}/countriesd`;
    
    const [countries, setCountries] = useState([]);
    const [countryValue, setCountryValue] = useState(selectedValue);

    useEffect(()=> {
      async function fetchData()
      {
        const {data} = await http.get(URL);
        let arr = new Array(data.length);
        for (let index = 0; index < data.length; index++) {
            const country = data[index];
            arr[index] = {
                value: country.name,
                label: country.name,
                flag: country.flag,
            }
            console.log(arr[index]);
          }
        setCountries(arr);
        
      }
      setCountryValue(selectedValue)
      fetchData();
    },[]);
    
      const formatOptionLabel = ({ value, label, flag }) => {
        const selectedCountry = countries.filter( country => country.label === label)
        if (selectedCountry['0']) flag = selectedCountry['0'].flag
        return <div style={{ 
            display: "flex",
            alignItems: 'center'
            }}>
          <img src={flag} style={{width:'20px', height:'12px', marginRight: "20px"}} />
          <div>{label}</div>
        </div>
      }

     

      const customStyles = () => ({
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
      })

    return (
        <Select
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition={'fixed'} 
            value={
              {
                
                label:countryValue
              }
            }
            onChange={(item)=>{
              
              console.log("mera item",countryValue);
              //setCountryValue(item); 
              setCountryValue(item.value); 
              changeHandler(item.label)
            }}
            formatOptionLabel={formatOptionLabel}
            options={countries? countries: {value: 'loading', label: 'loading'}}
        />
    )
}

export default CountryDropDown;
