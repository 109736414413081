import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Mic from "../../assets/Icons/mic.svg";
import MicMute from "../../assets/Icons/mic-mute.svg";
import Camera from "../../assets/Icons/camera.svg";
import CameraOff from "../../assets/Icons/camera-off.svg";
import Phone from "../../assets/Icons/phone.svg";
import { useSocket } from "../../store/context/SocketProvider";
import { Tabs } from "antd";
import { ToastContainer } from "react-toastify";
import Video from "./Video";
import VideoChat from "./VideoChat";

export default function VideoMeetingSession() {
  const {
    endMeeting,
    localStream,
    streams,
    muteStream,
    unmuteStream,
    turnOffVideo,
    turnOnVideo,
    isMuted,
    isVideoTrack,
    isGroupCall,
    isVideoCall
  } = useSocket();

  const videoMeeting = useSelector((state) => state.videoMeeting);
  const { me, meetId } = videoMeeting;

  let activeStream = useRef();
  const [activeUser, setActiveUser] = useState({});

  const maximizeUserVideo = (stream, user) => {
    console.log("video maximised")
    activeStream.current = stream.current;
    setActiveUser(user);
  };

  useEffect(() => {
    activeStream.current = localStream.current;
    setActiveUser(me);
  }, [localStream, me, turnOnVideo]);

  const Participants = () => {
    return (
      <div id="users-list">
        <div className="userinfo participant" key={me._id}>
          <div className="flex flex-start cursor-pointer" onClick={() => maximizeUserVideo(localStream, me)}>
            {me?.imageSrc && me?.imageSrc != "" ? (
              <div className="me-3 ms-0">
                <img
                  src={me?.imageSrc}
                  className="rounded-circle avatar-xs"
                  alt="chatvia"
                />
              </div>
            ) : (
              <div className="chat-user-img align-self-center me-3">
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                    {me?.contactName?.first?.charAt(0)}
                  </span>
                </div>
              </div>
            )}
            <span
              className="font-semibold cursor-pointer"
              style={{ marginTop: "5px" }}
            >
              You
            </span>
          </div>
          <div className="participants-controls">
            {isMuted ? (
              <img
                src={MicMute}
                alt="mic-mute"
                onClick={unmuteStream}
                className="cursor-pointer"
              />
            ) : (
              <img
                src={Mic}
                alt="mic"
                onClick={muteStream}
                className="cursor-pointer"
              />
            )}
            {!isGroupCall && (!isVideoTrack ? (
              <img
                src={CameraOff}
                alt="camera-off"
                onClick={turnOnVideo}
                className="cursor-pointer"
              />
            ) : (
              <img
                src={Camera}
                alt="camera"
                onClick={turnOffVideo}
                className="cursor-pointer"
              />
            ))}
            {isGroupCall && isVideoCall && (!isVideoTrack ? (
              <img
                src={CameraOff}
                alt="camera-off"
                onClick={turnOnVideo}
                className="cursor-pointer"
              />
            ) : (
              <img
                src={Camera}
                alt="camera"
                onClick={turnOffVideo}
                className="cursor-pointer"
              />
            ))}
          </div>
        </div>
        {streams.map((obj) => {
          const { user } = obj;
          return (
            <div
              className="userinfo participant cursor-pointer"
              key={user._id}
              onClick={() => maximizeUserVideo(obj.stream, user)}
            >
              <div className="flex flex-start">
                {user?.imageSrc && user?.imageSrc != "" ? (
                  <div className="me-3 ms-0">
                    <img
                      src={user?.imageSrc}
                      className="rounded-circle avatar-xs"
                      alt="chatvia"
                    />
                  </div>
                ) : (
                  <div className="chat-user-img align-self-center me-3">
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                        {user?.contactName?.first?.charAt(0)}
                      </span>
                    </div>
                  </div>
                )}
                <span
                  className="font-semibold cursor-pointer"
                  style={{ marginTop: "5px" }}
                >
                  {user.contactName.first + " " + user.contactName.last}
                </span>
              </div>
              {/*<div className="participants-controls">
                {mic ? (
                  <img
                    src={MicMute}
                    alt="mic-mute"
                    className="cursor-pointer"
                  />
                ) : (
                  <img src={Mic} alt="mic" className="cursor-pointer" />
                )}
                {!isGroupCall && (!isVideoTrack ? (
                  <img
                    src={CameraOff}
                    alt="camera-off"
                    className="cursor-pointer"
                  />
                ) : (
                  <img src={Camera} alt="camera" className="cursor-pointer" />
                ))}
                {isGroupCall && isVideoCall && (!isVideoTrack ? (
                  <img
                    src={CameraOff}
                    alt="camera-off"
                    className="cursor-pointer"
                  />
                ) : (
                  <img src={Camera} alt="camera" className="cursor-pointer" />
                ))}
                </div> */}
            </div>
          );
        })}
      </div>
    );
  };

  const userStream = (obj) => {
    const { user, stream, mute } = obj;
    return (
      <div
        id="cam"
        className="w-full h-[65vh] bg-black rounded-md text-white text-base font-semibold controls-btn mt-3"
        style={{ height: "150px", width: "220px", marginRight: "15px" }}
        key={user._id}
        onClick={() => maximizeUserVideo(stream, user)}
      >
        <div className="userinfo">
          <div className="flex items-center">
            {user?.imageSrc && user?.imageSrc != "" ? (
              <div className="ms-0 me-1">
                <img
                  src={user?.imageSrc}
                  className="rounded-circle avatar-xs"
                  alt="chatvia"
                />
              </div>
            ) : (
              <div className="chat-user-img align-self-center me-3">
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                    {user?.contactName?.first?.charAt(0)}
                  </span>
                </div>
              </div>
            )}
            <span className="font-semibold text-xs sm:text-md">
              {user.contactName.first + " " + user.contactName.last}
            </span>
          </div>
        </div>
        {stream ? (
          <Video
            stream={obj.stream}
            muted={mute == true ? true : false}
            style={{
              width: "220px",
              height: "140px",
              marginTop: "-50px",
              transform: `rotateY(180deg)`,
              zIndex: 1,
              objectFit: 'cover'
            }}
          />
        ) : (
          "Camera is off"
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-row justify-start">
      <div id="videomeeting-session" className="relative">
        <h3 id="meet-h1" className="hidden md:block md:absolute md:left-0 md:right-0 md:m-auto md:text-center md:top-3 md:z-10">{meetId}</h3>
        <div className="pt-2 px-2 flex flex-col gap-2 w-full">
          <div
            id="cam"
            className="w-full h-[55vh] bg-black rounded-md text-white text-base font-semibold"
            style={{ height: "500px" }}
          >
            <div className="active-userinfo">
              <div className="flex items-center">
                {activeUser?.imageSrc && activeUser?.imageSrc != "" ? (
                  <div className="me-1 ms-0">
                    <img
                      src={activeUser?.imageSrc}
                      className="rounded-circle avatar-xs"
                      alt="chatvia"
                    />
                  </div>
                ) : (
                  <div className="chat-user-img align-self-center me-3">
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                        {activeUser?.contactName?.first?.charAt(0)}
                      </span>
                    </div>
                  </div>
                )}
                <span className="font-semibold text-sm sm:text-md">
                  {activeUser?.contactName?.first +
                    " " +
                    activeUser?.contactName?.last}
                </span>
              </div>
            </div>
            <div id="controls">
              <div className="w-full flex justify-center gap-4">
                <div
                  className="w-9 h-9 rounded-full bg-blue-200 justify-center items-center flex cursor-pointer shadow-sm"
                // onClick={handleMic}
                >
                  {isMuted ? (
                    <img src={MicMute} alt="mic-mute" onClick={unmuteStream} />
                  ) : (
                    <img src={Mic} alt="mic" onClick={muteStream} />
                  )}
                </div>

                {!isGroupCall && <div
                  className="w-9 h-9 rounded-full bg-blue-200 justify-center items-center flex cursor-pointer shadow-sm"
                // onClick={handleCam}
                >
                  {!isVideoTrack ? (
                    <img
                      src={CameraOff}
                      alt="camera-off"
                      onClick={turnOnVideo}
                    />
                  ) : (
                    <img src={Camera} alt="camera" onClick={turnOffVideo} />
                  )}
                </div>}

                {isGroupCall && isVideoCall && <div
                  className="w-9 h-9 rounded-full bg-blue-200 justify-center items-center flex cursor-pointer shadow-sm"
                // onClick={handleCam}
                >
                  {!isVideoTrack ? (
                    <img
                      src={CameraOff}
                      alt="camera-off"
                      onClick={turnOnVideo}
                    />
                  ) : (
                    <img src={Camera} alt="camera" onClick={turnOffVideo} />
                  )}
                </div>}

                <div
                  className="w-9 h-9 rounded-full bg-red-200 justify-center items-center flex cursor-pointer shadow-sm"
                  onClick={() => endMeeting("Meet179")}
                >
                  <img src={Phone} alt="phone" />
                </div>
              </div>
            </div>

            {activeStream ? (
              <Video
                stream={activeStream}
                id="my-video"
                muted={true}
                style={{
                  transform: `rotateY(180deg)`,
                  zIndex: 1,
                  objectFit: 'cover'
                }}
              />
            ) : (
              "Camera is off"
            )}
          </div>

          <div id="users-stream" className="rounded-md">
            {userStream({ user: me, stream: localStream, mute: true })}

            {streams.length > 0 ? (
              streams.map((obj) => {
                return userStream(obj);
              })
            ) : (
              <div className="w-full h-9 flex justify-center items-center">
                <h3>Waiting for other users to join</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="participants-tab" className="pt-2">
        {isGroupCall ?
          <div className="users-details rounded-md">
            <Tabs
              type="card"
              items={new Array(2).fill(null).map((_, i) => {
                const id = String(i + 1);
                return {
                  label: id == 1 ? "Chats" : "Participants",
                  key: id,
                  children: id == 1 ? <VideoChat /> : <Participants />,
                };
              })}
            />
          </div>
          :
          <Participants />}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
