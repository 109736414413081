const Status = props => {
    let value = props.value
    if (value === null || value === "" || value === " " || value === undefined ) value = "no value"
	const status = value.toLowerCase().trim()
	let bg , label
	if (status === "active") {
        bg = "bg-green-400"
        label = "Active"
    }
	if (status === "inactive") { 
        bg = "bg-red-700" 
        label = "Inactive"
    }
	if(status === "no value" ) {
        bg = "bg-gray-500"
        label = "No Value"
    }
	return <p className={"px-4 py-2 text-center rounded-lg text-white font-bold " + bg } >{label}</p>
  }

export default Status