import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Button, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
//actions
import { openUserSidebar } from "../../../../store/messenger/layout/actions";
import CallModal from '../CallModal';
import GroupCallModal from "../GroupCallModal";
import CreateGroupModal from '../createGroupModal';
//import images
import { updateUser } from '../../../../services/users';
import { deleteGroup } from '../../../../services/conversations';

function UserHead(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [isCallmodal, setCallModal] = useState(false);
    const [isGroupModal, setGroupModal] = useState(false);
    const [isGroupCallModal, setGroupCallModal] = useState(false);
    const [isAudioGroupCallModal, setAudioGroupCallModal] = useState(false);
    const [Videomodal, setVideoModal] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggle1 = () => setDropdownOpen1(!dropdownOpen1);
    const toggleCallModal = () => setCallModal(!isCallmodal);
    const toggleGroupModal = () => setGroupModal(!isGroupModal);
    const toggleVideoModal = () => setVideoModal(!Videomodal);
    const toggleGroupCallModal = () => setGroupCallModal(!isGroupCallModal);
    const toggleAudioGroupCallModal = () => setAudioGroupCallModal(!isAudioGroupCallModal);

    const openUserSidebar = (e) => {
        e.preventDefault();
        props.openUserSidebar();
    }

    function closeUserChat(e) {
        e.preventDefault();
        var userChat = document.getElementsByClassName("user-chat");
        if (userChat) {
            userChat[0].classList.remove("user-chat-show");
        }
    }

    async function deleteChatOrGroup(child) {
        if (child?.isGroupChat) {
            console.log("deleted");
            const res = await deleteGroup(child?._id);
            console.log("res", res)
            if (res.status == 200) {
                props.handleDelConversation(child._id);
                toast.success(
                    "Group deleted..!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            } else {
                toast.error(
                    res.message,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            }
        }
    }

    const handleFavorite = async (child, val) => {

        if (!child.isGroupChat) {
            let newContacts = [];
            let newAllContacts = [];
            const msg = val ? 'Contact removed from favorites.!' : 'Contact added to favorites.!';
            for (let cntct of props.allContacts) {
                if (!cntct.isGroupChat) {
                    if (cntct._id === child._id) {
                        console.log(cntct)
                        newContacts.push({ ...cntct, star: !val })
                    } else {
                        newContacts.push({ ...cntct })
                    }
                }
                if (cntct._id === child._id) {
                    console.log(cntct)
                    newAllContacts.push({ ...cntct, star: !val })
                } else {
                    newAllContacts.push({ ...cntct })
                }

            }

            const res = await updateUser(props.currentUser._id, { contacts: newContacts });
            if (res.status == 200) {
                props.updateContacts(newAllContacts);
                toast.success(
                    msg,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            }
        } else {
            console.log("group can not favorite")
        }
    }
    console.log("userhead", isAudioGroupCallModal, isCallmodal, isGroupCallModal, Videomodal)
    return (
        <React.Fragment>
            <div className="p-3 p-lg-4 border-bottom user-head">
                <Row className="align-items-center">
                    <Col sm={4} xs={8}>
                        <div className="d-flex align-items-center">
                            <div className="d-block d-lg-none me-2 ms-0">
                                <Link to="#" onClick={(e) => closeUserChat(e)} className="user-chat-remove text-muted font-size-16 p-2">
                                    <i className="ri-arrow-left-s-line"></i></Link>
                            </div>
                            {

                                props?.contacts?.isGroupChat ?
                                    <div className="me-3 ms-0">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/021/063/599/small/group-coaching-icon-vector.jpg" className="rounded-circle avatar-xs" alt="chatvia" />
                                    </div>
                                    :
                                    (props.contacts?.user?.imageSrc ?
                                        <div className="me-3 ms-0">
                                            <img src={props.contacts?.user?.imageSrc} className="rounded-circle avatar-xs" alt="chatvia" />
                                        </div>
                                        : <div className="chat-user-img align-self-center me-3">
                                            <div className="avatar-xs">
                                                <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                    {props.contacts?.name?.charAt(0)}
                                                </span>
                                            </div>
                                        </div>)
                            }

                            <div className="flex-grow-1 overflow-hidden">
                                <h5 className="font-size-16 mb-0 text-truncate">
                                    <Link to="#" onClick={(e) => openUserSidebar(e)} className="text-reset user-profile-show">
                                        {props.contacts?.name}
                                    </Link>
                                    {(() => {
                                        switch (props?.contacts?.status) {
                                            case "online":
                                                return (
                                                    <>
                                                        <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
                                                    </>
                                                )

                                            case "away":
                                                return (
                                                    <>
                                                        <i className="ri-record-circle-fill font-size-10 text-warning d-inline-block ms-1"></i>
                                                    </>
                                                )

                                            case "offline":
                                                return (
                                                    <>
                                                        <i className="ri-record-circle-fill font-size-10 text-secondary d-inline-block ms-1"></i>
                                                    </>
                                                )

                                            default:
                                                return (
                                                    <>
                                                        <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
                                                    </>
                                                )
                                        }
                                    })()}

                                </h5>
                            </div>
                        </div>
                    </Col>
                    <Col sm={8} xs={4} >
                        <ul className="list-inline user-chat-nav text-end mb-0">

                            <li className="list-inline-item">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle color="none" className="btn nav-btn " type="button">
                                        <i className="ri-search-line"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-md">
                                        <div className="search-box p-2">
                                            <Input type="text" className="form-control bg-light border-0" placeholder="Search.." />
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                            <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                                <button type="button" onClick={props?.contacts?.isGroupChat ? toggleAudioGroupCallModal : toggleCallModal} className="btn nav-btn" >
                                    <i className="ri-phone-line"></i>
                                </button>
                            </li>
                            <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                                <button type="button" onClick={props?.contacts?.isGroupChat ? toggleGroupCallModal : toggleVideoModal} className="btn nav-btn">
                                    <i className="ri-vidicon-line"></i>
                                </button>
                            </li>

                            <li className="list-inline-item d-none d-lg-inline-block">
                                <Button type="button" color="none" onClick={(e) => openUserSidebar(e)} className="nav-btn user-profile-show">
                                    <i className="ri-user-2-line"></i>
                                </Button>
                            </li>

                            <li className="list-inline-item">
                                <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
                                    <DropdownToggle className="btn nav-btn " color="none" type="button" >
                                        <i className="ri-more-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end"  >
                                        <div id="mobile-dropdown">
                                            <DropdownItem onClick={() => props.contacts.isGroupChat ? toggleAudioGroupCallModal() : toggleCallModal()} >Audio call <i className="ri-phone-line float-end"></i></DropdownItem>
                                            <DropdownItem onClick={() => props.contacts.isGroupChat ? toggleGroupCallModal() : toggleVideoModal()} > Video call <i className="ri-vidicon-line float-end"></i></DropdownItem>
                                            <DropdownItem className="d-block d-lg-none user-profile-show" onClick={(e) => openUserSidebar(e)}>View profile <i className="ri-user-2-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem>Archive <i className="ri-archive-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem>Muted <i className="ri-volume-mute-line float-end text-muted"></i></DropdownItem>
                                            {!props?.contacts?.isGroupChat && <DropdownItem onClick={toggleGroupModal}>Create group <i className="ri-group-line float-end text-muted"></i></DropdownItem>}
                                            <DropdownItem onClick={() => deleteChatOrGroup(props.contacts)}>Delete <i className="ri-delete-bin-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem onClick={() => handleFavorite(props.contacts, props.contacts.star)}>{!props?.contacts?.star ? 'Add Favorite' : 'Remove Favorite'} <i className="ri-star-line float-end text-muted"></i></DropdownItem>
                                        </div>
                                        <div id="desktop-dropdown">
                                            <DropdownItem className="d-block d-lg-none user-profile-show" onClick={(e) => openUserSidebar(e)}>View profile <i className="ri-user-2-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem>Archive <i className="ri-archive-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem>Muted <i className="ri-volume-mute-line float-end text-muted"></i></DropdownItem>
                                            {!props?.contacts?.isGroupChat && <DropdownItem onClick={toggleGroupModal}>Create group <i className="ri-group-line float-end text-muted"></i></DropdownItem>}
                                            <DropdownItem onClick={() => deleteChatOrGroup(props.contacts)}>Delete <i className="ri-delete-bin-line float-end text-muted"></i></DropdownItem>
                                            <DropdownItem onClick={() => handleFavorite(props.contacts, props.contacts.star)}>{!props?.contacts?.star ? 'Add Favorite' : 'Remove Favorite'} <i className="ri-star-line float-end text-muted"></i></DropdownItem>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>

                        </ul>
                    </Col>
                </Row>
            </div>

            {/* Start Audiocall Modal */}

            <CallModal
                isOpen={Videomodal}
                toggleCallModal={toggleVideoModal}
                user={props?.contacts?.user}
                isVideo={true}
                currentUser={props.currentUser}
                conversationId={props.conversationId}
                updateMessages={props.updateMessages}
            />

            <CallModal
                isOpen={isCallmodal}
                toggleCallModal={toggleCallModal}
                user={props?.contacts?.user}
                isVideo={false}
                currentUser={props.currentUser}
                conversationId={props.conversationId}
                updateMessages={props.updateMessages}
            />

            <GroupCallModal
                isOpen={isGroupCallModal}
                toggleCallModal={toggleGroupCallModal}
                user={props?.contacts}
                isVideo={true}
                currentUser={props.currentUser}
                conversationId={props.conversationId}
                updateMessages={props.updateMessages}
            />

            <GroupCallModal
                isOpen={isAudioGroupCallModal}
                toggleCallModal={toggleAudioGroupCallModal}
                user={props?.contacts}
                isVideo={false}
                currentUser={props.currentUser}
                conversationId={props.conversationId}
                updateMessages={props.updateMessages}
            />

            <CreateGroupModal
                isOpen={isGroupModal}
                toggleCallModal={toggleGroupModal}
                user={props?.contacts?.user}
                isVideo={false}
                currentUser={props.currentUser}
                contacts={props.allContacts}
                updateConversations={props.updateConversations}
                updateContacts={props.updateContacts}
            />

        </React.Fragment>
    );
}


const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { openUserSidebar })(UserHead);