import React from 'react';
import auth from './../../../services/authservice';
import {getUser} from './../../../services/users';
class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser:{
			 firstName: '',
			 lastName : '',
			 username: '',
			 imageSrc: ''
			}
		};
	}

    async componentDidMount(){
		try{
				const user = auth.getProfile();	
		if(user){
			const {data:currentUser} = await getUser(user._id);
			this.setState({ currentUser: this.mapToViewModel(currentUser) });
		}
		}catch(ex){
			console.log(ex);
		}
	
	}
	

	mapToViewModel(user) {
		return {
		  _id: user._id,
		  username: user.username,
		  password: user.password,
		  profile: user.profile,
		  email: user.email,
		  firstName: user.contactName.first,
		  lastName: user.contactName.last,
		  initials: user.contactName.initials,
		  country: user.country,
		  gender: user.gender,
		  prefix: user.prefix,
		  imageSrc: user.imageSrc
		};
	  }
	render() {
		const { currentUser} = this.state;
		return (
			<div className="navbar-item navbar-user dropdown">
				<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<img src={currentUser.imageSrc} alt="" />
					{/* <span>
						<span className="d-none d-md-inline">{currentUser.firstName} {currentUser.lastName}</span>
						<b className="caret"></b>
					</span> */}
				</a>
				<div className="dropdown-menu dropdown-menu-end me-1">
					<a href="/user/profile">Edit Profile</a> 
					<a href="#/" className="dropdown-item d-flex align-items-center">
						Inbox
						<span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span> 
					</a>
					<a href="/calendar" className="dropdown-item">Calendar</a>
					<a href="/settings" className="dropdown-item">Settings</a>
					<div className="dropdown-divider"></div>
					<a href="/logout" className="dropdown-item">Log Out</a>
				</div>
			</div>
		);
	}
};

export default DropdownProfile;
