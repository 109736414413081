import React from "react";
import { Link,redirect} from "react-router-dom";
import withRouter from "../../common/withRouter.jsx";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ReactTags from "react-tag-autocomplete";
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import moment from "moment";
import Select from 'react-select';
//import Select from "../../common/select";
import CountryDropDown from "../../components/user/CountryDropDown";
import GenderDropDown from "../../components/user/GenderDropDown";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import Joi from "joi";
import Form from "../../common/form.jsx";

import http from "../../services/httpService"; 
import { saveCompany, getCompany,getCompanies} from "./../../services/companies.js";
import PhoneInput from "react-phone-input-2";
import { getCode } from "country-list";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;
const apiUrl = process.env.REACT_APP_API_URL;

class CompanySolo extends Form {
  constructor(props) {
    super(props);

    var maxYesterday = "";
    var minYesterday = DateTime.moment().subtract(1, "day");
    
    this.minDateRange = (current) => {
      return current.isAfter(minYesterday);
    };
    this.maxDateRange = (current) => {
      return current.isAfter(maxYesterday);
    };
    this.minDateChange = (value) => {
      this.setState({
        maxDateDisabled: false,
      });
      maxYesterday = value;
    };

    this.state = {
      maxDateDisabled: true,
      countries: [],
      profiles: [],
      data: {
        username: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        initials: "",
        address1: "",
        address2: "",
        address3: "",
        zip: "",
        city: "",
        state: "",
        country: "",
        longitude: "",
        latitude: "",
        dateBirth: new Date(),
        gender: "",
        imageSrc: null,
        prefix: "",
        phone: "",
        mobile: "",
        skype: "",
        IBAN: "",
        bank: "",
        branchOfBank: "",
        subscription: "",
        subscriptionStartDate: new Date(),
        subscriptionPeriod: "month",						
        businessName: "",
        chamberCommerceNo: "",
        taxPayerNo: "",
        website: "",
        size: "",
        treatments: "",
        licenseNo: "",
        licenseValidTill: new Date(),
        licenseValidTill: new Date(),
        branch: "",
        subBranch: "",
        organizationAName: "",
        organizationAMemberNo: "",
        organizationBName: "",
        organizationBMemberNo: "",
        status: "",
      },
      selectedFile: null,
      errors: {},
    };

    this.prefixOptions = [
      { value: "Mr", label: "Mr." },
      { value: "Mrs", label: "Mrs." },
      { value: "Mss", label: "Mss." },
      { value: "Ms", label: "Ms." },
      { value: "Prof", label: "Prof." },
      { value: "Dr", label: "Dr." },
    ];

    this.genderOptions = [
      { value: "Female", label: "Female" },
      { value: "Male", label: "Male" },
      { value: "Other", label: "Other" },
    ];

    this.subscriptionOptions = [
      { value: "Company", label: "Company" },
      { value: "Solo", label: "Solo" },
    ];

    this.subBranchOptions = [
      { value: "Traditional Chinese Medicine", label: "TCM" },
      { value: "General Practitioner", label: "General Practitioner" },
      { value: "Ayurveda", label: "Ayurveda" },
      { value: "Homeopathy", label: "Homeopathy" },
      { value: "Dental", label: "Dental" },	  
      { value: "Pharmacy", label: "Pharmacy" },	  	  
      { value: "Supplier", label: "Supplier" },	  	  	  
      { value: "Insurance", label: "Insurance" },	  	  	  	  
      { value: "Organization", label: "Organization" },	  	  	  	  	  
      { value: "Education", label: "Education" },	  	  	  	  	  	  
    ];

    this.statusOptions = [
      { value: "active", label: "Active" },
      { value: "banned", label: "Banned" },
      { value: "deleted", label: "Deleted" },
      { value: "inactive", label: "Inactive" },
      { value: "archived", label: "Archived" },
    ];

    this.handleSlider = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeImgHandler = this.onChangeImgHandler.bind(this);
  }

  async populateCountries() {
    const { data: countries } = await http.get(apiUrl + "/countries");
    this.setState({ countries: countries });
    //this.selectCountries = this.state.countries.map((country)=>({label: country.name, value: country.name}) );
    // this.selectCountries = this.state.countries.map((country) => ({
    //   _id: country._id,
    //   label: country.name,
    //   value: country.name,
    // }));
    console.log("Countries",countries);
  }

  async populateGenders() {
    this.genderoptions = this.genderOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));
  }
  async populatePrefix() {
    this.prefixoptions = this.prefixOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));
  }
  async populateStatus() {
    this.statusoptions = this.statusOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));
  }
  async populatesubBranch() {
    this.subBranchoptions = this.subBranchOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));
  }

  async populateUser() {
    
    try {
      const {params}=this.props.router
      // console.log(params,"This is our params");
      const {id:userId} = params;
        
        if (userId === "new") return;
        console.log("print userID",userId);
      //console.log("hello",companies[0]);
      // const company=companies.filter((company)=>{
      //   return company._id===userId;
      // })[0];
      //const Company = Object.assign({},user[0]);
      const data=await getCompany(userId);
      console.log("our2 comapny",data.data);
      const companies=data.data
      const oldCompany=companies[0];
      console.log("our old comapny",oldCompany);
      const {data:tempCompanies}=await getCompany(userId)
      //if (!company.dateBirth) company.dateBirth = new Date();
      const company=tempCompanies[0];
      company.firstName =oldCompany.contactName.first;
      console.log("Company first name",company.firstName);
      company.lastName =oldCompany.contactName.last;
      company.initials =oldCompany.contactName.initials;
      company.IBAN =oldCompany.bankInfo.IBAN;
      company.bank =oldCompany.bankInfo.bank;
      company.branchOfBank =oldCompany.bankInfo.branchOfBank;
      company.subscription =oldCompany.subscription.subscription;
      company.subscriptionStartDate =oldCompany.subscription.subscriptionStartDate;
      company.subscriptionPeriod =oldCompany.subscription.subscriptionPeriod;	  
      company.businessName =oldCompany.companyInfo.businessName;
      company.chamberCommerceNo =oldCompany.companyInfo.chamberCommerceNo;
      company.taxPayerNo =oldCompany.companyInfo.taxPayerNo;
      company.website =oldCompany.companyInfo.website;
      company.size =oldCompany.companyInfo.size;
      company.treatments =oldCompany.professionalInfo.treatments;
      company.licenseNo =oldCompany.professionalInfo.licenseNo;
      company.licenseValidTill =oldCompany.professionalInfo.licenseValidTill;
      company.branch =oldCompany.professionalInfo.branch;
      company.subBranch =oldCompany.companyInfo.subBranch;
      // company.organizationAName =oldCompany.membership.organizationAName;
      // company.organizationAMemberNo =oldCompany.membership.organizationAMemberNo;
      // company.organizationBName =oldCompany.membership.organizationBName;
      // company.organizationBMemberNo =oldCompany.membership.organizationBMemberNo;
      company.longitude =oldCompany.companyInfo.location.coordinates[0];
      company.latitude =oldCompany.companyInfo.location.coordinates[1];
      //  delete company.password;
      console.log("above model");
      this.setState({ data: this.mapToViewModel(company) });
      console.log("below model");
      console.log("our final company",company);
    } catch (ex) {
      console.log("error",ex);
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/error");
    }
  }

  async componentDidMount() {
    //await this.populateProfiles();
    console.log("hello mount");
    await this.populatePrefix();
    await this.populateCountries();
    await this.populateGenders();
    await this.populatesubBranch();		
    await this.populateStatus();
    await this.populateUser();
  }

  schema = Joi.object({
    username: Joi.string().alphanum().min(1).max(30).required(),

    password: Joi.string().pattern(new RegExp("^[a-zA-Z0-9]{3,30}$")),
    firstName: Joi.string(),
    lastName: Joi.string(),
    initials: Joi.any().optional(),
    gender: Joi.string().optional(),
    prefix: Joi.any().optional(),
    address1: Joi.any().optional(),
    address2: Joi.any().optional(),
    address3: Joi.any().optional(),
    zip: Joi.any().optional(),
    city: Joi.any().optional(),
    state: Joi.any().optional(),
    country: Joi.any().optional(),
    phone: Joi.any().optional(),
    mobile: Joi.any().optional(),
    skype: Joi.any().optional(),
    //profile: Joi.any().required(),
    dateBirth: Joi.any().optional(),
    // email: Joi.string()
    // 	.email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),
    email: Joi.string().email({ tlds: { allow: false } }),
    IBAN: Joi.any().optional(),
    bank: Joi.any().optional(),
    branchOfBank: Joi.any().optional(),
    subscription: Joi.string().required(),
    // subscriptionStartDate: Joi.any().optional(),
    businessName: Joi.any().required(),
    website: Joi.any().optional(),
    size: Joi.any().optional(),
    branch: Joi.any().optional(),
    subBranch: Joi.any().optional(),	
    chamberCommerceNo: Joi.any().optional(),
    taxPayerNo: Joi.any().optional(),
    treatments: Joi.any().optional(),
    licenseNo: Joi.any().optional(),
    licenseValidTill: Joi.any().optional(),
    organizationAName: Joi.any().optional(),
    organizationAMemberNo: Joi.any().optional(),
    organizationBName: Joi.any().optional(),
    organizationBMemberNo: Joi.any().optional(),
    longitude: Joi.any().optional(),
    latitude: Joi.any().optional(),
    subscription: Joi.any().required(),	
    subscriptionStartDate: Joi.any().required(),		
    subscriptionPeriod: Joi.any().required(),			
  });

  handleDobChange = (e) => {
    const errors = { ...this.state.errors };
    const obj = { ["dateBirth"]: e };

    const data = { ...this.state.data };
    data["dateBirth"] = e;
    //const data = {...this.state.data};
    //data.dateBirth = e;
    this.setState({ data });
    console.log(this.state.data);
  };
  handleLicenseValidTill = (e) => {
    console.log("hello ",e);
    const errors = { ...this.state.errors };
    const obj = { ["licenseValidTill"]: e };

    const data = { ...this.state.data };
    data["licenseValidTill"] = e;
    //const data = {...this.state.data};
    //data.dateBirth = e;
    this.setState({ data });
    console.log(this.state.data);
  };
  handlesubscriptionStartDateChange =(e)=>{
    const errors = { ...this.state.errors };
    const obj = { ["subscriptionStartDate"]: e };

    const data = { ...this.state.data };
    data["subscriptionStartDate"] = e;
    this.setState({ data });
    console.log(this.state.data);
  }
  onChangeImgHandler = (event) => {
    this.setState({ imageSrc: event.target.files[0] });
    console.log(event.target.files[0]);
  };

  handleSubmit = async (event) => {
    console.log("hello");
    event.preventDefault();
    // return redirect("/user/companies")
    // return <Redirect to="/>
    console.log('working');
    try {
      // this.props.history=[]
      // console.log("Rediect to history",this.props);
      console.log("nvkfdbkd",this.state.data);
      await saveCompany(this.state.data, this.state.imageSrc);
      const {navigate}=this.props.router;
      navigate("/user/companies")
      console.log("Successfully saved");
      //console.log(this.state.data);
     
      // this.props.history.push("/user/companies")
        
    } catch (ex) {
      //if(ex.response && ex.response.status === 404){
        console.log("error=> ",ex);

      if (ex.response) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
        //console.log(this.state.errors);
      }
      
    }
  };

  mapToViewModel(user) {
    console.log("insidemapToModel");
    return {
      _id: user._id,
      username: user.username,
      password: user.password,
      //profile: user.profile,
      email: user.email,
      dateBirth: user.dateBirth,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: user.initials,
      prefix: user.prefix,
      address1: user.address1,
      address2: user.address2,
      address3: user.address3,
      city: user.city,
      state: user.state,
      zip: user.zip,
      country: user.country,
      phone: user.phone,
      mobile: user.mobile,
      skype: user.skype,
      gender: user.gender,
      imageSrc: user.imageSrc,
      status: user.status,
      IBAN: user.IBAN,
      bank: user.bank,
      branchOfBank: user.branchOfBank,
      subscription: user.subscription,
      subscriptionStartDate: user.subscriptionStartDate,
      subscriptionPeriod: user.subscriptionPeriod,	  
      website: user.website,
      size: user.size,
      branch: user.branch,
      subBranch: user.subBranch,	  
      businessName: user.businessName,
      chamberCommerceNo: user.chamberCommerceNo,
      taxPayerNo: user.taxPayerNo,
      treatments: user.treatments,
      licenseNo: user.licenseNo,
      licenseValidTill: user.licenseValidTill,
      organizationAName: user.organizationAName,
      organizationAMemberNo: user.organizationAMemberNo,
      organizationBName: user.organizationBName,
      organizationBMemberNo: user.organizationBMemberNo,
      longitude: user.longitude,
      latitude: user.latitude,
    };
  }

  render() {
    const { data, errors } = this.state;
    console.log("our edit company23",data);
    return (
      <React.Fragment>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/form/plugins">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/user/companies">Companies</Link>
            </li>
            <li className="breadcrumb-item active">Add Company</li>
          </ol>
          <h1 className="page-header">
            Add Company-Solo <small>Company-Solo-registration-form</small>
          </h1>

          <div className="row">
            <div className="col-xl-10">
              <Panel>
                <PanelHeader>Add Company</PanelHeader>
                <PanelBody className="panel-form">
                  <form
                    className="form-horizontal form-bordered"
                    action="/user/companies"
                    onSubmit={this.handleSubmit}
                  >
                    {this.renderInput("businessName","Business-Name","text","Enter Business-Name")}

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Subscription Type
                      </label>

                      <div className="btn-group col-lg-8">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            name="subscription"
                            id="customRadioInline1"
                            className="custom-control-input"
                            onChange={this.handleChange}
                            value="Company"
                            checked={data.subscription === "Company"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioInline1"
                          >
                            {" "}
                            Company
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            name="subscription"
                            id="customRadioInline2"
                            className="custom-control-input"
                            onChange={this.handleChange}
                            value="Solo"
                            checked={data.subscription === "Solo"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadioInline2"
                          >
                            {" "}
                            SoloPractice
                          </label>
                        </div>
                    
                      
                      </div>
                      
                      {errors.subscription && (
                        <div className="alert alert-danger">
                          {errors.subscription}
                        </div>
                      )}
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="subscriptionStartDate">
                      Startdate of subscription
                      </label>
                      <div className="col-lg-8">
                        <DatePicker
                          onChange={this.handlesubscriptionStartDateChange}
                          id={data.subscriptionStartDate}
                          value={new Date(data.subscriptionStartDate)}
                          selected={new Date(data.subscriptionStartDate)}
                          inputProps={{ placeholder: "Datepicker" }}
                          className="form-control"
                        />
                        {errors.subscriptionStartDate && (
                          <div className="alert alert-danger">
                            {errors.subscriptionStartDate}
                          </div>
                        )}
                      </div>
                      </div>
                    {/* <FormControl component="fieldset">

      <FormLabel component="legend">Subscription Type</FormLabel>
  
      <RadioGroup row aria-label="position" name="subscription" defaultValue="top">
        <FormControlLabel
          value="Solo"
          control={<Radio color="primary" />}
          label="SoloPractice"
          labelPlacement="center" onChange={this.handleChange} value="Solo" checked={data.subscription === "Solo" }
        />
    
        <FormControlLabel value="Company" control={<Radio color="primary" />} label="Company" labelPlacement="center" onChange={this.handleChange} value="Company"  checked={data.subscription === "Company" }  />
      </RadioGroup>

	  {errors.subscription && (<div className="alert alert-danger">{errors.subscription}</div>)}
    </FormControl> */}


                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="prefix"
                      >
                        Prefix
                      </label>
                      <div className="col-lg-8">
                        <select
                          name="prefix"
                          id="prefix"
                          value={data.prefix}
                          onChange={this.handleChange}
                          className="form-control"
                        >
                          <option value="">Select Prefix</option>
                          {this.prefixoptions}
                        </select>
                      </div>
                      {errors.prefix && (
                        <div className="alert alert-danger">
                          {errors.prefix}
                        </div>
                      )}
                    </div>

                    {this.renderInput("firstName","First Name","text","* Enter Firstname")}
                    {this.renderInput("initials","Initials","text","Enter Initials")}
                    {this.renderInput("lastName","Last Name","text","* Enter Lastname")}
                    {this.renderInput("address1","Address 1","text","Enter address1")}
                    {this.renderInput("address2","Address 2","text","Enter address2")}
                    {this.renderInput("address3","Address 3","text","Enter address3")}
                    {this.renderInput("city", "City", "text", "Enter City")}
                    {this.renderInput("state", "State", "text", "Enter State")}
                    {this.renderInput("zip","Zip code","text","Enter zipcode")}

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      <div className="col-lg-8">
                        {/* <Select
                          value={"Gar"}
                        /> */}
                        <CountryDropDown
                          placeholder="country"
                          changeHandler={(e) => {
                            const data = { ...this.state.data };
                            data["country"] = e;
                            this.setState({ data });
                          }}
                          selectedValue={
                            this.state.data.country
                              ? this.state.data.country
                              : "Netherlands"
                          }
                          name="country"
                        />
                      </div>
                      {errors.country && (
                        <div className="alert alert-danger">
                          {errors.country}
                        </div>
                      )}
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="phone"
                      >
                        Phone
                      </label>
                      <div className="col-lg-8">
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                            height: "5px",
                          }}
                          country={
                            getCode(this.state.data.country)?.toLowerCase() ||
                            "nl"
                          }
                          value={
                            this.state.data.phone ? this.state.data.phone : ""
                          }
                          onChange={(_Phone) => {
                            const data = { ...this.state.data };
                            data["phone"] = _Phone;
                            this.setState({ data });
                          }}
                        />
                      </div>
                      {errors.phone && (
                        <div className="alert alert-danger">{errors.phone}</div>
                      )}
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="mobile"
                      >
                        Mobile
                      </label>
                      <div className="col-lg-8">
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                          }}
                          country={
                            getCode(this.state.data.country)?.toLowerCase() ||
                            "nl"
                          }
                          value={
                            this.state.data.mobile ? this.state.data.mobile : ""
                          }
                          onChange={(_Phone) => {
                            const data = { ...this.state.data };
                            data["mobile"] = _Phone;
                            this.setState({ data });
                          }}
                        />
                      </div>
                      {errors.mobile && (
                        <div className="alert alert-danger">
                          {errors.mobile}
                        </div>
                      )}
                    </div>

                    {this.renderInput("skype", "Skype", "text", "Enter skype")}

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="gender"
                      >
                        Gender
                      </label>
                      <div className="col-lg-8">
                        <select
                          name="gender"
                          id="gender"
                          value={data.gender}
                          onChange={this.handleChange}
                          className="form-control"
                        >
                          <option value="">Select Gender</option>
                          {this.genderoptions}
                        </select>
                      </div>
                      {errors.gender && (
                        <div className="alert alert-danger">
                          {errors.gender}
                        </div>
                      )}
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="dateBirth">
                        Date of Birth
                      </label>
                      <div className="col-lg-8">
                        <DatePicker
                          onChange={this.handleDobChange}
                          id={data.dateBirth}
                          value={new Date(data.dateBirth)}
                          selected={new Date(data.dateBirth)}
                          inputProps={{ placeholder: "Datepicker" }}
                          className="form-control"
                        />
                        {errors.dateBirth && (
                          <div className="alert alert-danger">
                            {errors.dateBirth}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="username"
                      >
                        UserName
                      </label>
                      <div className="col-lg-8">
                        <div className="row row-space-10">
                          <input
                            type="text"
                            id="username"
                            name="username"
                            value={data.username}
                            className="form-control m-b-5"
                            placeholder="Enter username"
                            onChange={this.handleChange}
                            autoFocus
                          />
                          {errors.username && (
                            <div className="alert alert-danger">
                              {errors.username}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {this.renderInput("email", "Email", "email", "Enter email")}
                    {this.renderInput("password","Password","password","Enter Password")}

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="imageSrc"
                      >
                        Logo
                      </label>
                      <div className="col-lg-8">
                        <div className="row row-space-10">
                          <input
                            type="file"
                            id="imageSrc"
                            name="imageSrc"
                            className="form-control-file m-b-5"
                            onChange={this.onChangeImgHandler}
                          />
                          <img
                            src={data.imageSrc}
                            alt=""
                            className="media-object"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          />
                          {errors.imageSrc && (
                            <div className="alert alert-danger">
                              {errors.imageSrc}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="subBranch">
                        Sub-Branch
                      </label>
                      <div className="col-lg-8">
                        <select name="subBranch" id="subBranch" value={data.subBranch} 
                          onChange={this.handleChange} className="form-control" >
                          <option value="">Select Sub-Branch</option>
                          {this.subBranchoptions}
                        </select>
                      </div>
                      {errors.gender && (
                        <div className="alert alert-danger">
                          {errors.subBranch}
                        </div>
                      )}

                    </div>

                    {this.renderInput("longitude","Longitude","text","Enter longitude")}
                    {this.renderInput("latitude","Latitude","text","Enter latitude")}
                    {this.renderInput("bank", "Bank", "text", "Enter Bank")}
                    {this.renderInput("branchOfBank","Branch Of Bank","text","Enter branchOfBank")}
                    {this.renderInput("IBAN", "IBAN", "text", "Enter IBAN")}
                    {this.renderInput("chamberCommerceNo","Chamber Commerce No","text","Enter chamberCommerceNo")}
                    {this.renderInput("taxPayerNo","Tax Payer No","text","Enter taxPayerNo")}
                    {this.renderInput("website","Website","text","Enter website")}
                    {this.renderInput("size", "Size", "text", "Enter size")}
                    {this.renderInput("treatments","Treatments","text","Enter treatments")}
                    {this.renderInput("licenseNo","License No","text","Enter licenseNo")}
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="licenseValidTill">

                      licenseValidTill
                      </label>
                      <div className="col-lg-8">
                        <DatePicker
                          onChange={this.handleLicenseValidTill}
                          id={data.licenseValidTill}
                          value={new Date(data.licenseValidTill)}

                          selected={new Date(data.licenseValidTill)}
                          inputProps={{ placeholder: "Datepicker" }}
                          
                          

                          className="form-control"
                        />
                        {errors.licenseValidTill && (
                          <div className="alert alert-danger">
                            {errors.licenseValidTill}
                          </div>
                        )}
                      </div>

                    </div>
                   

                    {this.renderInput("organizationAName","OrganizationA Name","text","Enter organizationAName")}
                    {this.renderInput("organizationAMemberNo","OrganizationA Member No","text","Enter organizationAMemberNo")}
                    {this.renderInput("organizationBName","OrganizationB Name","text","Enter organizationBName")}
                    {this.renderInput("organizationBMemberNo","OrganizationB MemberNo","text","Enter organizationBMemberNo")}

                    <div className="form-group row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="status"
                      >
                        Select Status
                      </label>
                      <div className="col-lg-8">
                        <select
                          name="status"
                          id="status"
                          value={data.status}
                          onChange={this.handleChange}
                          className="form-control"
                        >
                          <option value="">Select Status</option>
                          {this.statusoptions}
                        </select>
                      </div>
                      {errors.status && (
                        <div className="alert alert-danger">
                          {errors.status}
                        </div>
                      )}
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-8">
                        {/* <Link to='/user/companies'><button
                          type="submit"
                          className="btn btn-primary width-65"
                        >
                          Submit
                        </button></Link> */}
                        <button
                          type="submit"
                          className="btn btn-primary width-65"
                        >
                          Submit
                        </button>
                      </div>
                      
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CompanySolo); 
