import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Input, Row, Col, UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu, Label, Form } from "reactstrap";
import EmojiPicker from 'emoji-picker-react';
import { uploadAttachment } from '../../../../services/attachments';
import { Progress } from 'antd';
import Dropzone from '../DropZone';

function ChatInput(props) {
    const [textMessage, settextMessage] = useState("");
    const [isOpen, setisOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [file, setfile] = useState([]);
    const [status, setStatus] = useState("active");
    const [fileImage, setfileImage] = useState([])
    const containerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('paste', e => {
            console.log("paste files", e.clipboardData.files);
            handleFileChange({
                target: {
                    files: e.clipboardData.files
                }
            });
        });

        return () => {
            window.removeEventListener("paste", () => { })
        }
    }, [])


    const toggleOpen = () => setisOpen(!isOpen);

    //function for text input value change
    const handleChange = e => {
        settextMessage(e.target.value)
    }

    const onEmojiClick = (event, emojiObject) => {
        settextMessage(textMessage + emojiObject.emoji)
    };

    //function for file input change
    const handleFileChange = async e => {

        console.log("in file handle", e)
        const files = e.target.files;
        console.log("files is", files)

        if (files.length > 0) {
            setfile([{
                fileName: files[0].name,
                fileSize: files[0].size,
            }])
            let data = new FormData();
            for (let item of files) {
                data.append("attachments", item);
            }
            console.log("form data is", data)
            try {
                const res = await uploadAttachment(data, {
                    headers: {
                        'Content-Type': files[0].type,
                        'Content-Length': files[0].size,
                    },
                    onUploadProgress: (progressEvent) => {
                        const prog = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        // console.log(progressEvent, prog);
                        setProgress(prog)
                    }
                });
                if (res.status == 200) {
                    console.log("res is", res)
                    // props.onaddMessage(res.data, "imageMessage");
                    // setfileImage([res.data]);
                    setfile([{
                        fileName: files[0].name,
                        fileSize: files[0].size,
                        filePath: res.data[0].filePath,
                        fileType: res.data[0].fileType
                    }])
                }
            } catch (e) {
                console.log("error is", e.message)
                setStatus("exception")
            }
        }
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    //function for image input change
    const handleImageChange = async e => {
        const files = e.target.files;
        console.log("image", files)
        if (files.length > 3) {
            alert("you can not upload more than 3 images at one time");
            return;
        }

        let media = [];
        for (let key in files) {
            if (key < files.length) {
                const uri = await convertToBase64(files[key]);
                media.push({
                    file: files[key],
                    uri: uri
                })

            }
        }

        console.log("media", media)
        setfileImage(media);

    }

    const handleDrop = async (files) => {
        console.log("drop files is ", files);
        props?.setDrag(false);
        await handleFileChange({
            target: {
                files
            }
        });
        console.log("after handleChange")
    }

    const handleRemoveImage = (key) => {

        let newArr = [...fileImage];

        newArr = newArr.filter((item, index) => {
            if (key !== index) {
                return true;
            }
        });
        setfileImage(newArr)


    }
    //function for send data to onaddMessage function(in userChat/index.js component)
    const onaddMessage = async (e, textMessage) => {
        e.preventDefault();
        //if text value is not emptry then call onaddMessage function
        if (textMessage !== "") {
            props.onaddMessage(textMessage, "textMessage");
            settextMessage("");
            return;
        }

        //if file input value is not empty then call onaddMessage function
        if (file.length > 0) {
            props.onaddMessage(file, "imageMessage");
            setfile([]);
            return;
        }

        //if image input value is not empty then call onaddMessage function
        if (fileImage.length > 0) {
            let data = new FormData();
            for (let item of fileImage) {
                data.append("attachments", item.file);
            }

            try {
                const res = await uploadAttachment(data);
                if (res.status == 200) {
                    console.log("res is", res)
                    props.onaddMessage(res.data, "imageMessage");
                    setfileImage([]);
                }
            } catch (e) {
                console.log("error is", e.message)
            }
        }
    }


    const showUploadedImages = (data) => {

        const fileImageData = data ? data : fileImage; 
        return fileImageData.map((file, key) => {
            return <div style={{ padding: 5 }} key={key}>
                <img src={ data ? `https://backend.itransportindex.com/api/${file.filePath}` : file.uri} height='50px' width='60px' id="" />
                <span onClick={() => handleRemoveImage(key)} style={{ display: 'block', height: 20, width: 20, borderRadius: 10, backgroundColor: 'white', color: 'white', position: 'relative', top: -65, left: -5, textAlign: 'center', border: 'red 1px solid' }} className='cursor-pointer'>❌</span>
            </div>
        })
    }

    const showUploadedFiles = () => {

        return file.map((file, key) => {
            if(["image/jpeg","image/png"].includes(file.fileType)){
                return showUploadedImages([{...file}]);
            }
            console.log(file)
            return <div style={{ padding: 5, minWidth: 150 }} key={key}>
                <div className='mt-4'>
                    <span className='text-md text-gray-600'>{file.fileName}</span>
                    <Progress percent={progress} size="small" status={progress == 100 ? "" : status} />
                </div>
                <span onClick={() => { setProgress(0); setfile([]) }} style={{ display: 'block', height: 20, width: 20, borderRadius: 10, backgroundColor: 'white', color: 'white', position: 'relative', top: -65, left: -5, textAlign: 'center', border: 'red 1px solid' }} className='cursor-pointer'>❌</span>
            </div>
        })
    }

    return (
        <React.Fragment>
            <div className="p-2 border-top mb-0">
                <div className='flex justify-start item-center' style={props?.isForVideoChat ? {} : { padding: 5 }}>
                    {
                        showUploadedImages()
                    }
                    {
                        showUploadedFiles()
                    }
                    {props?.isDrag && <Dropzone handleDrop={handleDrop} />}
                </div>
                <Form onSubmit={(e) => onaddMessage(e, textMessage)} >
                    <Row className='g-0'>
                        <Col>
                            <div>
                                <Input ref={containerRef} type="text" value={textMessage} onChange={handleChange} className="form-control form-control-lg bg-light border-light" placeholder="Enter Message..." />
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div className="chat-input-links ms-md-2">
                                <ul className="list-inline mb-0 ms-0">
                                    {/* <EmojiPicker height={500} width={400} /> */}
                                    {/* <EmojiPicker onEmojiClick={onEmojiClick}  /> */}
                                    <li className="list-inline-item">
                                        <ButtonDropdown className="emoji-dropdown" direction="up" isOpen={isOpen} toggle={toggleOpen}>
                                            <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-emotion-happy-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <EmojiPicker onEmojiClick={onEmojiClick} />
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                        <UncontrolledTooltip target="emoji" placement="top">
                                            Emoji
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="list-inline-item input-file">
                                        <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                            <i className="ri-attachment-line"></i>
                                            <Input onChange={(e) => handleFileChange(e)} type="file" name="fileInput" size="60" accept='.txt, .xlx, .xlsx, .doc, .docx, .pdf, .ppt, .pptx, .zip' />
                                        </Label>
                                        <UncontrolledTooltip target="files" placement="top">
                                            Attached File
                                        </UncontrolledTooltip>
                                    </li>

                                    {!props?.isRecording ?
                                        <li className="list-inline-item input-file" onClick={props?.startRecording}>
                                            <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-mic-fill"></i>
                                            </Label>
                                            <UncontrolledTooltip target="images" placement="top">
                                                Start Recording
                                            </UncontrolledTooltip>
                                        </li>
                                        :
                                        <li className="list-inline-item input-file" onClick={props?.stopRecording}>
                                            <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-stop-circle-line"></i>
                                            </Label>
                                            <UncontrolledTooltip target="images" placement="top">
                                                Stop Recording
                                            </UncontrolledTooltip>
                                        </li>}

                                    <li className="list-inline-item input-file">
                                        <Label id="images" className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                            <i className="ri-image-fill"></i>
                                            <Input onChange={(e) => handleImageChange(e)} accept="image/*" type="file" name="fileInput" size="60" multiple />
                                        </Label>
                                        <UncontrolledTooltip target="images" placement="top">
                                            Images
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="list-inline-item">
                                        <Button type="submit" color="primary" className="font-size-16 btn-lg chat-send waves-effect waves-light" disabled={false}>
                                            <i className="ri-send-plane-2-fill"></i>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ChatInput;