import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  ModalFooter,
} from "reactstrap";
import { createGroupConversation } from "../../../services/conversations";
import {toast} from "react-toastify";
import Loader from "../../../common/loader";

export default function CreateGroupModal(props) {
  const [search, setSearch] = useState("");
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [name,setName] = useState("");
  const [members, setMembers] = useState([]);
  const {
    isOpen,
    toggleCallModal,
    isVideo,
    user,
    inComming,
    currentUser,
    contacts,
  } = props;

  const onClose = () => {
    toggleCallModal();
  };

  const filterContacts = () => {
    if (search !== "") {
      const newData = props?.contacts?.filter((contact) => {
        return contact?.name?.toLowerCase().includes(search);
      });
      setData(newData);
    } else {
      setData(contacts);
    }
  };

  const createGroup = async  (e) => {
    setLoading(true);
    e.preventDefault();
    console.log("submit", members,currentUser);
    
    const res = await createGroupConversation({
      name:name,
      members:[...members,currentUser._id],
    });
    console.log("res is",res)
    res.data.isGroupChat = true;
    if(res.status == 200){

      toast.success(
            `Group ${name} created`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          toggleCallModal();
          props.updateConversations([]);
          props.updateContacts([...props.contacts,res.data]);
          
    }else{
      toast.error(
            `${res.message}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
    }
    setLoading(false);
    
  };

  const handleCheckBox = (val, id) => {
    if (val) {
      const newMembers = [...members, id];
      console.log("members",newMembers)
      setMembers(newMembers);
    } else {
      // const newMembers = members.filter((m) => m != id);
      setMembers((state)=>{
          const newState =  state.filter(m => m != id);
          console.log("members",newState)
          return newState;
      });
    }
  };

  useEffect(()=>{
    filterContacts()
  }, [search]);

  useEffect(() => {
    setData(props.contacts);
  }, [props.contacts]);

  const renderContacts = () => {
    return data?.filter((c)=>c.isGroupChat ? false : true).map((contact,i) => (
      <ListGroupItem key={i}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <div className="chat-user-img align-self-center me-3">
              <div className="avatar-xs">
                <img
                  src={contact?.user?.imageSrc}
                  className="rounded-circle avatar-xs"
                  alt="chatvia"
                ></img>
              </div>
            </div>
            <span className="m-auto">{contact.name}</span>
          </div>
          <Input
            type="checkbox"
            className="my-auto"
            value={members.includes(contact?.user?._id)}
            onChange={(e) => handleCheckBox(e.target.checked, contact?.user?._id)}
          />
        </div>
      </ListGroupItem>
    ));
  };

  return (
    <Modal tabIndex="-1" isOpen={isOpen} toggle={toggleCallModal} centered>
      <ModalHeader toggle={toggleCallModal}>
        <h2 className="text-center">Add to Group</h2>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={createGroup}>
          <FormGroup>
            <Label for="exampleEmail">Name</Label>
            <Input
              id="exampleEmail"
              name="group-name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              placeholder="Enter group name"
              type="text"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Search</Label>
            <Input
              id="exampleEmail"
              name="user"
              placeholder="Enter username"
              type="text"
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </FormGroup>
          <ListGroup style={{ maxHeight: "236px", overflowY: "scroll" }}>
            {renderContacts()}
          </ListGroup>
         { !loading ? <Button color="primary" className="w-100 my-15px">
            Create
          </Button> : <Loader />}
        </Form>
      </ModalBody>
    </Modal>
  );
}
