//import axios from 'axios';
import http from './httpService';

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + '/conversations';

function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

function messagesURL(id) {
  return `${apiUrl}/messages/conversation/${id}`;
}

function messagesDelURL(id) {
  return `${apiUrl}/messages/${id}`;
}

export function getConversations() {
  return http.get(apiEndpoint);
}

export function getMessages(userId) {
  return http.get(messagesURL(userId));
}

export function delMessage(userId) {
  return http.delete(messagesDelURL(userId));
}

export const getConversation = (idusr1, idusr2) =>
  http.post(apiEndpoint, {
    sender: idusr1,
    receiver: idusr2,
  });


export function saveConversation(user, imageSrc) {
  const formData = new FormData();
  //update
  if (user._id) {
    //clone user and delete _id
    const body = { ...user };
    delete body._id;
    for (let key in body) {
      formData.append(key, body[key]);
    }
    formData.append('imageSrc', imageSrc);
    return http.put(userUrl(user._id), formData);
  }
  const body = { ...user };
  for (let key in body) {
    formData.append(key, body[key]);
  }
  formData.append('imageSrc', imageSrc);
  //add a new user
  return http.post(apiEndpoint, formData);
}

//delete users
export function deleteConversation(userId) {
  return http.delete(userUrl(userId));
}

export function createGroupConversation(data) {
  return http.post(apiUrl + "/groupConversations", data);

}


export function getGroupConversation() {
    console.log("i am runing")
  return http.get(apiUrl + "/groupConversations");
}

export function deleteGroup(id) {
  return http.delete(apiUrl + `/groupConversations/${id}`);
}

export function getGroupMessages(id) {

  return http.get(apiUrl + "/groupmessages/conversation/"+ id);


}