import React, { Component } from "react";
import { Input, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
//simplebar
import SimpleBar from "simplebar-react";

//components
import OnlineUsers from "./OnlineUsers";

class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchChat: "",
      recentChatList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.openUserChat = this.openUserChat.bind(this);
  }

  componentDidMount() {
    var li = document.getElementById("conversation" + this.props.active_user);
    if (li) {
      li.classList.add("active");
    }
    this.setState({
      recentChatList: this.props.contacts,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        recentChatList: this.props.contacts,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.recentChatList !== nextProps.recentChatList) {
      this.setState({
        recentChatList: nextProps.contacts,
      });
    }
  }

  handleChange(e) {
    this.setState({ searchChat: e.target.value });
    var search = e.target.value;
    let conversation = this.state.recentChatList;
    let filteredArray = [];

    //find conversation name from array
    for (let i = 0; i < conversation.length; i++) {
      if (
        conversation[i].name.toLowerCase().includes(search) ||
        conversation[i].name.toUpperCase().includes(search)
      )
        filteredArray.push(conversation[i]);
    }

    //set filtered items to state
    this.setState({ recentChatList: filteredArray });

    //if input value is blanck then assign whole recent chatlist to array
    if (search === "") this.setState({ recentChatList: this.props.contacts });
  }

  formatDate(date) {
    const renderNumber = (value, size) => {
      const text = String(value);
      return text.padStart(size, "0");
    };
    const year = renderNumber(date.getFullYear(), 4);
    const month = renderNumber(date.getMonth() + 1, 2);
    const day = renderNumber(date.getDate(), 2);
    return `${day}/${month}/${year}`;
  }

  openUserChat(e, contact) {
    e.preventDefault();

    const id = this.props.contacts.indexOf(contact);
    var chatList = document.getElementById("chat-list");
    var clickedItem = e.target;
    var currentli = null;

    if (chatList) {
      var li = chatList.getElementsByTagName("li");
      // remove coversation user
      for (var i = 0; i < li.length; ++i) {
        if (li[i].classList.contains("active")) {
          li[i].classList.remove("active");
        }
      }
      // find clicked coversation user
      for (var k = 0; k < li.length; ++k) {
        if (li[k].contains(clickedItem)) {
          currentli = li[k];
          break;
        }
      }
    }

    //activation of clicked coversation user
    if (currentli) {
      currentli.classList.add("active");
    }

    var userChat = document.getElementsByClassName("user-chat");
    if (userChat) {
      userChat[0].classList.add("user-chat-show");
    }
    this.props.setActiveUser(id);
    // removes unread badge if user clicks
    var unread = document.getElementById("unRead" + contact._id);
    if (unread) {
      unread.style.display = "none";
    }
  }

  // async onRefresh(props) {
  //   return new Promise((resolve) => {
  //     console.log(props);
  //     props.fetchUpdatedMessages();
  //     setTimeout(resolve, 1000);
  //   });
  // }

  render() {
    return (
      <React.Fragment>
        <div style={{ minWidth: 360 }} className="chats-h">
          <div className="px-4 pt-4">
            <h4 className="mb-4">Chats</h4>
            <div className="search-box chat-search-box">
              <InputGroup size="lg" className="mb-3 rounded-lg">
                <span
                  className="input-group-text text-muted bg-light pe-1 ps-3"
                  id="basic-addon1"
                >
                  <i className="ri-search-line search-icon font-size-18"></i>
                </span>
                <Input
                  type="text"
                  value={this.state.searchChat}
                  onChange={(e) => this.handleChange(e)}
                  className="form-control bg-light"
                  placeholder="Search messages or users"
                />
              </InputGroup>
            </div>
            {/* Search Box */}
          </div>

          {/* online users */}
          <OnlineUsers contacts={this.props.onlineContacts} />
          {/*  favrt chats */}
          <div 
          // style={{maxHeight:490,overflowY:'scroll'}} 
          className="chats-list-h">

          
          {(this.state.recentChatList.filter(chat => chat.star == true).length > 0) && <div className="px-2">
            <h5 className="mb-3 px-3 font-size-16">Favorites</h5>

            <SimpleBar
              // style={{ maxHeight: "100%" }}
              className="chat-message-list"
            >
              <ul
                className="list-unstyled chat-list chat-user-list"
                id="chat-list"
              >
                {this.state.recentChatList.map(
                  (chat, key) =>
                    chat?.star && (
                      <li
                        key={key}
                        id={"conversation" + key}
                        className={
                          chat.unRead
                            ? "unread"
                            : chat.isTyping
                            ? "typing"
                            : key === this.props.active_user
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to="#"
                          onClick={(e) => this.openUserChat(e, chat)}
                        >
                          <div className="d-flex">
                            {!chat.user?.imageSrc ? (
                              <div
                                className={
                                  "chat-user-img " +
                                  chat.status +
                                  " align-self-center me-3 ms-0"
                                }
                                style={{ width: "15%" }}
                              >
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {chat.user?.contactName?.first?.charAt(0)}
                                  </span>
                                </div>
                                {chat.status && (
                                  <span className="user-status"></span>
                                )}
                              </div>
                            ) : (
                              <div
                                className={
                                  "chat-user-img " +
                                  chat.status +
                                  " align-self-center me-3 ms-0"
                                }
                                style={{ width: "15%" }}
                              >
                                <img
                                  src={chat?.user?.imageSrc}
                                  className="rounded-circle avatar-xs"
                                  alt="chatvia"
                                />
                                {chat.status && (
                                  <span className="user-status"></span>
                                )}
                              </div>
                            )}

                            <div
                              className="flex-grow-1 overflow-hidden"
                              style={{ width: "65%" }}
                            >
                              <h5 className="text-truncate font-size-15 mb-1">
                                {chat.name}
                              </h5>
                              <p className="chat-user-message text-truncate mb-0">
                                {chat.isTyping ? (
                                  <>
                                    typing
                                    <span className="animate-typing">
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                      <span className="dot ms-1"></span>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {this.props.conversations[key]?.length >
                                      0 &&
                                    this.props.conversations[key][0] &&
                                    this.props.conversations[key][0].length >
                                      0 &&
                                    this.props.conversations[key][0][
                                      this.props.conversations[key][0].length -
                                        1
                                    ].media.length >= 1 ? (
                                      <i className="ri-image-fill align-middle me-1"></i>
                                    ) : null}
                                    {this.props.conversations[key]?.length >
                                      0 &&
                                    this.props.conversations[key][0] &&
                                    this.props.conversations[key][0].length >
                                      0 &&
                                    this.props.conversations[key][0][
                                      this.props.conversations[key][0].length -
                                        1
                                    ].isFileMessage === true ? (
                                      <i className="ri-file-text-fill align-middle me-1"></i>
                                    ) : null}
                                    {this.props.conversations[key]?.length >
                                      0 &&
                                    this.props.conversations[key][0] &&
                                    this.props.conversations[key][0].length > 0
                                      ? this.props.conversations[key][0][
                                          this.props.conversations[key][0]
                                            .length - 1
                                        ].message
                                      : null}
                                  </>
                                )}
                              </p>
                            </div>
                            <div
                              className="font-size-11"
                              style={{ width: "20%" }}
                            >
                              {this.props.conversations[key]?.length > 0 &&
                              this.props.conversations[key][0] &&
                              this.props.conversations[key][0].length > 0
                                ? this.formatDate(
                                    new Date(
                                      this.props.conversations[key]?.length >
                                        0 &&
                                        this.props.conversations[key][0][
                                          this.props.conversations[key][0]
                                            .length - 1
                                        ].createdAt
                                    )
                                  )
                                : null}
                            </div>
                            {chat.unRead === 0 ? null : (
                              <div
                                className="unread-message"
                                id={"unRead" + chat.id}
                              >
                                <span className="badge badge-soft-danger rounded-pill">
                                  {chat.messages && chat.messages.length > 0
                                    ? chat.unRead >= 20
                                      ? chat.unRead + "+"
                                      : chat.unRead
                                    : ""}
                                </span>
                              </div>
                            )}
                          </div>
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </SimpleBar>
          </div>}

          {/* Start chat-message-list  */}
          <div className="px-2">
            <h5 className="mb-3 px-3 font-size-16">Recent</h5>
              <SimpleBar
                style={{ maxHeight: "100%" }}
                className="chat-message-list"
              >
                <ul
                  className="list-unstyled chat-list chat-user-list"
                  id="chat-list"
                >
                  {this.state.recentChatList.map(
                    (chat, key) =>
                      !chat?.star && (
                        <li
                          key={key}
                          id={"conversation" + key}
                          className={
                            chat.unRead
                              ? "unread"
                              : chat.isTyping
                              ? "typing"
                              : key === this.props.active_user
                              ? "active"
                              : ""
                          }
                        >
                          <Link
                            to="#"
                            onClick={(e) => this.openUserChat(e, chat)}
                          >
                            <div className="d-flex">
                              {chat.isGroupChat ? (
                                <div
                                  className={
                                    "chat-user-img " +
                                    chat.status +
                                    " align-self-center me-3 ms-0"
                                  }
                                  style={{ width: "15%" }}
                                >
                                  <img
                                    src="https://static.vecteezy.com/system/resources/thumbnails/021/063/599/small/group-coaching-icon-vector.jpg"
                                    className="rounded-circle avatar-xs"
                                    alt="chatvia"
                                  />
                                  {chat.status && (
                                    <span className="user-status"></span>
                                  )}
                                </div>
                              ) : !chat.user?.imageSrc ? (
                                <div
                                  className={
                                    "chat-user-img " +
                                    chat.status +
                                    " align-self-center me-3 ms-0"
                                  }
                                  style={{ width: "15%" }}
                                >
                                  <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                      {chat.user?.contactName?.first?.charAt(0)}
                                    </span>
                                  </div>
                                  {chat.status && (
                                    <span className="user-status"></span>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={
                                    "chat-user-img " +
                                    chat.status +
                                    " align-self-center me-3 ms-0"
                                  }
                                  style={{ width: "15%" }}
                                >
                                  <img
                                    src={chat?.user?.imageSrc}
                                    className="rounded-circle avatar-xs"
                                    alt="chatvia"
                                  />
                                  {chat.status && (
                                    <span className="user-status"></span>
                                  )}
                                </div>
                              )}

                              <div
                                className="flex-grow-1 overflow-hidden"
                                style={{ width: "65%" }}
                              >
                                <h5 className="text-truncate font-size-15 mb-1">
                                  {chat.name}
                                </h5>
                                <p className="chat-user-message text-truncate mb-0">
                                  {chat.isTyping ? (
                                    <>
                                      typing
                                      <span className="animate-typing">
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                        <span className="dot ms-1"></span>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {this.props.conversations[key]?.length >
                                        0 &&
                                      this.props.conversations[key][0] &&
                                      this.props.conversations[key][0].length >
                                        0 &&
                                      this.props.conversations[key][0][
                                        this.props.conversations[key][0]
                                          .length - 1
                                      ].media.length >= 1 ? (
                                        <i className="ri-image-fill align-middle me-1"></i>
                                      ) : null}
                                      {this.props.conversations[key]?.length >
                                        0 &&
                                      this.props.conversations[key][0] &&
                                      this.props.conversations[key][0].length >
                                        0 &&
                                      this.props.conversations[key][0][
                                        this.props.conversations[key][0]
                                          .length - 1
                                      ].isFileMessage === true ? (
                                        <i className="ri-file-text-fill align-middle me-1"></i>
                                      ) : null}
                                      {this.props.conversations[key]?.length >
                                        0 &&
                                      this.props.conversations[key][0] &&
                                      this.props.conversations[key][0].length >
                                        0
                                        ? this.props.conversations[key][0][
                                            this.props.conversations[key][0]
                                              .length - 1
                                          ].message
                                        : null}
                                    </>
                                  )}
                                </p>
                              </div>
                              <div
                                className="font-size-11"
                                style={{ width: "20%" }}
                              >
                                {this.props.conversations[key]?.length > 0 &&
                                this.props.conversations[key][0] &&
                                this.props.conversations[key][0].length > 0
                                  ? this.formatDate(
                                      new Date(
                                        this.props.conversations[key]?.length >
                                          0 &&
                                          this.props.conversations[key][0][
                                            this.props.conversations[key][0]
                                              .length - 1
                                          ].createdAt
                                      )
                                    )
                                  : null}
                              </div>
                              {chat.unRead === 0 ? null : (
                                <div
                                  className="unread-message"
                                  id={"unRead" + chat.id}
                                >
                                  <span className="badge badge-soft-danger rounded-pill">
                                    {chat.messages && chat.messages.length > 0
                                      ? chat.unRead >= 20
                                        ? chat.unRead + "+"
                                        : chat.unRead
                                      : ""}
                                  </span>
                                </div>
                              )}
                            </div>
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </SimpleBar>
          </div>
          {/* End chat-message-list */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Chats;
