import "bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabContent } from "reactstrap";
import ReusableTab from "../../common/ReusableTab";
import ReusableTabNavs from "../../common/ReusableTabNavs";
import {
    Panel,
    PanelBody,
    PanelHeader,
} from "../../components/panel/panel";
// Icons imports
import { useTable } from "react-table";
import archiveIcon from "../../assets/Icons/archive.svg";
import clipboardIcon from "../../assets/Icons/copy2clipboard.svg";
import csvIcon from "../../assets/Icons/csv.svg";
import newIcon from "../../assets/Icons/new.svg";
import pdfIcon from "../../assets/Icons/pdf.svg";
import printerIcon from "../../assets/Icons/printer-xxl.svg";
import trash from "../../assets/Icons/trash.svg";
import xlsIcon from "../../assets/Icons/xls.svg";
import importIcon from "../../assets/Icons/import.svg";
// import http from "../../../services/httpService";
import { getConsults } from "../../services/consults";
import Icon from "../../common/icon";
import Conversations from "./Conversations";
import { Tooltip } from "@material-ui/core";
const apiUrl = process.env.REACT_APP_API_URL;

const tabMenus = [
    { label: "Active", background: "#2BD62E" },
    { label: "Archived", background: "#939993" },
];

function ConsultsTableData() {
    const [consultsData, setConsultsData] = useState([{
        _id: "1",
        avatar: archiveIcon,
        name: "ram",
        patient: "ram",
        subject: "ram subject",
        consult: "",
        date: new Date(),
        tags: "ram tags",
        viewSession: {
            _id: "1"
        },
        visibleFile: true,
        isPreviousSession: false
    }]);
    const [checkedFields, setCheckedFields] = useState([]);
    const [fetchAgain, setfetchAgain] = useState(false);

    const getAllConsults = async () => {
        // const { data } = await getConsults();
        // const sorted = _.orderBy(data, ["createdAt"], ["desc"]);

        // let files = sorted.map((item) => {
        //     return {
        //         _id: item._id,
        //         avatar: item.patientNo?.user?.imageSrc,
        //         name: item?.patientNo?.user?.contactName?.last,
        //         patient: item.patientNo?.user?.contactName?.last,
        //         subject: item?.ubject,				
        //         consult: item?.chiefComplaint,
        //         date: item.createdOn,
        //         tags: item.tags,
        //         viewSession: item,
        //     };
        // });
        // setConsultsData(files);
    };

    const viewFile = (id) => {
        setConsultsData(
            consultsData.map((item) => {
                if (id === item.viewSession._id) {
                    return {
                        ...item,
                        isPreviousSession: !item.isPreviousSession,
                    };
                } else {
                    return {
                        ...item,
                        visibleFile: !item.visibleFile,
                    };
                }
            })
        );
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setCheckedFields([...checkedFields, e.target.value]);
        } else {
            setCheckedFields(
                checkedFields.filter((field) => field !== e.target.value)
            );
        }
    };

    const selectItem = async (e) => {
        // const { data } = await getTCMsessions();
        // const { checked } = e.target;
        // const collection = [];

        // if (checked) {
        //     data.map((item) => {
        //         collection.push(item._id);
        //     });
        // }

        // setCheckedFields(collection);
    };

    // delete medical files
    const handleDelete = (CheckedConsults) => {
        // const originalConsults = medicalFilesData;
        // CheckedConsults.map(async (file) => {
        //     setConsultsData(
        //         medicalFilesData.filter((mf) => mf._id !== file)
        //     );
        //     try {
        //         await http.delete(apiUrl + "/tcmsessions/" + file);
        //     } catch (ex) {
        //         if (ex.response && ex.response === 404) {
        //             alert("already deleted");
        //         }
        //         setConsultsData(originalConsults);
        //     }
        // });
    };

    useEffect(() => {
        getAllConsults();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <input
                        type="checkbox"
                        style={{ marginLeft: "12px" }}
                        onClick={(e) => selectItem(e)}
                    />
                ),
                accessor: "col1",
                sortable: false,
            },
            {
                Header: "User",
                accessor: "col2", // accessor is the "key" in the data
                sortable: true,
            },

            {
                Header: "Subject",
                accessor: "col3", // accessor is the "key" in the data
                sortable: true,
            },
            {
                Header: "Date/Time",
                accessor: "col4", // accessor is the "key" in the data
                sortable: true,
            },
            {
                Header: "Tags",
                accessor: "col5",
                sortable: true,
            },
            // {
            //     Header: "Previous Chats",
            //     accessor: "col6",
            //     sortable: false,
            // },

        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: consultsData,
        });

    const DisplayConsults = () => {
        return <></>;
    };

    const [activeTab, setActiveTab] = React.useState(1);
    const [entries, setEntries] = React.useState(10);
    const [searchQuery, setSearchQuery] = React.useState("");

    const setActiveView = (n) => setActiveTab(n);

    const targetHeight = 34;

    const customStyles = {
        control: (styles) => ({
            ...styles,
            minHeight: "initial",
        }),

        option: (provided) => ({
            ...provided,
        }),
        valueContainer: (base) => ({
            ...base,
            padding: "0 8px",
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="page-header text-center">
                    Consults{" "}
                </h1>
            </div>
            {/* {console.log("Checked : ", checkedFields)} */}
            <Panel>
                <PanelHeader>Management for Consults</PanelHeader>
                <React.Fragment>
                    <ToastContainer />
                    <div className="toolbar" style={toolbarStyles}>
                        <Icon
                            to="/chatGTP/tcmsession/new"
                            title="add consult"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={newIcon}
                        />
                        <Icon
                            title="Delete Conversation"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={trash}
                            handleClick={() => handleDelete(checkedFields)}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="csv"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={csvIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="pdf"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={pdfIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="xls"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={xlsIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="printer"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={printerIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="clipboard"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={clipboardIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="archive"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={archiveIcon}
                        />
                        <Icon
                            to="/chatGTP/consults"
                            title="import"
                            btnStyle={btnStyles}
                            iconStyle={iconStyles}
                            icon={importIcon}
                        />
                    </div>
                </React.Fragment>

                <hr className="m-0" />
                <PanelBody>
                    <ReusableTabNavs
                        setActiveTab={(n) => setActiveView(n)}
                        activeTab={activeTab}
                        navprops={tabMenus}
                    />
                    <TabContent activeTab={activeTab}>
                        <ReusableTab id={1}>
                            <div className="d-flex justify-content-between align-items-center mt-4">
                                <div className="d-flex align-items-center">
                                    Show&nbsp;
                                    <div style={{ width: "70px" }}>
                                        <Select
                                            styles={customStyles}
                                            options={[
                                                {
                                                    value: 10,
                                                    label: 10,
                                                },
                                            ]}
                                            placeholder={"Entries..."}
                                            value={{
                                                value: entries,
                                                label: entries,
                                            }}
                                            onChange={(e) =>
                                                setEntries(e.value)
                                            }
                                        />
                                    </div>
                                    &nbsp;entries
                                </div>
                                <div className="d-flex align-items-center">
                                    <span>Search:&nbsp;</span>
                                    <input
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div className="table-responsive mt-3">
                                <table
                                    className="table table-bordered"
                                    {...getTableProps()}
                                >
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => (
                                                        <th
                                                            {...column.getHeaderProps()}
                                                        >
                                                            <div class="d-flex">
                                                                <span>
                                                                    {column.render(
                                                                        "Header"
                                                                    )}
                                                                </span>
                                                                <span class="ml-auto">
                                                                    {column.sortable ? (
                                                                        column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <i className="fa fa-sort-down fa-fw f-s-14 text-blue"></i>
                                                                            ) : (
                                                                                <i className="fa fa-sort-up fa-fw f-s-14 text-blue"></i>
                                                                            )
                                                                        ) : (
                                                                            <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            // this are the single consult INFORMATION
                                            return (
                                                row.original.visibleFile && (
                                                    <>
                                                        <tr>
                                                            <input
                                                                type="checkbox"
                                                                style={{
                                                                    width: "15px",
                                                                    height: "15px",
                                                                    marginTop:
                                                                        "15px",
                                                                    marginLeft:
                                                                        "20px",
                                                                    borderRadius:
                                                                        "2px",
                                                                }}
                                                                value={
                                                                    row.original
                                                                        .viewSession
                                                                        ._id
                                                                }
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        e
                                                                    )
                                                                }
                                                                checked={checkedFields.includes(
                                                                    row.original
                                                                        .viewSession
                                                                        ._id
                                                                )}
                                                            />

                                                            <td>
                                                                <img
                                                                    src={
                                                                        row
                                                                            .original
                                                                            .avatar
                                                                    }
                                                                    style={{
                                                                        height: "30px",
                                                                        width: "30px",
                                                                        marginRight:
                                                                            "3px",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                />
                                                                {
                                                                    row.original
                                                                        .patient
                                                                }
                                                            </td>
                                                            <td>
                                                                <Tooltip title="Click to view details">
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                row
                                                                                    .original
                                                                                    ?.viewSession
                                                                                    ?._id
                                                                            )
                                                                        }
                                                                        style={{ cursor: "pointer" }}>
                                                                        {
                                                                            row.original
                                                                                .subject
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            </td>
                                                            <td>
                                                                {new Date(
                                                                    row.original.date
                                                                ).toLocaleDateString()}{" "}
                                                                {new Date(
                                                                    row.original.date
                                                                ).toLocaleTimeString()}
                                                            </td>

                                                            <td>
                                                                {
                                                                    row.original
                                                                        .tags
                                                                }
                                                            </td>

                                                            {/* <td>
                                                                <a
                                                                    style={{
                                                                        fontSize:
                                                                            "20px",
                                                                    }}
                                                                    data-toggle="collapse"
                                                                    data-target={`#multiCollapseExample1${index}`}
                                                                    role="button"
                                                                    aria-expanded="false"
                                                                    aria-controls={`multiCollapseExample1${index}`}
                                                                    onClick={() =>
                                                                        viewFile(
                                                                            row
                                                                                .original
                                                                                ?.viewSession
                                                                                ?._id
                                                                        )
                                                                    }
                                                                >
                                                                    <AiOutlineEye />
                                                                </a>
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <div>
                                                                    {row
                                                                        .original
                                                                        .isPreviousSession && (
                                                                            <Conversations />
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </ReusableTab>
                        <ReusableTab id={2} height={"100%"} width={"100%"}>
                            <DisplayConsults />
                        </ReusableTab>
                    </TabContent>
                </PanelBody>
            </Panel>
        </div>
    );
}

const toolbarStyles = {
    background: "#c8e9f3",
    padding: "10px",
};

const btnStyles = { background: "#348fe2", margin: "0rem" };
const iconStyles = {
    width: "25px",
    height: "25px",
    marginRight: "0rem",
};

export default ConsultsTableData;
