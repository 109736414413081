import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({ handleDrop }) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    handleDrop(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        className="h-[100%] w-full border-2 bg-gray-400 opacity-60 flex justify-center items-center absolute top-[0%] left-[0%]"
      >
        <input {...getInputProps()} />

        <p className="text-2xl">Drop the files here ...</p>
      </div>
    </>
  );
}

export default Dropzone;
