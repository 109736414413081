import React, {  useRef } from "react";
import "../../../assets/scss/themes.scss";
import { configureStore } from "../../../store/messenger/store";
import { Provider } from "react-redux";
//Import Components
import "./index.css";
import Layout from "../Chats/Layout";
import { useLocation } from "react-router-dom";
import { useCallContext } from "../../../store/context/CallProvider";
import { useSocket } from "../../../store/context/SocketProvider";
import Audiocall from "./Audiocall";
const AudiocallIndex = () => {
  const {
    remoteUser,
    localStream,
    remoteStream,
    closeCall,
    startVideo,
    stopVideo,
    toggleMute,
    isVideoTrack,
    isMuted,
    isVideo
  } = useCallContext();
  const {state:{min,sec}} = useLocation();
  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  localStreamRef.current = localStream;
  remoteStreamRef.current = remoteStream;
  const { me } = useSocket();

  console.log("is Video is",isVideo)
  return (
    <Provider store={configureStore()}>
      <React.Fragment>
        <Layout>
            <Audiocall
              currentUser={me}
              remoteUser={remoteUser.current}
              localStream={localStreamRef}
              remoteStream={remoteStreamRef}
              closeCall={closeCall}
              startVideo={startVideo}
              stopVideo={stopVideo}
              toggleMute={toggleMute}
              isVideoTrack={isVideoTrack}
              isMuted={isMuted}
              isVideo={isVideo}
              seconds={sec}
              minutes={min}
            />
        </Layout>
      </React.Fragment>
    </Provider>
  );
};

export default AudiocallIndex;
