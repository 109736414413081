import { createSlice, current } from "@reduxjs/toolkit";
import { actions } from "react-table";
import { activeUser } from "./messenger/actions";

const initialState = {
  activeUser: 0,
  contacts: [],
  currentUser: null,
  conversations: [],
  isLoading: true,
  conversationsIds: [],
  onlineUsers: []
};

export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    deleteContacts: (state, action) => {
      let newContacts = [...state.contacts];
      const newConver = [...state.conversations];

      newContacts = newContacts.filter(contact => !(contact._id == action.payload))
      return { ...state, contacts: newContacts, conversations: newConver, activeUser: 0 }
    },
    updateContacts: (state, action) => {
      return { ...state, contacts: action.payload, isLoading: true };
    },
    updateContact: (state, action) => {
      return { ...state, contacts: [...state.contacts, action.payload], isLoading: true };
    },
    updateActiveUser: (state, action) => {
      return { ...state, activeUser: action.payload };
    },
    updateCurrentUser: (state, action) => {
      return { ...state, currentUser: action.payload };
    },
    updateConversationsId: (state, action) => {
      const arr = [...state.conversationsIds];
      arr.push(action.payload);
      return { ...state, conversationsIds: arr };
    },
    updateConversations: (state, action) => {
      return { ...state, conversations: [...state.conversations, action.payload], isLoading: false };
    },
    updateEntireConversation: (state, action) => {
      return { ...state, conversations: action.payload, isLoading: false };
    },
    updateMessages: (state, action) => {
      let newState = current(state);
      let activeUser = action?.payload?.key;
      console.log("key is",activeUser,action.payload.key)
      for (const i in newState.contacts) {
        if (!newState.contacts[i].isGroupChat && newState.contacts[i]?.user?._id === action?.payload?.message?.sender) {
          activeUser = i;
        }
      }
      let messages = [...newState.conversations];
      console.log(messages, activeUser, action.payload.key)
      let arr = [...messages[activeUser][0]];
      arr[arr.length] = action.payload.message;
      messages[activeUser] = [arr];
      // for showing on top
      let contacts = [...newState.contacts];
      let conversationsIds = [...newState.conversationsIds];
      const topContact = contacts.splice(activeUser, 1);
      const topId = conversationsIds.splice(activeUser, 1);
      const topMessages = messages.splice(activeUser, 1);
      contacts.unshift(topContact[0]);
      conversationsIds.unshift(topId[0]);
      messages.unshift(topMessages[0]);
      return { ...newState, conversations: messages, contacts: contacts, conversationsIds: conversationsIds, activeUser: 0 }
    },
    updateGroupMessages: (state, action) => {
      let newState = current(state);
      let messages = [...newState.conversations];
      let k;
      const key = k;
      for (const key in newState.contacts) {
        if (newState.contacts[key]._id === action.payload.conversationId) {
          k = key;
        }
      }
      let arr = [...messages[k][0]];
      arr[arr.length] = action.payload;
      messages[k] = [arr];

      //for showing on top as recent chat
      let contacts = [...newState.contacts];
      let conversationsIds = [...newState.conversationsIds];
      const topContact = contacts.splice(key, 1);
      const topId = conversationsIds.splice(key, 1);
      const topMessages = messages.splice(key, 1);
      contacts.unshift(topContact[0]);
      conversationsIds.unshift(topId[0]);
      messages.unshift(topMessages[0]);

      return { ...newState, conversations: messages, contacts: contacts, conversationsIds: conversationsIds, activeUser: 0 }
    },
    deleteMessage: (state, action) => {
      let newState = current(state);
      let messages = [...newState.conversations];
      let arr = [...messages[newState.activeUser][0]];
      arr = arr.filter((item) => {
        if (action.payload.id !== item._id) {
          return true;
        } else {
          console.log("deleted", item._id)
          return false
        }
      })
      messages[newState.activeUser] = [arr];
      return { ...newState, conversations: messages }
    },
    updateMoreMessages: (state, action) => {
      let newState = current(state);
      const { convId, newMessages } = action.payload;
      const chatKey = newState.conversationsIds.indexOf(convId);
      const oldMessages = [...newState.conversations[chatKey]];
      const finalMessages = newMessages.contact(oldMessages);

      return { ...newState, conversations: finalMessages }

    },
    updateOnlineUsers: (state, action) => {
      return { ...state, onlineUsers: action.payload };
    },
    updateForwardMessage: (state, action) => {

      const msg = action.payload;
      let newState = current(state);
      let messages = [...newState.conversations];

      let k;
      for (const key in newState.conversationsIds) {
        if (newState.conversationsIds[key] === msg?.conversationId) {
          k = key;
        }
      }

      let arr = [...messages[k][0]];
      arr[arr.length] = msg;
      messages[k] = [arr];

      //for showing on top as recent chat
      let contacts = [...newState.contacts];
      let conversationsIds = [...newState.conversationsIds];
      const topContact = contacts.splice(k, 1);
      const topId = conversationsIds.splice(k, 1);
      const topMessages = messages.splice(k, 1);
      contacts.unshift(topContact[0]);
      conversationsIds.unshift(topId[0]);
      messages.unshift(topMessages[0]);

      return { ...newState, conversations: messages ,contacts: contacts, conversationsIds: conversationsIds, activeUser:0};
    },
  }
});
export const { updateForwardMessage,updateContacts, updateContact, updateMoreMessages, updateActiveUser, updateCurrentUser, updateConversations, deleteContacts, updateGroupMessages, updateOnlineUsers, updateMessages, updateConversationsId, updateEntireConversation, deleteMessage } = messengerSlice.actions;

export default messengerSlice.reducer;
