import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AppRoute from "./config/app-route.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
// bootstrap
import "bootstrap";

// css
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";
//custom
//import 'antd/dist/antd.css';
//////////////////
//import 'flag-icon-css/css/flag-icon.min.css';
//import './scss/default/fontstyles.scss';
//import './scss/default/demo.scss';
import "./scss/default/fonts/Cronicon/Cronicon.css";
import SocketProvider from "./store/context/SocketProvider.js"
import CallProvider from "./store/context/CallProvider.js";
import "./index.css";

const store = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  let element = useRoutes(AppRoute);

  return (
    <>
      <SocketProvider>
        <CallProvider>
          {element}
          <ToastContainer />
        </CallProvider>
      </SocketProvider>
    </>
  );
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
